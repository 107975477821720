import { getFarmListForProvider } from './getFarmListForProvider';

export const getMatchingFarm = (
  id: string,
  exchangeProvider: string,
): IFarmPair | undefined => {
  const list = getFarmListForProvider(exchangeProvider);

  const farm = list.find(
    (f: IFarmPair) =>
      f.stakingToken?.token?.toLowerCase() === id.toLowerCase() && !f?.hidden,
  );

  return farm;
};
