import { State } from '../store';

export type FallbackRatesAction = {
  type: 'SET_FALLBACK_RATES';
  payload: DexRatesType;
};

export const fallbackRatesReducer = (
  state: State,
  payload: DexRatesType,
): State => ({
  ...state,
  fallbackRates: payload,
});
