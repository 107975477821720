import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const HelpIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M13.75 3.75C13.75 5.38277 12.7065 6.77182 11.25 7.28662V17.3963C14.3906 16.8694 16.8694 14.3906 17.3963 11.25H15V8.75H20V10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10V8.75H5V11.25H2.6037C3.1306 14.3906 5.60937 16.8694 8.75 17.3963V7.28662C7.29351 6.77182 6.25 5.38277 6.25 3.75C6.25 1.67893 7.92893 0 10 0C12.0711 0 13.75 1.67893 13.75 3.75Z"
          fill="currentColor"
        />
      </SvgIcon>
    </>
  );
};

export default HelpIcon;
