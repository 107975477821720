import React, { FunctionComponent, useEffect } from 'react';

import { useEthers } from '@usedapp/core';

import { useCefiSwapContext } from '@views/Swap/context/providers/CefiSwapProvider';
import { useSwapContext } from '@views/Swap/context/providers/SwapProvider';

import { findPrefill } from '@views/Swap/helpers/utils';

import { getAddress } from '../api/nekosdk';
import { EMPTY, EVM_COMPATIBLE_PROTOCOLS, TEST_ACCOUNT } from '../constants';
import { useTrackedState } from '../store';

interface Props {
  isDefiProvider: boolean;
}

export const AddressHandler: FunctionComponent<Props> = ({
  isDefiProvider,
}) => {
  const { userDetails } = useTrackedState();

  const {
    swapFromBlockchain,
    swapToBlockchain,
    swapToCurrency,
    swapFromCurrency,
    swapToProtocol,
    swapFromProtocol,
    setRefundAddress,
    setSelectedWallet,
    setToAddress,
  } = useSwapContext();
  const { userWallets } = useCefiSwapContext();

  const { account: ethersAccount } = useEthers();
  const account = TEST_ACCOUNT || ethersAccount;

  const refundAddressPrefill = findPrefill(
    userWallets,
    swapFromBlockchain,
    swapFromProtocol,
  );

  const toAddressPrefill = findPrefill(
    userWallets,
    swapToBlockchain,
    swapToProtocol,
  );

  useEffect(() => {
    const queryAddress = async () => {
      let address = '';
      if (typeof toAddressPrefill === 'string') {
        address = toAddressPrefill;
      } else {
        address = await getAddress(toAddressPrefill.walletId, swapToProtocol);
      }

      const useExternalAccount =
        isDefiProvider ||
        (!isDefiProvider &&
          !userDetails &&
          EVM_COMPATIBLE_PROTOCOLS.includes(swapToProtocol));

      address = useExternalAccount ? account || EMPTY : address;

      setToAddress(address);
    };
    queryAddress();
  }, [
    swapToCurrency,
    swapToProtocol,
    toAddressPrefill,
    isDefiProvider,
    account,
  ]);

  useEffect(() => {
    const queryAddress = async () => {
      let address = '';
      if (typeof refundAddressPrefill === 'string') {
        address = refundAddressPrefill;
      } else {
        address = await getAddress(
          refundAddressPrefill.walletId,
          swapFromProtocol,
        );
      }

      const useExternalAccount =
        isDefiProvider ||
        (!isDefiProvider &&
          !userDetails &&
          EVM_COMPATIBLE_PROTOCOLS.includes(swapFromProtocol));

      address = useExternalAccount ? account || EMPTY : address;

      setRefundAddress(address);

      if (typeof refundAddressPrefill === 'object') {
        setSelectedWallet(refundAddressPrefill);
      }
    };
    queryAddress();
  }, [
    swapFromCurrency,
    swapFromProtocol,
    refundAddressPrefill,
    isDefiProvider,
    account,
  ]);

  return <></>;
};

export default AddressHandler;
