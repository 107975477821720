import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const EarnBitcoinIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/SvgIcon"
        {...rest}
      >
        <path
          d="M3.75 3.75V0H6.25C8.32107 0 10 1.67893 10 3.75C10 1.67893 11.6789 0 13.75 0H16.25V3.75H20V7.5H0V3.75H3.75Z"
          fill="currentColor"
        />
        <path d="M1.25 10H8.75V18.75H1.25V10Z" fill="currentColor" />
        <path d="M18.75 10H11.25V18.75H18.75V10Z" fill="currentColor" />
      </SvgIcon>
    </>
  );
};

export default EarnBitcoinIcon;
