import { CoinKey, Token } from '@lifi/sdk';

import {
  RPC_URL_BINANCE,
  RPC_URL_ETHEREUM,
  RPC_URL_POLYGON,
  RPC_URL_SEPOLIA,
  RPC_URL_SMARTBCH,
  VERSE_ETHEREUM_TOKEN_CONTRACT,
  VERSE_POLYGON_TOKEN_CONTRACT,
  VERSE_TOKEN,
} from '@context/constants';
import { DEXTokens, DEXTokensSBCH, DEXTokensSepolia } from '@context/tokens';
import { LinksType } from '@context/types';

// eslint-disable-next-line no-shadow
export enum ORDER_STATUSES {
  Unknown = 'UNKNOWN_ORDER_STATUS',
  Pending = 'PENDING_ORDER_STATUS',
  Settling = 'SETTLING_ORDER_STATUS',
  Settled = 'SETTLED_ORDER_STATUS',
  Refund = 'REFUND_ORDER_STATUS',
  Refunding = 'REFUNDING_ORDER_STATUS',
  Refunded = 'REFUNDED_ORDER_STATUS',
}

export const CEFI_STATUS_BASE_URLS: LinksType = {
  changenow: 'https://changenow.io/exchange/txs/',
  sideshift: 'https://sideshift.ai/orders/',
};

export const EVM_CHAINS = ['AVAX', 'BNB', 'ETH', 'MATIC', 'SBCH'];

export const BCOM_SUPPORTED_EVM_PROTOCOLS = [
  'ETH_PROTOCOL',
  'ERC_20_PROTOCOL',
  'AVAX_PROTOCOL',
  'MATIC_PROTOCOL',
  'BNB_PROTOCOL',
];

export const BCOM_SUPPORTED_PROTOCOLS = [
  'BCH_PROTOCOL',
  'BTC_PROTOCOL',
  ...BCOM_SUPPORTED_EVM_PROTOCOLS,
];

export const NULL_AMP_EVENT = {
  from: '',
  to: '',
  fromProtocol: '',
  toProtocol: '',
  fromBlockchain: '',
  toBlockchain: '',
  valueCrypto: 0,
  valueUsd: 0,
  provider: undefined,
};

export const NULL_MULTICHAIN_EVENT = {
  from: '',
  'from address': '',
  'from blockchain': '',
  'from value crypto': 0,
  'from value usd': 0,
  route: [],
  'service fee': 0,
  to: '',
  'to address': '',
  'to blockchain': '',
  'to value crypto': 0,
  'to value usd': 0,
  'uses verse liquidity': false,
};

export const NULL_CEX_PAIR = {
  min: '0.00',
  max: '0.00',
  rate: '0.00',
  from: '',
  to: '',
  provider: '',
  estimatedNetworkFeesUsd: '0.00',
};

export const NULL_ORDER = {
  orderId: '',
  tradingPair: {
    depositAsset: {
      assetIdentifier: '',
      blockchain: '',
      protocol: '',
    },
    settleAsset: {
      assetIdentifier: '',
      blockchain: '',
      protocol: '',
    },
  },
  depositAddress: 'defaultAddress',
  depositAmount: '0',
  depositMin: '0',
  depositMax: '0',
  deposits: [],
  settleAmount: '0',
  settleAddress: 'defaultSettleAddress',
  createdAtInMillis: '0',
  expiresAtInMillis: '0',
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

// TODO: need to update or create separate contract error code map?
export const ERROR_CODE_MAP: { [code: string]: string } = {
  '-32000': 'Invalid input',
  '-32001': 'Resource not found.',
  '-32002': 'Resource is unavailable',
  '-32003': 'Transaction was rejected',
  '-32004': 'Method is not supported',
  '-32005': 'Request limit exceeded',
  '4001': 'Transaction request was rejected by user',
  '4200':
    'The requested account and/or method has not been authorized by the user',
  '4900': 'The provider is disconnected from all chains',
  '4901': 'The provider is not connected to the specified chain',
};

export const VERSE_TOKENS: Record<number, string> = {
  1: VERSE_ETHEREUM_TOKEN_CONTRACT,
  137: VERSE_POLYGON_TOKEN_CONTRACT,
};

export const DEFAULT_SWAP_FROM: Record<string, ITickerObject> = {
  bitcoincom_eth: DEXTokens[0], // eth
  bitcoincom_seth: DEXTokensSepolia[0], // sepolia eth
  bitcoincom: DEXTokensSBCH[0], // bch
  sideshift: DEXTokens[0], // eth
  changenow: DEXTokens[0], // eth
};

export const DEFAULT_SWAP_TO: Record<string, ITickerObject> = {
  bitcoincom_eth: VERSE_TOKEN,
  bitcoincom_seth: VERSE_TOKEN,
  bitcoincom: DEXTokensSBCH[3], // joy
  sideshift: VERSE_TOKEN,
  changenow: DEXTokens[1], // dai
};

export const LIFI_PRICE_FLOOR = 0.00001;
export const LIFI_BLACK_LIST = [
  // eth
  '0xDD1A646123855564Af8E44812C2cdb1F485412De',
  '0x0eF495bEb82eF421233e49a15bbdD95710BFa472',
  '0x41f7B8b9b897276b7AAE926a9016935280b44E97',
  '0x3Fa3E65695F54D96A88e06904e0a8c4B7ac65609',
  '0x764493a90ba8695661302741fc4d6Cd8a658ab73',
  // matic
  '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
  // avax
  '0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
];

export const UNISWAP_OPTIONS: UniswapOptionsListType = {
  1: {
    providerUrl: RPC_URL_ETHEREUM,
    customNetworkData: {
      nameNetwork: 'Ethereum',
      // multicallContractAddress: '0x65e9a150e06c84003d15ae6a060fc2b1b393342c',
      nativeCurrency: {
        name: 'ETH Token',
        symbol: 'ETH',
      },
      nativeWrappedTokenInfo: {
        chainId: 1,
        contractAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped ETH',
      },
      baseTokens: {
        comp: {
          chainId: 1,
          contractAddress: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
          decimals: 18,
          symbol: 'COMP',
          name: 'COMP Token',
        },
        dai: {
          chainId: 1,
          contractAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
          decimals: 18,
          symbol: 'DAI',
          name: 'DAI Token',
        },
        usdc: {
          chainId: 1,
          contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
          decimals: 6,
          symbol: 'USDC',
          name: 'USDC Token',
        },
        usdt: {
          chainId: 1,
          contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
          decimals: 6,
          symbol: 'USDT',
          name: 'USDT Token',
        },
        wbtc: {
          chainId: 1,
          contractAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
          decimals: 8,
          symbol: 'WBTC',
          name: 'WBTC Token',
        },
      },
    },
  },
  56: {
    providerUrl: RPC_URL_BINANCE,
    customNetworkData: {
      nameNetwork: 'Binance',
      multicallContractAddress: '0x65e9a150e06c84003d15ae6a060fc2b1b393342c',
      nativeCurrency: {
        name: 'BNB Token',
        symbol: 'BNB',
      },
      nativeWrappedTokenInfo: {
        chainId: 56,
        contractAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        decimals: 18,
        symbol: 'WBNB',
        name: 'Wrapped BNB',
      },
    },
  },
  137: {
    providerUrl: RPC_URL_POLYGON,
    customNetworkData: {
      nameNetwork: 'Polygon',
      multicallContractAddress: '0x275617327c958bD06b5D6b871E7f491D76113dd8',
      nativeCurrency: {
        name: 'Matic Token',
        symbol: 'MATIC',
      },
      nativeWrappedTokenInfo: {
        chainId: 137,
        contractAddress: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        decimals: 18,
        symbol: 'WMATIC',
        name: 'Wrapped Matic',
      },
    },
  },
  10000: {
    providerUrl: RPC_URL_SMARTBCH,
    customNetworkData: {
      nameNetwork: 'Mainnet',
      multicallContractAddress: '0x59BDDAB3E5115a1678E6314584d0D1bC2737381E',
      nativeCurrency: {
        name: 'BCH Token',
        symbol: 'BCH',
      },
      nativeWrappedTokenInfo: {
        chainId: 10000,
        contractAddress: '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04',
        decimals: 18,
        symbol: 'WBCH',
        name: 'Wrapped BCH',
      },
    },
  },
  10001: {
    providerUrl: 'https://moeing.tech:9545/',
    customNetworkData: {
      nameNetwork: 'Amber',
      multicallContractAddress: '0x1ca39bcf7B0399E1149622b9524541A02E70a4a9',
      nativeCurrency: {
        name: 'BCH Token',
        symbol: 'BCH',
      },
      nativeWrappedTokenInfo: {
        chainId: 10001,
        contractAddress: '0x17F4FCF5b6E0A95D4eE331c8529041896A073F9b',
        decimals: 18,
        symbol: 'WBCH',
        name: 'Wrapped BCH',
      },
    },
  },
  11155111: {
    providerUrl: RPC_URL_SEPOLIA,
    customNetworkData: {
      nameNetwork: 'Ethereum Sepolia',
      nativeCurrency: {
        name: 'ETH Token',
        symbol: 'ETH',
      },
      nativeWrappedTokenInfo: {
        chainId: 11155111,
        contractAddress: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped ETH',
      },
      baseTokens: {
        dai: {
          chainId: 11155111,
          contractAddress: '0x3e622317f8C93f7328350cF0B56d9eD4C620C5d6',
          decimals: 18,
          symbol: 'DAI',
          name: 'DAI Token',
        },
      },
    },
  },
};

export const LIFI_FEE = 0.02;

export const LIFI_INTEGRATOR_IDS: Record<string, string> = {
  production: 'verse_dex_swap_01',
  staging: 'verse_dex_swap_01',
  development: 'verse_dex_swap_00',
};

export const MULTICHAIN_PROVIDERS = ['bitcoincom_eth'];

export const LIFI_UNLISTED_TOKENS: Record<number, Token[]> = {
  1: [
    {
      address: '0x33eE36e96B3Cc9821deA2D22f9Bb39EC9a8F69b8',
      chainId: 1,
      coinKey: 'AKIRA' as CoinKey,
      symbol: 'AKIRA',
      decimals: 9,
      name: 'Akira Toriyama',
      priceUSD: '6.388991424703183e-8',
    },
    {
      address: '0xEE2a03Aa6Dacf51C18679C516ad5283d8E7C2637',
      chainId: 1,
      coinKey: 'NEIRO' as CoinKey,
      symbol: 'NEIRO',
      decimals: 9,
      name: 'Neiro',
      priceUSD: '0.024473210373146016',
      logoURI: 'https://markets.bitcoin.com/images/coins/32461.png',
    },
  ],
};

export const WRAPPED_TOKENS = [
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04',
];

export const DEBRIDGE_EVM_WALLETS = [
  'Browser Wallet EVM',
  'METAMASK',
  'PHANTOM EVM',
  'COINBASEWALLET',
  'ONEINCHWALLET',
  'Fordefi',
  'Backpack EVM',
  'RABBY',
  'GNOSIS',
  'Brave Wallet',
  'UNSTOPPABLEDOMAINS',
  'Trust Wallet',
];

export const DEBRIDGE_SOLANA_WALLETS = [
  'Frontier',
  'Browser Wallet Solana',
  'PHANTOM',
  'Solflare',
  'SolflareMetamask',
  'Salmon',
  'Backpack',
  'Brave',
  'Coinbase Wallet',
  'Glow',
  'Fordefi Solana',
  'Trust',
];
