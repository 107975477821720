import styled, { css, keyframes } from 'styled-components';

import ExternalLink from '@bitcoin-portal/verse-web-components/dist/Link';
import {
  colors,
  verseColors,
} from '@bitcoin-portal/verse-web-components/dist/themes/colors';

import verseLogo from '../../../../static/images/uploads/frame1.png';
import verseLogo1 from '../../../../static/images/uploads/frame2.png';
import verseLogo2 from '../../../../static/images/uploads/frame3.png';
import verseLogo3 from '../../../../static/images/uploads/frame4.png';

import { media } from '../media';

const logoAnimation = keyframes`
  0%, 100% {
    background-image: url(${verseLogo});
  }
  25% {
    background-image: url(${verseLogo1});
  }
  50% {
    background-image: url(${verseLogo2});
  }
  75% {
    background-image: url(${verseLogo3});
  }
`;

export const NavSection = styled.div<{
  standalone: boolean;
  offsetTop?: boolean;
}>`
  ${({ standalone, offsetTop }) => css`
    grid-area: nav;
    background-color: ${verseColors.verseCardColor};
    box-sizing: border-box;
    border-top: 1px solid ${colors.shade30};
    position: fixed;
    bottom: 0;

    left: 0;
    right: 0;
    z-index: 20;
    height: ${standalone ? '88px' : '60px'};

    ${media.portrait} {
      display: grid;
      top: 0;
      grid-template-areas: 'logo' 'links';
      padding-top: ${offsetTop ? '36px' : 0};

      border-top: none;
      height: 100%;
      max-height: 100vh;
      width: 200px;

      grid-template-columns: none;
      grid-template-rows: 84px auto;
      border-right: 1px solid ${colors.shade30};
    }

    @media (min-width: ${media.large}) {
      width: 240px;
      grid-template-rows: 84px auto 84px;
      align-items: flex-start;
      grid-template-areas:
        'logo'
        'links'
        'bcom';
    }
  `}
`;

export const VerseLogoWrapper = styled.div`
  grid-area: logo;
  align-self: center;
  display: none;
  margin: 2rem 1.5rem 0;
  ${media.portrait} {
    display: block;
  }
`;

export const LinkWrapper = styled.div`
  grid-area: links;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 8px;
  overflow: hidden;

  ${media.portrait} {
    align-items: flex-start;
    padding-left: 24px;
    padding-bottom: 24px;
    margin-top: 0;
    overflow: auto;
    & > :first-child {
      margin: 0;
    }
  }
`;

export const LinkDiv = styled.div`
  flex-grow: 1;
  min-width: 72px;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 68px);
  ${media.portrait} {
    width: inherit;
    flex-direction: column;
  }
`;

export const StyledIcon = styled.div<{
  active?: boolean;
}>`
  ${({ active }) => css`
    position: relative;

    & > #dot-icon {
      position: absolute;
      top: -3px;
      right: -5px;
      color: ${active ? colors.shade60 : colors.shade20};
    }

    ${media.portrait} {
      margin-right: 12px;
    }
  `}
`;

export const StyledExternalLink = styled(ExternalLink)<{
  gap?: boolean;
}>`
  ${({ gap }) => css`
    display: flex;
    flex-direction: column;
    height: 44px;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 14.32px;
    color: ${colors.shade80};
    margin: 5px 0 5px 0;
    padding: 6px 0 4px 0;
    box-sizing: border-box;
    ${media.portrait} {
      flex-direction: row;
      margin-top: ${gap ? '16px' : 0};
      margin-bottom: 0;
      justify-content: flex-start;
      width: 150px;
      padding: 0 0 0 12px;
      font-size: 16px;
      line-height: 19.9px;
    }
    @media (min-width: ${media.large}) {
      width: 192px;
    }
    &:hover {
      height: 44px;
      background: ${colors.shade40};
      border-radius: 4px;
    }
  `}
`;

export const GatsbyLinkWrapper = styled.div<{
  active: boolean;
  gap?: boolean;
}>`
  a {
    ${({ active, gap }) => css`
      display: flex;
      flex-direction: column;
      height: 44px;
      box-sizing: border-box;
      width: 100%;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 14.32px;
      color: ${colors.shade80};
      margin: 5px 0 5px 0;
      padding: 6px 0 4px;
      ${media.portrait} {
        flex-direction: row;
        margin-top: ${gap ? '16px' : 0};
        margin-bottom: 0;
        justify-content: flex-start;
        width: 150px;
        padding: 0 16.37px 0 12px;
        font-size: 16px;
        line-height: 19.9px;
      }
      @media (min-width: ${media.large}) {
        width: 192px;
      }
      &:hover {
        background: ${colors.verseNavBlue};
        border-radius: 4px;

        & ${StyledIcon} > #dot-icon {
          color: ${colors.shade40};
        }
      }
      ${active &&
      css`
        background: ${colors.shade60};
        color: ${colors.white};
        border-radius: 4px;
        &:hover {
          background: ${colors.shade60};

          & ${StyledIcon} > #dot-icon {
            color: ${colors.shade60};
          }
        }
      `}
    `}
  }
`;

export const ClaimWrapper = styled.div`
  a {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin: 5px 0 5px 0;
    padding: 6px 0 4px;
    ${media.portrait} {
      flex-direction: row;
      margin-top: 16px;
      margin-bottom: 0;
      justify-content: flex-start;
      width: 150px;
      padding: 0 16.37px 0 12px;
    }
    @media (min-width: ${media.large}) {
      width: 192px;
    }
  }
`;

export const StyledLabel = styled.div`
  white-space: nowrap;
`;

export const ExternalIconWrapper = styled.div`
  display: none;
  @media (min-width: ${media.large}) {
    display: flex;
    color: ${colors.shade50};
    justify-content: flex-end;
    align-items: center;
    flex-grow: 2;
    padding-right: 16.37px;
  }
`;

export const BcomLogoWrapper = styled.div`
  grid-area: bcom;
  margin: 24px;
  display: none;
  @media (min-width: ${media.large}) {
    display: block;
  }
`;

export const NewBadge = styled.div`
  display: none;
  background: linear-gradient(93.32deg, #0085ff 0%, #db00ff 100%);
  margin-left: 15px;
  padding: 1px 4px;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  @media (min-width: ${media.large}) {
    display: block;
  }
`;

interface AnimatedLogoProps {
  width?: string;
  height?: string;
}

export const AnimatedLogo = styled.div<AnimatedLogoProps>`
  width: ${({ width }) => width || '160px'};
  height: ${({ height }) => height || '80px'};
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${verseLogo});
  @supports not (-webkit-overflow-scrolling: touch) {
    animation: ${logoAnimation} 8s infinite linear;
  }
`;
