import { State } from '../store';

export type LocalePayload = 'en';

export type LocaleAction = {
  type: 'SET_LOCALE';
  payload: LocalePayload;
};

export const localeReducer = (state: State, payload: LocalePayload): State => ({
  ...state,
  locale: payload,
});
