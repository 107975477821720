import { State } from '../store';

export type DeleteExecutingRoutePayload = string;

export type DeleteExecutingRouteAction = {
  type: 'DELETE_EXECUTING_ROUTE';
  payload: DeleteExecutingRoutePayload;
};

export const deleteExecutingRouteReducer = (
  state: State,
  payload: DeleteExecutingRoutePayload,
): State => {
  const routes = state.executingRoutes;
  delete routes[payload];

  return {
    ...state,
    executingRoutes: routes,
  };
};
