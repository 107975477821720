import { State } from '../store';

export type IsWalletAction = {
  type: 'SET_IS_WALLET';
  payload?: boolean;
};

export const isWalletReducer = (
  state: State,
  payload?: IsWalletAction['payload'],
): State => ({
  ...state,
  isWallet: payload,
});
