import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const FarmsIcon: FunctionComponent<{
  fillColor?: string;
  [key: string]: ReactNode;
}> = ({ fillColor = 'currentColor', ...rest }) => {
  return (
    <>
      <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M18.75 3.75C18.75 7.01884 16.6588 9.79925 13.7412 10.8263C12.9617 11.1007 12.1233 11.25 11.25 11.25V16.25H16.25V18.75H3.75V16.25H8.75V13.75C4.60786 13.75 1.25 10.3921 1.25 6.25V3.75H3.75C5.90894 3.75 7.85483 4.66221 9.22324 6.12223C9.92464 6.87059 10.4743 7.76287 10.8232 8.75H11.25C11.9844 8.75 12.6818 8.59166 13.31 8.30727C12.7842 6.59714 11.8122 5.08245 10.5298 3.8991C11.9055 2.27844 13.9576 1.25 16.25 1.25H18.75V3.75Z"
          fill={fillColor}
        />
      </SvgIcon>
    </>
  );
};

export default FarmsIcon;
