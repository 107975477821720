import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  DEFAULT_VALUE,
  EMPTY,
  NULL_WALLET,
  VERSE_ETHEREUM_TOKEN_CONTRACT,
} from '@context/constants';
import { WalletType } from '@context/types';

type SwapState = {
  cexProvider: string;
  refundAddress: string;
  selectedWallet: WalletType;
  swapCryptoBalance: number;
  swapCurrencyRate: string;
  swapFromBlockchain: string;
  swapFromCurrency: string;
  swapFromProtocol: string;
  swapFromTokenAddress: string;
  swapSettleAmount: string;
  swapToBlockchain: string;
  swapToCurrency: string;
  swapToProtocol: string;
  swapToTokenAddress: string;
  toAddress: string;
  setCexProvider: Dispatch<SetStateAction<string>>;
  setRefundAddress: Dispatch<SetStateAction<string>>;
  setSelectedWallet: Dispatch<SetStateAction<WalletType>>;
  setSwapCryptoBalance: Dispatch<SetStateAction<number>>;
  setSwapCurrencyRate: Dispatch<SetStateAction<string>>;
  setSwapFromBlockchain: Dispatch<SetStateAction<string>>;
  setSwapFromCurrency: Dispatch<SetStateAction<string>>;
  setSwapFromProtocol: Dispatch<SetStateAction<string>>;
  setSwapFromTokenAddress: Dispatch<SetStateAction<string>>;
  setSwapSettleAmount: Dispatch<SetStateAction<string>>;
  setSwapToBlockchain: Dispatch<SetStateAction<string>>;
  setSwapToCurrency: Dispatch<SetStateAction<string>>;
  setSwapToProtocol: Dispatch<SetStateAction<string>>;
  setSwapToTokenAddress: Dispatch<SetStateAction<string>>;
  setToAddress: Dispatch<SetStateAction<string>>;
};

const INITIAL_STATE = {
  cexProvider: 'SIDESHIFT_CEX_PROVIDER',
  refundAddress: EMPTY,
  selectedWallet: NULL_WALLET,
  swapCryptoBalance: 0,
  swapCurrencyRate: DEFAULT_VALUE,
  swapFromBlockchain: 'ETH_BLOCKCHAIN',
  swapFromCurrency: 'ETH',
  swapFromProtocol: 'ERC_20_PROTOCOL',
  swapFromTokenAddress: EMPTY,
  swapSettleAmount: DEFAULT_VALUE,
  swapToBlockchain: 'ETH_BLOCKCHAIN',
  swapToCurrency: 'VERSE',
  swapToProtocol: 'ERC_20_PROTOCOL',
  swapToTokenAddress: VERSE_ETHEREUM_TOKEN_CONTRACT,
  toAddress: EMPTY,
  setRefundAddress: () => null,
  setSelectedWallet: () => null,
  setSwapCryptoBalance: () => null,
  setSwapCurrencyRate: () => null,
  setSwapFromBlockchain: () => null,
  setSwapFromCurrency: () => null,
  setSwapFromProtocol: () => null,
  setSwapFromTokenAddress: () => null,
  setSwapSettleAmount: () => null,
  setSwapToBlockchain: () => null,
  setSwapToCurrency: () => null,
  setSwapToProtocol: () => null,
  setSwapToTokenAddress: () => null,
  setToAddress: () => null,
};

const SwapContext = createContext<SwapState>(INITIAL_STATE);

export const SwapProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cexProvider, setCexProvider] = useState('SIDESHIFT_CEX_PROVIDER');
  const [refundAddress, setRefundAddress] = useState(EMPTY);
  const [selectedWallet, setSelectedWallet] = useState(NULL_WALLET);
  const [swapCryptoBalance, setSwapCryptoBalance] = useState(0);
  const [swapCurrencyRate, setSwapCurrencyRate] = useState(DEFAULT_VALUE);
  const [swapFromBlockchain, setSwapFromBlockchain] =
    useState('ETH_BLOCKCHAIN');
  const [swapFromCurrency, setSwapFromCurrency] = useState('ETH');
  const [swapFromProtocol, setSwapFromProtocol] = useState('ERC_20_PROTOCOL');
  const [swapFromTokenAddress, setSwapFromTokenAddress] = useState(EMPTY);
  const [swapSettleAmount, setSwapSettleAmount] = useState(DEFAULT_VALUE);
  const [swapToBlockchain, setSwapToBlockchain] = useState('ETH_BLOCKCHAIN');
  const [swapToCurrency, setSwapToCurrency] = useState('VERSE');
  const [swapToProtocol, setSwapToProtocol] = useState('ERC_20_PROTOCOL');
  const [swapToTokenAddress, setSwapToTokenAddress] = useState(
    VERSE_ETHEREUM_TOKEN_CONTRACT,
  );
  const [toAddress, setToAddress] = useState(EMPTY);

  const contextValue = useMemo(
    () => ({
      cexProvider,
      refundAddress,
      selectedWallet,
      swapCryptoBalance,
      swapCurrencyRate,
      swapFromBlockchain,
      swapFromCurrency,
      swapFromProtocol,
      swapFromTokenAddress,
      swapSettleAmount,
      swapToBlockchain,
      swapToCurrency,
      swapToProtocol,
      swapToTokenAddress,
      toAddress,
      setCexProvider,
      setRefundAddress,
      setSelectedWallet,
      setSwapCryptoBalance,
      setSwapCurrencyRate,
      setSwapFromBlockchain,
      setSwapFromCurrency,
      setSwapFromProtocol,
      setSwapFromTokenAddress,
      setSwapSettleAmount,
      setSwapToBlockchain,
      setSwapToCurrency,
      setSwapToProtocol,
      setSwapToTokenAddress,
      setToAddress,
    }),
    [
      cexProvider,
      refundAddress,
      selectedWallet,
      swapCryptoBalance,
      swapCurrencyRate,
      swapFromBlockchain,
      swapFromCurrency,
      swapFromProtocol,
      swapFromTokenAddress,
      swapSettleAmount,
      swapToBlockchain,
      swapToCurrency,
      swapToProtocol,
      swapToTokenAddress,
      toAddress,
    ],
  );

  return (
    <SwapContext.Provider value={contextValue}>{children}</SwapContext.Provider>
  );
};

export const useSwapContext = () => useContext(SwapContext);
