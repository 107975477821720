import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { ChainId, useCall, useEthers } from '@usedapp/core';
import { BigNumber } from 'ethers';

import { ACTIVE_ENV } from '@context/constants';
import { useBcomAccounts } from '@context/providers/BcomAccountsProvider';

import {
  verseBalancesContractEthereum,
  verseBalancesContractPolygon,
  verseBalancesContractSepolia,
} from '../../contracts/verseBalancesABI';

const isDev = ACTIVE_ENV === 'development' || ACTIVE_ENV === 'staging';

export type TVerseBalances = {
  totalVerse?: BigNumber;
  verseLiquid?: BigNumber;
  verseStaked?: BigNumber;
  verseInFarms?: BigNumber;
  verseInPools?: BigNumber;
  verseEarnedStaked?: BigNumber;
  verseEarnedFarmed?: BigNumber;
};

type TVerseBalancesContext = Record<number, TVerseBalances | undefined>;

export const VerseBalancesContext = createContext<
  TVerseBalancesContext | undefined
>(undefined);

const VerseBalancesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { bcomAccounts } = useBcomAccounts();
  const { account } = useEthers();

  const ethMainnetAccount = bcomAccounts?.[ChainId.Mainnet] || account;
  const polygonAccount = bcomAccounts?.[ChainId.Polygon] || account;

  const { value: totalVerseBreakdownEthereum } =
    useCall(
      {
        contract: verseBalancesContractEthereum,
        args: [ethMainnetAccount],
        method: 'getTotalVerseBreakDown',
      },
      {
        chainId: ChainId.Mainnet,
      },
    ) ?? {};

  const { value: totalVerseBreakdownPolygon } =
    useCall(
      {
        contract: verseBalancesContractPolygon,
        args: [polygonAccount],
        method: 'getTotalVerseBreakDown',
      },
      {
        chainId: ChainId.Polygon,
      },
    ) ?? {};

  const { value: totalVerseBreakdowSepolia } =
    useCall(
      isDev
        ? {
            contract: verseBalancesContractSepolia,
            args: [account],
            method: 'getTotalVerseBreakDown',
          }
        : null,
      {
        chainId: ChainId.Sepolia,
      },
    ) ?? {};

  const polygonValues = useMemo(() => {
    if (!totalVerseBreakdownPolygon) {
      return undefined;
    }

    return {
      totalVerse: totalVerseBreakdownPolygon.totalVerse,
      verseLiquid: totalVerseBreakdownPolygon.verseLiquid,
      verseStaked: totalVerseBreakdownPolygon.verseStaked,
      verseInFarms: totalVerseBreakdownPolygon.verseInFarms,
      verseInPools: totalVerseBreakdownPolygon.verseInPools,
      verseEarnedStaked: totalVerseBreakdownPolygon.verseEarnedStaked,
      verseEarnedFarmed: totalVerseBreakdownPolygon.verseEarnedFarmed,
    };
  }, [totalVerseBreakdownPolygon]);

  const ethereumValues = useMemo(() => {
    if (!totalVerseBreakdownEthereum) {
      return undefined;
    }

    return {
      totalVerse: totalVerseBreakdownEthereum.totalVerse,
      verseLiquid: totalVerseBreakdownEthereum.verseLiquid,
      verseStaked: totalVerseBreakdownEthereum.verseStaked,
      verseInFarms: totalVerseBreakdownEthereum.verseInFarms,
      verseInPools: totalVerseBreakdownEthereum.verseInPools,
      verseEarnedStaked: totalVerseBreakdownEthereum.verseEarnedStaked,
      verseEarnedFarmed: totalVerseBreakdownEthereum.verseEarnedFarmed,
    };
  }, [totalVerseBreakdownEthereum]);

  const sepoliaValues = useMemo(() => {
    if (!totalVerseBreakdowSepolia || !isDev) {
      return undefined;
    }

    return {
      totalVerse: totalVerseBreakdowSepolia.totalVerse,
      verseLiquid: totalVerseBreakdowSepolia.verseLiquid,
      verseStaked: totalVerseBreakdowSepolia.verseStaked,
      verseInFarms: totalVerseBreakdowSepolia.verseInFarms,
      verseInPools: totalVerseBreakdowSepolia.verseInPools,
      verseEarnedStaked: totalVerseBreakdowSepolia.verseEarnedStaked,
      verseEarnedFarmed: totalVerseBreakdowSepolia.verseEarnedFarmed,
    };
  }, [totalVerseBreakdowSepolia]);

  const value: TVerseBalancesContext | undefined = useMemo(() => {
    // console.log('Verse Balances Memoization');
    return {
      [ChainId.Mainnet]: ethereumValues,
      [ChainId.Polygon]: polygonValues,
      ...(isDev ? { [ChainId.Sepolia]: sepoliaValues } : {}),
    };
  }, [ethereumValues, polygonValues, sepoliaValues]);

  return (
    <VerseBalancesContext.Provider value={value}>
      {children}
    </VerseBalancesContext.Provider>
  );
};

export const useVerseBalances = () => useContext(VerseBalancesContext);

export default VerseBalancesProvider;
