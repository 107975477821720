import { State } from '../store';

export type ConnectModalOpenPayload = boolean;

export type ConnectModalOpenAction = {
  type: 'SET_CONNECT_MODAL_OPEN';
  payload: ConnectModalOpenPayload;
};

export const connectModalOpenReducer = (
  state: State,
  payload: ConnectModalOpenPayload,
): State => ({
  ...state,
  connectModalOpen: payload,
});
