import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { SubgraphAPIClient } from '@context/api/subgraph';
import { useTrackedState } from '@context/store';
import { DEXTokensVerse } from '@context/tokens';
import { DexTokenType } from '@context/types';

import { findProvider, subgraphApiForProvider } from '@helpers/index';

import sethTokens from '../../mocks/bitcoincom_seth_tokens.json';
import { ETH_TESTNET_PROVIDERS } from '../constants';

type ProviderInfoType = {
  [key: string]: {
    blockchain: string;
    protocol: string;
    explorer: string;
  };
};

type DexTokensState = ITickerObject[];

type DexTokensPayload = {
  provider: string;
  tokens: DexTokenType[];
};

const PROVIDER_CHAINS: ProviderInfoType = {
  bitcoincom: {
    blockchain: 'BCH',
    protocol: 'SEP20_PROTOCOL',
    explorer: 'https://smartscout.cash/tx/',
  },
  bitcoincom_eth: {
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    explorer: 'https://etherscan.io/tx/',
  },
};

const DexTokensContext = createContext<DexTokensState>(DEXTokensVerse);

const formatDexTokens = (payload: DexTokensPayload): ITickerObject[] => {
  const chainInfo =
    PROVIDER_CHAINS[payload.provider] || PROVIDER_CHAINS.bitcoincom_eth;

  const dexTokens = payload.tokens
    .filter(item => item.symbol !== 'WBCH' && parseFloat(item.liquidity) > 0)
    .map((token: DexTokenType) => {
      return {
        ...chainInfo,
        abbr: token.symbol,
        value: token.symbol,
        label: token.name,
        ticker: token.symbol?.toLowerCase(),
        token: token.id,
        decimals: parseInt(token.decimals, 10),
      };
    });

  if (payload.provider === 'bitcoincom_eth') {
    const verseToken = dexTokens.find(t => t.ticker.toUpperCase() === 'VERSE');

    dexTokens.unshift({
      abbr: 'ETH',
      value: 'ETH',
      blockchain: 'ETH',
      protocol: 'ETH_PROTOCOL',
      label: 'Ethereum',
      ticker: 'eth',
      token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2_ETH',
      explorer: 'https://etherscan.io/tx/',
      decimals: 18,
    });

    if (verseToken) {
      const verseIdx = dexTokens.indexOf(verseToken);
      dexTokens.splice(verseIdx, 1);
      dexTokens.unshift(verseToken);
    }
  }

  if (payload.provider === 'bitcoincom') {
    dexTokens.unshift({
      abbr: 'BCH',
      value: 'BCH',
      blockchain: 'BCH',
      protocol: 'SEP20_PROTOCOL',
      label: 'Bitcoin Cash',
      ticker: 'bch',
      explorer: 'https://smartscout.cash/tx/',
      token: '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04_ETH',
      decimals: 18,
    });
  }

  if (payload.provider === 'bitcoincom_seth') {
    dexTokens.unshift({
      abbr: 'ETH',
      value: 'ETH',
      blockchain: 'ETH',
      protocol: 'ETH_PROTOCOL',
      label: 'SepoliaETH',
      ticker: 'eth',
      token: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14_ETH',
      explorer: 'https://sepolia.etherscan.io/tx/',
      decimals: 18,
    });
  }

  return dexTokens;
};

export const DexTokensProvider: FC<PropsWithChildren> = ({ children }) => {
  const { provider: exchangeProvider } = useTrackedState();

  const [dexTokens, setDexTokens] = useState<ITickerObject[]>(DEXTokensVerse);

  useEffect(() => {
    const fetchDexTokens = async () => {
      const provider = findProvider(exchangeProvider);

      if (!provider.isDeFi) return;

      const subgraphClient = new SubgraphAPIClient(
        subgraphApiForProvider(provider),
      );
      const response = ETH_TESTNET_PROVIDERS.includes(exchangeProvider)
        ? sethTokens
        : await subgraphClient.getTokenList();

      if (response.tokens?.length) {
        const { tokens } = response;
        const formattedTokens = formatDexTokens({
          provider: exchangeProvider,
          tokens,
        });

        setDexTokens(formattedTokens);
      }
    };

    fetchDexTokens();
  }, [exchangeProvider]);

  return (
    <DexTokensContext.Provider value={dexTokens}>
      {children}
    </DexTokensContext.Provider>
  );
};

export const useDexTokens = () => useContext(DexTokensContext);

export default DexTokensProvider;
