const verseBtcStakingData = {
  contractAddress: '0xcbE5F4E8a112F25C2F902714e3cBB7955F19Bb36',
  stakingToken: {
    // ITickerObject
    token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    decimals: 18,
    abbr: 'VERSE',
    value: 'VERSE',
    blockchain: 'ETH',
    label: 'Verse',
    ticker: 'verse',
    explorer: '',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/verse.png',
  },
  rewardToken: {
    // ITickerObject
    token: '0x18084fba666a33d37592fa2633fd49a74dd93a88',
    decimals: 18,
    abbr: 'tBTC',
    value: 'tBTC',
    blockchain: 'ETH',
    label: 'tBTC v2',
    ticker: 'tbtc',
    explorer: 'https://etherscan.io/tx/',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/26133.png',
  },
  rewardTokens: [
    {
      // ITickerObject
      token: '0x18084fba666a33d37592fa2633fd49a74dd93a88',
      decimals: 18,
      abbr: 'tBTC',
      value: 'tBTC',
      blockchain: 'ETH',
      label: 'tBTC v2',
      ticker: 'tbtc',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/26133.png',
    },
  ],
  pair: {
    id: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    name: 'VERSE',
    token0: {
      name: 'Verse',
      symbol: 'VERSE',
      id: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
    },
  },
  singleSided: true,
  customPath: 'verse-btc',
  dynamicRewards: true,
};

export default verseBtcStakingData;
