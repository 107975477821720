import styled, { css } from 'styled-components';

import Label from '@bitcoin-portal/verse-web-components/dist/Label';
import { media } from '@bitcoin-portal/verse-web-components/dist/themes/breakpoints';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const ProviderTypeLabel = styled(Label)`
  ${({ theme }) => css`
    padding: ${theme.spacing.unit}px ${theme.spacing.unit * 3}px
      ${theme.spacing.unit * 0.5}px;
    font-size: 14px;
    text-transform: uppercase;
    color: ${colors.shade80} !important;
    ${media.sm`
      font-size: 10px !important;
      padding: ${theme.spacing.unit}px ${theme.spacing.unit * 2}px !important;
    `}
  `}
`;
