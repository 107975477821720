import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const VoyagersIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="voyagers" viewBox="0 0 20 20" fill="none" {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.17157 1.17157C0 2.34315 0 4.22876 0 8V12C0 15.7712 0 17.6569 1.17157 18.8284C2.34315 20 4.22876 20 8 20H12C15.7712 20 17.6569 20 18.8284 18.8284C20 17.6569 20 15.7712 20 12V8C20 4.22876 20 2.34315 18.8284 1.17157C17.6569 0 15.7712 0 12 0H8C4.22876 0 2.34315 0 1.17157 1.17157ZM4.31738 16.7466C4.52897 16.9155 4.81864 17 5.1864 17H14.8136C15.1814 17 15.471 16.9155 15.6826 16.7466C15.8942 16.5777 16 16.3499 16 16.0632C16 15.5922 15.8615 15.0982 15.5844 14.5812C15.3073 14.0642 14.9068 13.583 14.3829 13.1376C13.8589 12.6871 13.2267 12.3211 12.4861 12.0396C11.7506 11.7529 10.9219 11.6096 10 11.6096C9.07305 11.6096 8.2393 11.7529 7.49874 12.0396C6.76322 12.3211 6.1335 12.6871 5.60957 13.1376C5.09068 13.583 4.6927 14.0642 4.41562 14.5812C4.13854 15.0982 4 15.5922 4 16.0632C4 16.3499 4.10579 16.5777 4.31738 16.7466ZM8.5869 9.88955C9.02015 10.1711 9.4937 10.3119 10.0076 10.3119C10.5164 10.3119 10.9849 10.1711 11.4131 9.88955C11.8463 9.60287 12.194 9.21894 12.4559 8.73774C12.7179 8.25143 12.8489 7.7088 12.8489 7.10986C12.8489 6.52117 12.7179 5.99134 12.4559 5.52038C12.194 5.04942 11.8463 4.67828 11.4131 4.40697C10.9849 4.13566 10.5164 4 10.0076 4C9.49874 4 9.02771 4.13822 8.59446 4.41465C8.16121 4.69108 7.8136 5.06478 7.55164 5.53574C7.29471 6.00669 7.16625 6.53396 7.16625 7.11754C7.16625 7.71648 7.29471 8.25655 7.55164 8.73774C7.8136 9.21894 8.15869 9.60287 8.5869 9.88955Z"
          fill="#899BB5"
        />
      </SvgIcon>
    </>
  );
};

export default VoyagersIcon;
