import { useStytch } from '@stytch/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLocation } from '@reach/router';

import Button from '@bitcoin-portal/verse-web-components/dist/Button';
import Refresh from '@bitcoin-portal/verse-web-components/dist/Icons/Refresh';

import { useDispatch } from '../../context/store';

import { sendEmailMagicLink } from '../../helpers';

import Email from '../../../static/images/uploads/email.png';

import { Confirmation, EmailWrapper } from './styled';

interface Props {
  email?: string;
}

const CheckEmailModal: FunctionComponent<Props> = ({ email }) => {
  const stytchClient = useStytch();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisabled(false);
    }, 1000 * 10);

    return () => clearTimeout(timeout);
  }, []);

  const resendEmail = async () => {
    await sendEmailMagicLink(stytchClient, email as string, pathname, dispatch);
    setDisabled(true);

    setTimeout(() => {
      setDisabled(false);
    }, 1000 * 10);
  };

  return (
    <Confirmation>
      <div>
        <img src={Email} alt="paper airplane" />
      </div>
      <div>
        <FormattedMessage
          id="global.modals.check-email.sent"
          values={{
            EMAIL: <EmailWrapper>{email}</EmailWrapper>,
          }}
        />
      </div>
      <div>
        <FormattedMessage id="global.modals.check-email.cta" />
      </div>
      <Button design="clear" onClick={resendEmail} disabled={disabled}>
        {!disabled && <Refresh size={15} />}
        <span
          style={{
            paddingLeft: 4,
          }}
        >
          <FormattedMessage id="global.modals.check-email.resend" />
        </span>
      </Button>
    </Confirmation>
  );
};

export default CheckEmailModal;
