import styled from 'styled-components';

import Button from '@bitcoin-portal/verse-web-components/dist/Button';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const StyledText = styled.div`
  text-align: center;
  color: ${colors.shade70};
  font-size: 12px;
`;

export const StyledButton = styled(Button)`
  padding: 5px ${({ theme }) => theme.spacing.unit * 1.5}px;
`;
