import { State } from '../store';

export type FallbackEstimatesAction = {
  type: 'SET_FALLBACK_ESTIMATES';
  payload: FallbackEstimatesType;
};

export const fallbackEstimatesReducer = (
  state: State,
  payload: FallbackEstimatesType,
): State => ({
  ...state,
  fallbackEstimates: payload,
});
