import React, { FC } from 'react';

import { useEthers } from '@usedapp/core';

import { useDispatch, useTrackedState } from '../../../../context/store';

import { redirectAfterLogin } from '../../../../helpers';

import metamaskIcon from '../../../../../static/images/uploads/metamask.png';

import { ConnectBubble, ConnectButton, WalletDisplay } from './styled';

const MetaMaskConnectButton: FC = () => {
  const { activateBrowserWallet } = useEthers();
  const { loginFrom } = useTrackedState();
  const dispatch = useDispatch();

  return (
    <ConnectButton
      fullWidth
      design="flat"
      onClick={(e: React.UIEvent<HTMLElement>) => {
        e.stopPropagation();

        activateBrowserWallet({ type: 'metamask' });

        if (loginFrom) {
          redirectAfterLogin(loginFrom, dispatch);
        }
      }}
    >
      <div>
        <img src={metamaskIcon} width="32px" alt="MetaMask" />
        <WalletDisplay>
          <span>MetaMask</span>
        </WalletDisplay>
      </div>
      <ConnectBubble>Connect</ConnectBubble>
    </ConnectButton>
  );
};

export default MetaMaskConnectButton;
