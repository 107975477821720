import { ChainId } from '@usedapp/core';
import { Contract } from 'ethers';

import { VERSE_BALANCES_CONTRACTS } from '../context/constants';

export const VerseBalancesABI = [
  {
    inputs: [
      {
        internalType: 'contract IVerseFarm',
        name: '_verseStaking',
        type: 'address',
      },
      {
        internalType: 'contract ISwapsPair[]',
        name: '_lpTokens',
        type: 'address[]',
      },
      {
        internalType: 'contract IVerseFarm[]',
        name: '_verseFarms',
        type: 'address[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [],
    name: 'FARM_COUNT',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'LP_COUNT',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VERSE_STAKING',
    outputs: [
      {
        internalType: 'contract IVerseFarm',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VERSE_TOKEN',
    outputs: [
      {
        internalType: 'contract ISwapsERC20',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISwapsPair[]',
        name: '_lpTokenAddress',
        type: 'address[]',
      },
      {
        internalType: 'address',
        name: '_lpTokenHolderAddress',
        type: 'address',
      },
    ],
    name: 'calculateBulkVerseInLP',
    outputs: [
      {
        internalType: 'uint256',
        name: 'verseAmount',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_verseHolderAddress',
        type: 'address',
      },
    ],
    name: 'calculateLiquidVerse',
    outputs: [
      {
        internalType: 'uint256',
        name: 'verseAmount',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IVerseFarm',
        name: '_verseFarm',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_lpTokenHolderAddress',
        type: 'address',
      },
    ],
    name: 'calculateVerseInFarm',
    outputs: [
      {
        internalType: 'uint256',
        name: 'verseInFarm',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseEarned',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_lpTokenHolderAddress',
        type: 'address',
      },
    ],
    name: 'calculateVerseInFarms',
    outputs: [
      {
        internalType: 'uint256',
        name: 'verseInFarms',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseEarned',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISwapsPair',
        name: '_lpTokenAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_lpTokenHolderAddress',
        type: 'address',
      },
    ],
    name: 'calculateVerseInLP',
    outputs: [
      {
        internalType: 'uint256',
        name: 'verseAmount',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_lpTokenHolderAddress',
        type: 'address',
      },
    ],
    name: 'calculateVerseInPools',
    outputs: [
      {
        internalType: 'uint256',
        name: 'verseAmount',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_lpTokenHolderAddress',
        type: 'address',
      },
    ],
    name: 'calculateVerseInStaking',
    outputs: [
      {
        internalType: 'uint256',
        name: 'verseStaked',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseEarned',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_verseHolderAddress',
        type: 'address',
      },
    ],
    name: 'getTotalVerse',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalVerse',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_verseHolderAddress',
        type: 'address',
      },
    ],
    name: 'getTotalVerseBreakDown',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalVerse',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseLiquid',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseStaked',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseInFarms',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseInPools',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseEarnedStaked',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'verseEarnedFarmed',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'lpPairs',
    outputs: [
      {
        internalType: 'contract ISwapsPair',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'verseFarms',
    outputs: [
      {
        internalType: 'contract IVerseFarm',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const;

export const verseBalancesContractEthereum = new Contract(
  VERSE_BALANCES_CONTRACTS[ChainId.Mainnet],
  VerseBalancesABI,
);

export const verseBalancesContractPolygon = new Contract(
  VERSE_BALANCES_CONTRACTS[ChainId.Polygon],
  VerseBalancesABI,
);

export const verseBalancesContractSepolia = new Contract(
  VERSE_BALANCES_CONTRACTS[ChainId.Sepolia],
  VerseBalancesABI,
);
