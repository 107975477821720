import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const ClaimVerseIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon
        data-icon="claim-verse"
        viewBox="0 0 20 20"
        fill="none"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.81818C5.48131 1.81818 1.81818 5.48131 1.81818 10C1.81818 14.5187 5.48131 18.1818 10 18.1818C14.5187 18.1818 18.1818 14.5187 18.1818 10C18.1818 5.48131 14.5187 1.81818 10 1.81818ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM15.223 7.25383L8.91056 14.3106L4.83322 10.4932L6.07587 9.16592L8.79547 11.7121L13.8679 6.04163L15.223 7.25383Z"
          fill="#899BB5"
        />
      </SvgIcon>
    </>
  );
};

export default ClaimVerseIcon;
