import React, { FC, RefObject, useEffect, useState } from 'react';

import { JsonRpcProvider } from '@ethersproject/providers';
import { navigate, useLocation } from '@reach/router';
import { useEthers } from '@usedapp/core';

import Modal from '@bitcoin-portal/verse-web-components/dist/Modal/Modal';

import { useEns } from '@context/providers/EnsProvider';

import { TEST_ACCOUNT } from '../../../../context/constants';

import { shortAddress } from '../../../../helpers';

import metamaskIcon from '../../../../../static/images/uploads/metamask.png';
import walletconnectIcon from '../../../../../static/images/uploads/walletconnect.png';

import { ConnectButton, DisconnectBubble, WalletDisplay } from './styled';

interface Props {
  loginModalRef?: RefObject<Modal>;
}

const DisconnectButton: FC<Props> = ({ loginModalRef }) => {
  const { pathname } = useLocation();
  const username = useEns();
  const { account: ethersAccount, deactivate, library } = useEthers();
  const account = TEST_ACCOUNT || ethersAccount;
  const [isMetaMask, setIsMetaMask] = useState(false);

  useEffect(() => {
    setIsMetaMask((library as JsonRpcProvider)?.connection?.url === 'metamask');
  }, [library]);

  return (
    <ConnectButton
      fullWidth
      design="primary"
      onClick={(e: React.UIEvent<HTMLElement>) => {
        e.stopPropagation();
        deactivate();
        loginModalRef?.current?.close();

        if (pathname.includes('/pools/create')) {
          navigate('/pools/eth/');
        }
      }}
    >
      <div>
        <img
          src={isMetaMask ? metamaskIcon : walletconnectIcon}
          width="32px"
          alt="Connected wallet"
        />
        <WalletDisplay>
          <span>{isMetaMask ? 'MetaMask' : 'WalletConnect'}</span>
          <span>{username || shortAddress(account)}</span>
        </WalletDisplay>
      </div>
      <DisconnectBubble>Disconnect</DisconnectBubble>
    </ConnectButton>
  );
};

export default DisconnectButton;
