import React, {
  Dispatch,
  FunctionComponent,
  RefObject,
  SetStateAction,
} from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '@bitcoin-portal/verse-web-components/dist/Link';
import Modal from '@bitcoin-portal/verse-web-components/dist/Modal';

import { useTrackedState } from '../../../context/store';

import { ButtonWrapper, Heading, Purpose } from '../styled';

import LogoutButton from './ConnectButtons/LogoutButton';
import StytchLogin from './StytchLogin';

interface Props {
  loginModalRef?: RefObject<Modal>;
  checkEmailModalRef?: RefObject<Modal>;
  updateEmail: (email: string) => void;
  email: string;
  setSubmittedEmail: Dispatch<SetStateAction<string>>;
  trackEvent?: (connection: string) => void;
}

const LoginModal: FunctionComponent<Props> = ({
  loginModalRef,
  checkEmailModalRef,
  updateEmail,
  email,
  setSubmittedEmail,
  trackEvent,
}) => {
  const { userDetails } = useTrackedState();

  const BcomWalletPurpose = userDetails ? (
    <Purpose secondary>
      <FormattedMessage id="global.modals.login.cefi.connected" />
      &nbsp;
    </Purpose>
  ) : (
    <Purpose secondary>
      <FormattedMessage
        id="global.modals.login.cefi.sign-in"
        values={{
          BITCOIN_DOT_COM_APP_LINK: (
            <Link href="https://wallet.bitcoin.com" newTab>
              <FormattedMessage id="global.modals.login.cefi.link-cta" />
            </Link>
          ),
        }}
      />
    </Purpose>
  );

  return (
    <div style={{ padding: '24px 32px 24px' }}>
      <Heading>
        <FormattedMessage id="global.modals.login.cefi.header" />
      </Heading>
      {BcomWalletPurpose}
      <ButtonWrapper>
        {userDetails ? (
          <LogoutButton />
        ) : (
          <StytchLogin
            loginModalRef={loginModalRef}
            checkEmailModalRef={checkEmailModalRef}
            updateEmail={updateEmail}
            email={email}
            setSubmittedEmail={setSubmittedEmail}
            trackEvent={trackEvent}
          />
        )}
      </ButtonWrapper>
    </div>
  );
};

export default LoginModal;
