import React, { FunctionComponent, useState } from 'react';

import Cross from '@bitcoin-portal/verse-web-components/dist/Icons/Cross';

import { TMenuItem } from '../../../context/types';

import TripleDotsIcon from '../../Icons/TripleDotsIcon';

import Modal from './Modal';
import { Overlay, StyledMenuButton } from './styled';

interface Props {
  list: TMenuItem[];
  standalone: boolean;
  trackEvent?: (label: string, isNew?: boolean) => void;
}

const ModalMenuBtn: FunctionComponent<Props> = ({
  list,
  standalone,
  trackEvent,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  if (list.length === 0) return null;

  return (
    <>
      {menuOpen && (
        <Overlay onClick={() => setMenuOpen(false)} standalone={standalone} />
      )}
      <StyledMenuButton
        onClick={() => {
          const eventLabel = menuOpen ? 'close' : 'expand';
          trackEvent?.(eventLabel);
          setMenuOpen(!menuOpen);
        }}
        open={menuOpen}
      >
        {menuOpen ? <Cross size={15} /> : <TripleDotsIcon width={20} />}
      </StyledMenuButton>
      <Modal
        list={list}
        show={menuOpen}
        setShow={setMenuOpen}
        standalone={standalone}
        trackEvent={trackEvent}
      />
    </>
  );
};

export default ModalMenuBtn;
