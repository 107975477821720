// validates a url pair against current liquidity pairs
export const getPairForPath = (
  pairString: string,
  lpArray: ILiquidityPair[],
): ILiquidityPair | undefined => {
  const [tokenA, tokenB] = pairString.split('-');
  const foundPair = lpArray.find(lp => {
    const s1 = tokenA.toUpperCase();
    const s2 = tokenB.toUpperCase();

    const token0 = lp.token0.symbol.toUpperCase();
    const token1 = lp.token1.symbol.toUpperCase();
    return (token0 === s1 && token1 === s2) || (token0 === s2 && token1 === s1);
  });
  return foundPair;
};
