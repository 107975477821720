import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const StakingIcon: FunctionComponent<{
  fillColor?: string;
  [key: string]: ReactNode;
}> = ({ fillColor = 'currentColor', ...rest }) => {
  return (
    <>
      <SvgIcon
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M0.25 6.25V3.75L9 0L17.75 3.75V6.25L9 10L0.25 6.25Z"
          fill={fillColor}
        />
        <path
          d="M9 20L0.25 16.25V13.75L9 17.5L17.75 13.75V16.25L9 20Z"
          fill={fillColor}
        />
        <path
          d="M0.25 11.25L9 15L17.75 11.25V8.75L9 12.5L0.25 8.75V11.25Z"
          fill={fillColor}
        />
      </SvgIcon>
    </>
  );
};

export default StakingIcon;
