import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { JsonRpcProvider } from '@ethersproject/providers';
import { useConnector } from '@usedapp/core';

import { accountsMapForProvider } from '@helpers/index';

type TBcomAccountsContext = {
  bcomAccounts: Record<number, string>;
  bcomChains: number[];
};

const BcomAccountsContext = createContext<TBcomAccountsContext>({
  bcomAccounts: {},
  bcomChains: [],
});

export const BcomAccountsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { connector } = useConnector();

  const [bcomAccounts, setBcomAccounts] = useState({});
  const [bcomChains, setBcomChains] = useState([]);

  useEffect(() => {
    const web3Provider = connector?.getProvider() as unknown as JsonRpcProvider;
    const connectedChains = web3Provider?.provider?.namespaces?.eip155?.chains;
    const accounts = accountsMapForProvider(web3Provider);

    const chainIds = connectedChains?.map((namespace: string) =>
      parseInt(namespace?.split(':')?.pop(), 10),
    );

    setBcomAccounts(accounts);
    setBcomChains(chainIds);
  }, [connector?.active]);

  const value = useMemo(
    () => ({
      bcomAccounts,
      bcomChains,
    }),
    [bcomAccounts, bcomChains],
  );

  return (
    <BcomAccountsContext.Provider value={value}>
      {children}
    </BcomAccountsContext.Provider>
  );
};

export const useBcomAccounts = () => useContext(BcomAccountsContext);

export default BcomAccountsProvider;
