import React, { FunctionComponent, useEffect } from 'react';

import { DEFAULT_DECIMALS } from '@context/constants';
import { useFarms } from '@context/providers/FarmsProvider';

import { formatValue } from '@helpers/formatValue';

import { useDispatch } from '../store';

interface Props {
  farm: IFarmPair;
}
export const FarmDepositsHandler: FunctionComponent<Props> = ({ farm }) => {
  const dispatch = useDispatch();
  const { farmBalances } = useFarms();

  const stakedAmount = parseFloat(
    formatValue(farmBalances[farm.contractAddress], DEFAULT_DECIMALS),
  );

  useEffect(() => {
    dispatch({
      type: 'SET_FARM_DEPOSITS',
      payload: {
        [farm.pair.id.toLowerCase()]: stakedAmount,
      },
    });
  }, [stakedAmount]);

  return <></>;
};

export default FarmDepositsHandler;
