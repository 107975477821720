import oneBchIcon from '../../assets/tokens/1bch2x.png';
import catsIcon from '../../assets/tokens/cats2x.png';
import ebenIcon from '../../assets/tokens/eben2x.png';
import emberIcon from '../../assets/tokens/ember2x.png';
import fireIcon from '../../assets/tokens/fire2x.png';
import gocIcon from '../../assets/tokens/goc2x.png';
import lawIcon from '../../assets/tokens/law2x.png';
import lnsIcon from '../../assets/tokens/lns2x.png';
import mistIcon from '../../assets/tokens/mist2x.png';
import potaIcon from '../../assets/tokens/pota2x.png';
import rbchIcon from '../../assets/tokens/rbch2x.png';
import rmzIcon from '../../assets/tokens/rmz2x.png';
import sssIcon from '../../assets/tokens/sss2x.png';
import tangoIcon from '../../assets/tokens/tango2x.png';

const iconList = {
  onebch: oneBchIcon,
  cats: catsIcon,
  eben: ebenIcon,
  ember: emberIcon,
  fire: fireIcon,
  law: lawIcon,
  tango: tangoIcon,
  rbch: rbchIcon,
  rmz: rmzIcon,
  pota: potaIcon,
  goc: gocIcon,
  sss: sssIcon,
  mist: mistIcon,
  lns: lnsIcon,
};

export default iconList;
