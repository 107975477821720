import {
  LpDepositSequenceEndedProperties,
  LpDepositSequenceStartedProperties,
  LpDepositSuccessCtaProperties,
  MultichainSwapResultProperties,
  MultichainSwapStartedProperties,
  OrderCompletedProperties,
  OrderStartedProperties,
  PoolVisitedProperties,
  PoolsVisitedProperties,
  QuoteReceivedProperties,
  ampli,
} from '@bitcoin-portal/web-ampli';

export const logSwapEnteredEvent = (
  fromBlockchain: string,
  toBlockchain: string,
): void => {
  ampli.swapEntered({
    platform: 'DEX',
    entrypoint: 'top',
    'from blockchain': fromBlockchain,
    'to blockchain': toBlockchain,
  });
};

export const logQuoteReceivedEvent = (event: ISwapEvent): void => {
  ampli.quoteReceived({
    from: event.from,
    to: event.to,
    'from chainAssetProtocol': event.fromProtocol,
    'to chainAssetProtocol': event.toProtocol,
    'from blockchain': event.fromBlockchain,
    'to blockchain': event.toBlockchain,
    'value crypto': event.valueCrypto.toString(),
    'value usd': event.valueUsd.toString(),
    provider: event.provider,
    platform: 'DEX',
  } as QuoteReceivedProperties);
};

export const logOrderCreatedEvent = (event: ISwapEvent): void => {
  ampli.orderStarted({
    from: event.from,
    to: event.to,
    'from chainAssetProtocol': event.fromProtocol,
    'to chainAssetProtocol': event.toProtocol,
    'from blockchain': event.fromBlockchain,
    'to blockchain': event.toBlockchain,
    'value crypto': event.valueCrypto.toString(),
    'value usd': event.valueUsd.toString(),
    provider: event.provider,
    platform: 'DEX',
  } as OrderStartedProperties);
};

export const logOrderCompletedEvent = (event: ISwapEvent): void => {
  ampli.orderCompleted({
    from: event.from,
    to: event.to,
    'from chainAssetProtocol': event.fromProtocol,
    'to chainAssetProtocol': event.toProtocol,
    'from blockchain': event.fromBlockchain,
    'to blockchain': event.toBlockchain,
    'value crypto': event.valueCrypto.toString(),
    'value usd': event.valueUsd.toString(),
    provider: event.provider,
    platform: 'DEX',
  } as OrderCompletedProperties);
};

export const logMultichainSwapStartedEvent = (
  event: MultichainSwapStartedProperties,
): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');
  ampli.multichainSwapStarted({
    ...event,
    'session id': sessionId || undefined,
  });
};

export const logMultichainSwapResultEvent = (
  event: MultichainSwapResultProperties,
): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');
  ampli.multichainSwapResult({
    ...event,
    'session id': sessionId || undefined,
  });
};

export const logPoolsVisitedEvent = (event: PoolsVisitedProperties): void => {
  ampli.poolsVisited(event);
};

export const logPoolVisitedEvent = (event: PoolVisitedProperties): void => {
  ampli.poolVisited(event);
};

export const logLpDepositSequenceStartedEvent = (
  event: LpDepositSequenceStartedProperties,
): void => {
  ampli.lpDepositSequenceStarted(event);
};

export const logLpDepositSequenceEndedEvent = (
  event: LpDepositSequenceEndedProperties,
): void => {
  ampli.lpDepositSequenceEnded(event);
};

export const logLpDepositSuccessCtaEvent = (
  event: LpDepositSuccessCtaProperties,
): void => {
  ampli.lpDepositSuccessCta(event);
};
