import binanceIcon from '../../assets/binance.png';
import ethereumIcon from '../../assets/eth.png';
import polygonIcon from '../../assets/polygon.png';
import smartBchIcon from '../../assets/smartbch.png';

import { tokensIn, tokensOut } from './cexTokens';
import {
  DEXTokens,
  DEXTokensBinance,
  DEXTokensHolesky,
  DEXTokensPolygon,
  DEXTokensSBCH,
  DEXTokensSepolia,
  DEXTokensSushi,
  DEXTokensVerse,
} from './dexTokens';

// chainIds (set by blockchain)
// 0:  Centralized
// 1:  Ethereum Mainnet
// 3:  Ethereum Ropsten
// 56: Binance Smart Chain
// 137: Polygon Chain
// 10000: SmarthBCH live (WETH: 0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04 (WBCH))
// 10001: SmarthBCH test (WETH: 0x17F4FCF5b6E0A95D4eE331c8529041896A073F9b (WBCH))

// https://app.mistswap.fi/swap

// MISTSWAP on smartBCH (mainnet):
// pairAddress: '0x6008247F53395E7be698249770aa1D2bfE265Ca0',
// factoryAddress: '0x6008247F53395E7be698249770aa1D2bfE265Ca0',
// routerAddress: '0x5d0bF8d8c8b054080E2131D8b260a5c6959411B8',

// MISTSWAP on smartBCH (testnet):
// pairAddress: '0xaa094cA3FBd19dCCcE91C79d1FffA28293B05f28',
// factoryAddress: '0xaa094cA3FBd19dCCcE91C79d1FffA28293B05f28',
// routerAddress: '0x72a1A3F85e775dbd82D737830190aCB338fd6D71',

export const providers = [
  {
    name: 'sideshift',
    label: 'Sideshift',
    chainParam: 'cex',
    longLabel: 'Centralized Exchange',
    description: 'Cross-chain swaps of popular coins',
    website: 'https://sideshift.ai',
    tradingFee: 1,
    isDeFi: false,
    supportedChains: [
      {
        chainId: 0,
        tokensIn,
        tokensOut,
      },
    ],
    hexColor: '#0AC18E',
    nekoKey: 'SIDESHIFT_CEX_PROVIDER',
    comingSoon: false,
    menuLabel: 'Bitcoin.com Swap',
  },
  {
    name: 'changenow',
    label: 'ChangeNOW',
    chainParam: 'cex',
    longLabel: 'Centralized Exchange',
    description: 'Cross chain swaps of popular coins with low fees.',
    website: 'https://changenow.io/',
    tradingFee: 0.04,
    isDeFi: false,
    supportedChains: [
      {
        chainId: 0,
        tokensIn,
        tokensOut,
      },
    ],
    defaultTokensIn: tokensIn,
    defaultTokensOut: tokensOut,
    testTokensIn: tokensIn,
    testTokensOut: tokensOut,
    hexColor: '#EAA523',
    nekoKey: 'CHANGE_NOW_CEX_PROVIDER',
    comingSoon: false,
    menuLabel: 'Bitcoin.com Swap',
  },
  {
    name: 'bitcoincom',
    label: 'SmartBCH',
    chainParam: 'sbch',
    longLabel: 'Verse Decentralized Exchange - SmartBCH',
    description: 'Swap and invest your SmartBCH assets',
    website: 'https://www.bitcoin.com',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        chainId: 10000,
        pairAddress: '0x16bc2B187D7C7255b647830C05a6283f2B9A3AF8',
        factoryAddress: '0x16bc2B187D7C7255b647830C05a6283f2B9A3AF8',
        routerAddress: '0xF13541FaD443a4Bf4160B5c0F46aC5c735a908d3',
        wethAddress: '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04_ETH',
        wethSymbol: 'WBCH',
        tokensIn: DEXTokensSBCH,
        tokensOut: DEXTokensSBCH,
        atomicUnit: 'SATS',
      },
    ],
    /* supportedChains: [
            {
                chainId: 10001,
                pairAddress: '0x850D6e81395feD81737DA27509e96ED8c8BD03a9',
                factoryAddress: '0x850D6e81395feD81737DA27509e96ED8c8BD03a9',
                routerAddress: '0x4fcCe036Cf7142B9AE948e33636bF5dD31255867',
                tokensIn: DEXTokensSBCH,
                tokensOut: DEXTokensSBCH
            },
        ], */
    hexColor: '#00ca87',
    comingSoon: false,
    menuLabel: 'Verse DEX',
    icon: smartBchIcon,
    subgraphUrl:
      'https://sbch.subgraph.api.bitcoin.com/subgraphs/name/verse/exchange',
    feeToAddress: '0x138a09B1822450a2d6cbbf59C72aecbb475b0f5f',
  },
  {
    name: 'bitcoincom_ply',
    label: 'Polygon',
    chainParam: 'matic',
    longLabel: 'Verse Decentralized Exchange - Polygon',
    description: 'Swap and invest your Polygon assets',
    website: 'https://www.bitcoin.com',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        // dummy data cuz unnecessary
        chainId: 137,
        pairAddress: '0x',
        factoryAddress: '0x',
        routerAddress: '0x',
        liquidityMakerAddress: '0x',
        wethAddress: '0x',
        wethSymbol: 'WETH',
        tokensIn: DEXTokensVerse,
        tokensOut: DEXTokensVerse,
        atomicUnit: 'GWEI',
      },
    ],
    hexColor: '#00ca87',
    comingSoon: false,
    menuLabel: 'Verse DEX',
    icon: polygonIcon,
    subgraphUrl:
      'https://subgraph.api.bitcoin.com/subgraphs/name/verse/exchange',
    feeToAddress: '0x21aE363B6C9b16158a8e7e3b14CED19cE6F5B492',
  },
  {
    name: 'bitcoincom_eth',
    label: 'Ethereum',
    chainParam: 'eth',
    longLabel: 'Verse Decentralized Exchange - Ethereum',
    description: 'Swap and invest your Ethereum assets',
    website: 'https://www.bitcoin.com',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        chainId: 1,
        pairAddress: '0xee3E9E46E34a27dC755a63e2849C9913Ee1A06E2',
        factoryAddress: '0xee3E9E46E34a27dC755a63e2849C9913Ee1A06E2',
        routerAddress: '0xB4B0ea46Fe0E9e8EAB4aFb765b527739F2718671',
        liquidityMakerAddress: '0x7D3caD2EF18689625231Fc793Ac11c1676FdeDCC',
        wethAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        wethSymbol: 'WETH',
        tokensIn: DEXTokensVerse,
        tokensOut: DEXTokensVerse,
        atomicUnit: 'GWEI',
      },
    ],
    hexColor: '#00ca87',
    comingSoon: false,
    menuLabel: 'Verse DEX',
    icon: ethereumIcon,
    subgraphUrl:
      'https://subgraph.api.bitcoin.com/subgraphs/name/verse/exchange',
    feeToAddress: '0x21aE363B6C9b16158a8e7e3b14CED19cE6F5B492',
  },
  {
    name: 'bitcoincom_seth',
    label: 'Sepolia Testnet',
    chainParam: 'seth',
    longLabel: 'Verse Decentralized Exchange - Ethereum Sepolia',
    description: 'Swap and invest your Ethereum assets',
    website: 'https://www.bitcoin.com',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        chainId: 11155111,
        pairAddress: '0x3a44d45f67BfBAdF8d14B64DeAeBe14C9d3920C3',
        factoryAddress: '0x56c5E2F8e9B344082Ab5368f82971c816f2E7605',
        routerAddress: '0x44CD20CeCd1e8466477f2f11aA207f2623EbfF17',
        liquidityMakerAddress: '0x997572ae91Dd575C4A7fa261C85EB04c3F16092D',
        daiAddress: '0x3e622317f8C93f7328350cF0B56d9eD4C620C5d6',
        wethAddress: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14_ETH',
        wethSymbol: 'WETH',
        tokensIn: DEXTokensSepolia,
        tokensOut: DEXTokensSepolia,
        atomicUnit: 'GWEI',
      },
    ],
    hexColor: '#0000FF',
    comingSoon: false,
    menuLabel: 'Verse DEX',
    icon: ethereumIcon,
    subgraphUrl:
      'https://subgraph.api.bitcoin.com/subgraphs/name/verse/exchange',
    feeToAddress: '0x641AD78BAca220C5BD28b51Ce8e0F495e85Fe689',
  },
  {
    name: 'bitcoincom_heth',
    label: 'Holesky Testnet',
    chainParam: 'heth',
    longLabel: 'Verse Decentralized Exchange - Ethereum Holesky',
    description: 'Swap and invest your Ethereum assets',
    website: 'https://www.bitcoin.com',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        chainId: 17000,
        // TODO: update contract addresses when dex contracts are deployed on holesky
        pairAddress: '0x3a44d45f67BfBAdF8d14B64DeAeBe14C9d3920C3',
        factoryAddress: '0x56c5E2F8e9B344082Ab5368f82971c816f2E7605',
        routerAddress: '0x44CD20CeCd1e8466477f2f11aA207f2623EbfF17',
        liquidityMakerAddress: '0x997572ae91Dd575C4A7fa261C85EB04c3F16092D',
        // --
        daiAddress: '0x86Db6AD79793434627293c0B7801be04Aa783030',
        wethAddress: '0x7BF1A93194376c10b2C8d434D87CCCF2c5d2ED01',
        wethSymbol: 'WETH',
        tokensIn: DEXTokensHolesky,
        tokensOut: DEXTokensHolesky,
        atomicUnit: 'GWEI',
      },
    ],
    hexColor: '#0000FF',
    comingSoon: false,
    menuLabel: 'Verse DEX',
    icon: ethereumIcon,
    subgraphUrl:
      'https://subgraph.api.bitcoin.com/subgraphs/name/verse/exchange',
    feeToAddress: '0x641AD78BAca220C5BD28b51Ce8e0F495e85Fe689',
  },
  {
    name: 'uniswap',
    label: 'Uniswap',
    chainParam: 'eth',
    longLabel: 'Uniswap - Ethereum',
    description:
      'Decentralized exchanges on Ethereum network with liquidity pools',
    website: 'https://app.uniswap.org',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        chainId: 1,
        pairAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
        factoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
        routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        daiAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        wethAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2_ETH',
        tokensIn: DEXTokens,
        tokensOut: DEXTokens,
      },
      /* {
                chainId: 3,
                pairAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
                factoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
                routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
                daiAddress: '0xaD6D458402F60fD3Bd25163575031ACDce07538D',
                wethAddress: '0xc778417e063141139fce010982780140aa0cd5ab_ETH',
                tokensIn: DEXTokensRopsten,
                tokensOut: DEXTokensRopsten,
            } */
    ],
    hexColor: '#FF21B0',
    comingSoon: true,
    menuLabel: 'Uniswap DEX',
    icon: ethereumIcon,
    hidden: true,
  },
  {
    name: 'sushiswap',
    label: 'Sushiswap',
    chainParam: 'eth',
    website: 'https://app.sushi.com',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        chainId: 1,
        pairAddress: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
        factoryAddress: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
        routerAddress: '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F',
        daiAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        wethAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2_ETH',
        tokensIn: DEXTokensSushi,
        tokensOut: DEXTokensSushi,
      },
      /* {
                chainId: 3,
                pairAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
                factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
                routerAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
                daiAddress: '0xaD6D458402F60fD3Bd25163575031ACDce07538D',
                wethAddress: '0xc778417e063141139fce010982780140aa0cd5ab_ETH',
                tokensIn: DEXTokensRopsten,
                tokensOut: DEXTokensRopsten,
            }, */
      /* {
                chainId: 56,
                pairAddress: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
                factoryAddress: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
                routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
                daiAddress: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
                wethAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                tokensIn: DEXTokensBinance,
                tokensOut: DEXTokensBinance,
            } */
    ],
    hexColor: '#7F00FF',
    comingSoon: true,
    menuLabel: 'Sushiswap DEX',
    icon: ethereumIcon,
    hidden: true,
  },
  {
    name: 'pancakeswap',
    label: 'Pancakeswap',
    website: 'https://pancakeswap.finance/swap',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        chainId: 56,
        pairAddress: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
        factoryAddress: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
        routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        daiAddress: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        wethAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        tokensIn: DEXTokensBinance,
        tokensOut: DEXTokensBinance,
      },
    ],
    hexColor: '#f0b90a',
    comingSoon: true,
    menuLabel: 'Pancakeswap DEX',
    icon: binanceIcon,
    hidden: true,
  },
  {
    name: 'quickswap',
    label: 'Quickswap',
    website: 'https://quickswap.exchange',
    tradingFee: 0.3,
    isDeFi: true,
    supportedChains: [
      {
        chainId: 137,
        pairAddress: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32',
        factoryAddress: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        daiAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        wethAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        tokensIn: DEXTokensPolygon,
        tokensOut: DEXTokensPolygon,
      },
    ],
    hexColor: '#2891f9',
    comingSoon: true,
    menuLabel: 'Quickswap DEX',
    icon: polygonIcon,
    hidden: true,
  },
];
