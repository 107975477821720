import { VERSE_TOKEN } from '@context/constants';

export const tokensIn = [
  {
    abbr: 'BCH',
    value: 'BCH',
    blockchain: 'BCH_BLOCKCHAIN',
    protocol: 'BCH_PROTOCOL',
    label: 'Bitcoin Cash',
    ticker: 'bch',
    explorer: 'https://explorer.bitcoin.com/bch/tx/',
  },
  {
    abbr: 'BTC',
    value: 'BTC',
    blockchain: 'BTC_BLOCKCHAIN',
    protocol: 'BTC_PROTOCOL',
    label: 'Bitcoin',
    ticker: 'btc',
    explorer: 'https://explorer.bitcoin.com/btc/tx/',
  },
  {
    abbr: 'ETH',
    value: 'ETH',
    blockchain: 'ETH_BLOCKCHAIN',
    protocol: 'ETH_PROTOCOL',
    label: 'Ethereum',
    ticker: 'eth',
    explorer: 'https://etherscan.io/tx/',
  },
  {
    abbr: 'LTC',
    value: 'LTC',
    blockchain: 'LTC_BLOCKCHAIN',
    protocol: 'LTC_PROTOCOL',
    label: 'Litecoin',
    ticker: 'ltc',
    explorer: 'https://ltc.bitaps.com/',
  },
  {
    abbr: 'XMR',
    value: 'XMR',
    blockchain: 'XMR_BLOCKCHAIN',
    protocol: 'XMR_PROTOCOL',
    label: 'Monero',
    ticker: 'xmr',
    explorer: 'https://localmonero.co/blocks/tx/',
  },
  {
    abbr: 'XLM',
    value: 'XLM',
    blockchain: 'XLM_BLOCKCHAIN',
    protocol: 'XLM_PROTOCOL',
    label: 'Stellar Lumens',
    ticker: 'xlm',
    explorer: 'https://stellarchain.io/tx/',
  },
  {
    abbr: 'DASH',
    value: 'DASH',
    blockchain: 'DASH_BLOCKCHAIN',
    protocol: 'DASH_PROTOCOL',
    label: 'DASH',
    ticker: 'dash',
    explorer: 'https://explorer.dash.org/insight/tx/',
  },
  {
    abbr: 'DOGE',
    value: 'DOGE',
    blockchain: 'DOGE_BLOCKCHAIN',
    protocol: 'DOGE_PROTOCOL',
    label: 'DOGE Coin',
    ticker: 'doge',
    explorer: 'https://dogechain.info/tx/',
  },
  {
    abbr: 'USDT',
    value: 'USDT',
    blockchain: 'ETH_BLOCKCHAIN',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Tether (ERC20)',
    ticker: 'usdt',
    explorer: 'https://etherscan.io/tx/',
    token: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
  },
  {
    abbr: 'USDT',
    value: 'USDT',
    blockchain: 'BCH_BLOCKCHAIN',
    protocol: 'SLP_PROTOCOL',
    label: 'Tether (SLP)',
    ticker: 'usdt',
    explorer: 'https://explorer.bitcoin.com/bch/tx/',
    token: '9fc89d6b7d5be2eac0b3787c5b8236bca5de641b5bafafc8f450727b63615c11',
    decimals: 6,
  },
  {
    abbr: 'USDC',
    value: 'USDC',
    blockchain: 'ETH_BLOCKCHAIN',
    protocol: 'ERC_20_PROTOCOL',
    label: 'USDC Token',
    ticker: 'usdc',
    explorer: 'https://etherscan.io/tx/',
    token: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
  },
  VERSE_TOKEN,
];

export const tokensOut = [
  {
    abbr: 'BCH',
    value: 'BCH',
    blockchain: 'BCH_BLOCKCHAIN',
    protocol: 'BCH_PROTOCOL',
    label: 'Bitcoin Cash',
    ticker: 'bch',
    explorer: 'https://explorer.bitcoin.com/bch/tx/',
  },
  {
    abbr: 'BTC',
    value: 'BTC',
    blockchain: 'BTC_BLOCKCHAIN',
    protocol: 'BTC_PROTOCOL',
    label: 'Bitcoin',
    ticker: 'btc',
    explorer: 'https://explorer.bitcoin.com/btc/tx/',
  },
  {
    abbr: 'ETH',
    value: 'ETH',
    blockchain: 'ETH_BLOCKCHAIN',
    protocol: 'ETH_PROTOCOL',
    label: 'Ethereum',
    ticker: 'eth',
    explorer: 'https://etherscan.io/tx/',
  },
  {
    abbr: 'LTC',
    value: 'LTC',
    blockchain: 'LTC_BLOCKCHAIN',
    protocol: 'LTC_PROTOCOL',
    label: 'Litecoin',
    ticker: 'ltc',
    explorer: 'https://ltc.bitaps.com/',
  },
  {
    abbr: 'XMR',
    value: 'XMR',
    blockchain: 'XMR_BLOCKCHAIN',
    protocol: 'XMR_PROTOCOL',
    label: 'Monero',
    ticker: 'xmr',
    explorer: 'https://localmonero.co/blocks/tx/',
  },
  {
    abbr: 'XLM',
    value: 'XLM',
    blockchain: 'XLM_BLOCKCHAIN',
    protocol: 'XLM_PROTOCOL',
    label: 'Stellar Lumens',
    ticker: 'xlm',
    explorer: 'https://stellarchain.io/tx/',
  },
  {
    abbr: 'DASH',
    value: 'DASH',
    blockchain: 'DASH_BLOCKCHAIN',
    protocol: 'DASH_PROTOCOL',
    label: 'DASH',
    ticker: 'dash',
    explorer: 'https://explorer.dash.org/insight/tx/',
  },
  {
    abbr: 'DOGE',
    value: 'DOGE',
    blockchain: 'DOGE_BLOCKCHAIN',
    protocol: 'DOGE_PROTOCOL',
    label: 'DOGE Coin',
    ticker: 'doge',
    explorer: 'https://dogechain.info/tx/',
  },
  {
    abbr: 'USDT',
    value: 'USDT',
    blockchain: 'ETH_BLOCKCHAIN',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Tether (ERC20)',
    ticker: 'usdt',
    explorer: 'https://etherscan.io/tx/',
    token: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
  },
  {
    abbr: 'USDT',
    value: 'USDT',
    blockchain: 'BCH_BLOCKCHAIN',
    protocol: 'SLP_PROTOCOL',
    label: 'Tether (SLP)',
    ticker: 'usdt',
    explorer: 'https://explorer.bitcoin.com/bch/tx/',
    token: '9fc89d6b7d5be2eac0b3787c5b8236bca5de641b5bafafc8f450727b63615c11',
    decimals: 6,
  },
  {
    abbr: 'USDC',
    value: 'USDC',
    blockchain: 'ETH_BLOCKCHAIN',
    protocol: 'ERC_20_PROTOCOL',
    label: 'USDC Token',
    ticker: 'usdc',
    explorer: 'https://etherscan.io/tx/',
    token: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
  },
];
