import { SECONDS_IN_YEAR } from '@context/constants';

export const getStakingAPY = (
  farm: IFarmPair,
  rewardRate: number,
  rewardDuration: number,
  totalSupply: number,
  dexRates: DexRatesType,
  exchangeProvider: string,
): string => {
  const { rewardToken, stakingToken } = farm;
  const totalYearlyRewards = rewardRate * SECONDS_IN_YEAR;
  const rewardTokenFiatRate =
    dexRates?.[exchangeProvider]?.[rewardToken.abbr?.toUpperCase()] || 0;
  const stakingTokenFiatRate =
    dexRates?.[exchangeProvider]?.[stakingToken.abbr?.toUpperCase()] || 0;

  const yearlyRewardsFiat = totalYearlyRewards * rewardTokenFiatRate;
  const totalSupplyFiat = totalSupply * stakingTokenFiatRate;

  const estimatedAPY = (yearlyRewardsFiat / totalSupplyFiat) * 100;
  if (!totalSupplyFiat) return '...';
  if (estimatedAPY > 500) return '> 500';
  return estimatedAPY.toFixed(2);
};
