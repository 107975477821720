import styled from 'styled-components';

import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const Confirmation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: ${colors.shade80};
  padding-top: ${({ theme }) => theme.spacing.unit * 0.5}px;
  padding-bottom: ${({ theme }) => theme.spacing.unit * 2}px;

  & a {
    color: ${colors.verseBlue};
  }

  & > * + * {
    padding-top: ${({ theme }) => theme.spacing.unit * 3}px;
  }
`;
