export const lpTokenValue = (
  lpTokenBalance: number,
  pair: ILiquidityPair,
  rate?: number,
): number => {
  const singleToken = !pair.token1;
  const tokenPrice = singleToken
    ? rate || 0
    : parseFloat(pair.reserveUSD) / parseFloat(pair.totalSupply);

  return lpTokenBalance * tokenPrice;
};
