import { Dispatch, useCallback, useEffect, useReducer } from 'react';
import { createContainer } from 'react-tracked';

import farmsEthList from '../data/farmsEthList';

import { NULL_PAIR } from './constants';
import { Action, reducer } from './reducer';
import {
  ExecutingRoutesType,
  TExchangeProvider,
  UserDetailsType,
  UserStytchDetailsType,
} from './types';

export type State = {
  connectModalOpen: boolean;
  fallbackEstimates: FallbackEstimatesType;
  fallbackRates: DexRatesType;
  farmDeposits: FarmDepositType;
  executingRoutes: ExecutingRoutesType;
  locale: 'en';
  loginFrom?: string;
  provider: TExchangeProvider;
  rpcModalHide: boolean;
  rpcModalOpen: boolean;
  selectedFarmAddress: string;
  selectedLiquidityPair: ILiquidityPair;
  userDetails?: UserDetailsType | UserStytchDetailsType;
  isWallet?: boolean;
};

export const STORAGE_KEY = 'bitcoincom-dex';

const INITIAL_STATE: State = {
  connectModalOpen: false,
  executingRoutes: {},
  fallbackEstimates: {},
  fallbackRates: {},
  farmDeposits: {},
  locale: 'en',
  provider: 'bitcoincom_eth',
  selectedFarmAddress: farmsEthList[0].contractAddress, /// whyyyyyyyyy
  selectedLiquidityPair: NULL_PAIR,
  rpcModalHide: false,
  rpcModalOpen: false,
  isWallet: undefined,
};

const init = () => {
  try {
    if (typeof window === 'undefined') return INITIAL_STATE;

    const item = window.localStorage.getItem(STORAGE_KEY);
    if (item == null) return INITIAL_STATE;

    const preloadedState = {
      ...INITIAL_STATE,
      ...JSON.parse(item),
    };
    return preloadedState || INITIAL_STATE;
  } catch (e) {
    return INITIAL_STATE;
  }
};

const useValue = (): [State, Dispatch<Action>] => {
  const [state, dispatch] = useReducer(reducer, {}, init);

  /* useEffect(() => {
    console.log('state', state);
  }, [state]); */

  const dispatchWithLogging = useCallback((action: Action) => {
    // console.log('action', action);
    dispatch(action);
  }, []);

  useEffect(() => {
    const { isWallet, ...rest } = state;

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(rest));

    window.sessionStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        isWallet,
      }),
    );
  }, [state]);

  return [state, dispatchWithLogging];
};

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
