export const getReserves = async (
  subgraphClient: ISubgraphAPIClient,
  pair: string,
  snapshot: ILiquidityPositionSnapshot,
  feeToAddress: string,
): Promise<number> => {
  const response = await subgraphClient.getMintTransaction(
    pair.toLowerCase(),
    feeToAddress.toLowerCase(),
    snapshot?.timestamp,
  );

  const mintData = response?.pair?.liquidityPositionSnapshots || [];

  let reserveUSDAtFunding = 0;
  if (mintData.length) {
    reserveUSDAtFunding = parseFloat(mintData[0]?.reserveUSD);
  }

  return reserveUSDAtFunding;
};
