const verseStakingEthData = {
  contractAddress: '0xd920556b0f3522bB1257923292A256F1e3023e07',
  stakingToken: {
    // ITickerObject
    token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    decimals: 18,
    abbr: 'VERSE',
    value: 'VERSE',
    blockchain: 'ETH',
    label: 'Verse',
    ticker: 'verse',
    explorer: '',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/verse.png',
  },
  rewardToken: {
    // ITickerObject
    token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    decimals: 18,
    abbr: 'VERSE',
    value: 'VERSE',
    blockchain: 'ETH',
    label: 'Verse',
    ticker: 'verse',
    explorer: 'https://etherscan.io/tx/',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/verse.png',
  },
  pair: {
    id: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    name: 'VERSE',
    token0: {
      name: 'Verse',
      symbol: 'VERSE',
      id: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
    },
  },
  singleSided: true,
};

export default verseStakingEthData;
