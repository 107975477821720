import { Route as LiFiRoute } from '@lifi/sdk';

import { State } from '@context/store';

export type ExecutingRoutesPayload = LiFiRoute;

export type ExecutingRoutesAction = {
  type: 'SET_EXECUTING_ROUTES';
  payload: ExecutingRoutesPayload;
};

export const executingRoutesReducer = (
  state: State,
  payload: ExecutingRoutesPayload,
): State => {
  const routes = state.executingRoutes;
  routes[payload.id] = payload;

  return {
    ...state,
    executingRoutes: routes,
  };
};
