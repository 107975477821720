import { Contract } from '@ethersproject/contracts';
import { Call, Falsy, useCall } from '@usedapp/core';
import { TypedContract } from '@usedapp/core/dist/esm/src/model';
import { BigNumber, ethers } from 'ethers';

import {
  DEFAULT_BIGNUM,
  DEFAULT_CHAIN,
  DEFAULT_DECIMALS,
} from '@context/constants';

import { formatValue } from '@helpers/formatValue';
import { ERC20Interface } from '@helpers/index';

import { useCustomBalance } from '@hooks/core';

export const useCustomTotalSupply = (
  tokenAddress: string | undefined,
  chainId: number,
): BigNumber => {
  const { value, error } =
    useCall(
      tokenAddress &&
        ({
          contract: new Contract(tokenAddress, ERC20Interface),
          method: 'totalSupply',
          args: [],
        } as unknown as Falsy | Call<TypedContract, string>),
      {
        chainId: chainId || DEFAULT_CHAIN,
      },
    ) ?? {};

  if (error) {
    console.error(error.message);
    return DEFAULT_BIGNUM;
  }

  return value?.[0];
};
export const useGetLiquidity = (
  pair: ILiquidityPair,
  holderAddress: string,
  chainId: number,
): {
  readableBalance0: number;
  readableBalance1: number;
  usersLiquidity: BigNumber;
} => {
  const usersLiquidity = useCustomBalance(pair?.id, holderAddress, chainId);

  const token0Total = useCustomBalance(pair?.token0?.id, pair?.id, chainId);

  const token1Total = useCustomBalance(pair?.token1?.id, pair?.id, chainId);

  const lpTokensTotal = useCustomTotalSupply(pair?.id, chainId);

  if (!lpTokensTotal) {
    return {
      readableBalance0: 0,
      readableBalance1: 0,
      usersLiquidity: DEFAULT_BIGNUM,
    };
  }

  const usersLiquidityFloat = usersLiquidity
    ? parseFloat(formatValue(usersLiquidity, DEFAULT_DECIMALS))
    : 0;

  const lpTokensTotalFloat = parseFloat(
    ethers.utils.formatUnits(lpTokensTotal, DEFAULT_DECIMALS),
  );

  const token0TotalFloat = token0Total
    ? parseFloat(formatValue(token0Total, parseInt(pair.token0?.decimals, 10)))
    : 0;

  const token1TotalFloat = token1Total
    ? parseFloat(formatValue(token1Total, parseInt(pair.token1?.decimals, 10)))
    : 0;

  const token0Balance =
    (usersLiquidityFloat / lpTokensTotalFloat) * token0TotalFloat;

  const token1Balance =
    (usersLiquidityFloat / lpTokensTotalFloat) * token1TotalFloat;

  return {
    readableBalance0: token0Balance,
    readableBalance1: token1Balance,
    usersLiquidity,
  };
};
