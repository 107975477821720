import React, { FunctionComponent, RefObject, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useConnector, useEthers } from '@usedapp/core';

import Modal from '@bitcoin-portal/verse-web-components/dist/Modal';

import { WalletConnectConnector } from '../../../context/connectors/WalletConnectConnector';
import { useTrackedState } from '../../../context/store';

import { Heading, Purpose } from '../styled';

import BcomConnectButton from './ConnectButtons/BcomConnectButton';
import DisconnectButton from './ConnectButtons/DisconnectButton';
import MetaMaskConnectButton from './ConnectButtons/MetaMaskConnectButton';
import WalletConnectUniversalButton from './ConnectButtons/WalletConnectUniversalButton';

interface Props {
  loginModalRef?: RefObject<Modal>;
  trackEvent?: (connection: string) => void;
}

const LoginModal: FunctionComponent<Props> = ({
  loginModalRef,
  trackEvent,
}) => {
  const { provider, isWallet } = useTrackedState();
  const { account, isLoading } = useEthers();
  const { connector } = useConnector();

  useEffect(() => {
    // connection event

    if (isLoading || !account || !connector) return;

    loginModalRef?.current?.close();

    const walletConnectSession =
      connector?.connector instanceof WalletConnectConnector
        ? connector.connector.session
        : undefined;

    const name = walletConnectSession
      ? walletConnectSession.peer.metadata.name
      : connector?.connector.name.toLowerCase();
    trackEvent?.(name);
  }, [isLoading, account, connector]);

  return (
    <div style={{ padding: '24px 32px 24px' }}>
      <Heading>
        <FormattedMessage id="global.modals.login.defi.title" />
      </Heading>
      {account ? (
        <Purpose secondary>
          <FormattedMessage id="global.modals.login.defi.description" />
        </Purpose>
      ) : (
        <Purpose secondary>
          <FormattedMessage id="global.modals.login.defi.services" />
        </Purpose>
      )}

      {account && <DisconnectButton loginModalRef={loginModalRef} />}

      {!account && (
        <>
          {isWallet && (
            <BcomConnectButton
              loginModalRef={loginModalRef}
              trackEvent={trackEvent}
            />
          )}

          {!isWallet && (
            <>
              <MetaMaskConnectButton />
              {provider !== 'bitcoincom' && <WalletConnectUniversalButton />}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LoginModal;
