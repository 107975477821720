import React, { FC } from 'react';

interface Props {
  src: string;
  alt: string;
  height?: number | string;
  width?: number | string;
}

const Image: FC<Props> = ({ src, alt, height, width, ...rest }) => {
  return <img src={src} alt={alt} height={height} width={width} {...rest} />;
};

export default Image;
