import { flattenMessages } from '../../helpers';

import enGlobal from '../global/en.json';
import esGlobal from '../global/es.json';
import ruGlobal from '../global/ru.json';
import zhGlobal from '../global/zh.json';
import enHome from '../home/en.json';
import esHome from '../home/es.json';
import ruHome from '../home/ru.json';
import zhHome from '../home/zh.json';

import en from './en.json';
import es from './es.json';
import ru from './ru.json';
import zh from './zh.json';

export default {
  en: {
    ...flattenMessages(en, '404'),
    ...flattenMessages(enGlobal, 'global'),
    ...flattenMessages(enHome, 'home'),
  },
  es: {
    ...flattenMessages(es, '404'),
    ...flattenMessages(esGlobal, 'global'),
    ...flattenMessages(esHome, 'home'),
  },
  ru: {
    ...flattenMessages(ru, '404'),
    ...flattenMessages(ruGlobal, 'global'),
    ...flattenMessages(ruHome, 'home'),
  },
  zh: {
    ...flattenMessages(zh, '404'),
    ...flattenMessages(zhGlobal, 'global'),
    ...flattenMessages(zhHome, 'home'),
  },
};
