import { flattenMessages } from '../../helpers';

import enGlobal from '../global/en.json';

import en from './en.json';

export default {
  en: {
    ...flattenMessages(en, 'card'),
    ...flattenMessages(enGlobal, 'global'),
  },
};
