import {
  ConnectModalOpenAction,
  connectModalOpenReducer,
} from './reducers/connectModalOpen';
import {
  DeleteExecutingRouteAction,
  deleteExecutingRouteReducer,
} from './reducers/deleteExecutingRoute';
import {
  ExecutingRoutesAction,
  executingRoutesReducer,
} from './reducers/executingRoutes';
import {
  FallbackEstimatesAction,
  fallbackEstimatesReducer,
} from './reducers/fallbackEstimates';
import {
  FallbackRatesAction,
  fallbackRatesReducer,
} from './reducers/fallbackRates';
import {
  FarmDepositsAction,
  farmDepositsReducer,
} from './reducers/farmDeposits';
import { IsWalletAction, isWalletReducer } from './reducers/isWallet';
import { LocaleAction, localeReducer } from './reducers/locale';
import { LoginFromAction, loginFromReducer } from './reducers/loginFrom';
import { ProviderAction, providerReducer } from './reducers/provider';
import {
  RpcModalHideAction,
  rpcModalHideReducer,
} from './reducers/rpcModalHide';
import {
  RpcModalOpenAction,
  rpcModalOpenReducer,
} from './reducers/rpcModalOpen';
import {
  SelectedFarmAction,
  selectedFarmReducer,
} from './reducers/selectedFarm';
import {
  SelectedLiquidityPairAction,
  selectedLiquidityPairReducer,
} from './reducers/selectedLiquidityPair';
import { UserDetailsAction, userDetailsReducer } from './reducers/userDetails';
import { State } from './store';

export type Action =
  | ConnectModalOpenAction
  | DeleteExecutingRouteAction
  | ExecutingRoutesAction
  | FallbackEstimatesAction
  | FallbackRatesAction
  | FarmDepositsAction
  | IsWalletAction
  | LocaleAction
  | LoginFromAction
  | ProviderAction
  | RpcModalHideAction
  | RpcModalOpenAction
  | SelectedFarmAction
  | SelectedLiquidityPairAction
  | UserDetailsAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CONNECT_MODAL_OPEN':
      return connectModalOpenReducer(state, action.payload);
    case 'SET_EXECUTING_ROUTES':
      return executingRoutesReducer(state, action.payload);
    case 'SET_FALLBACK_ESTIMATES':
      return fallbackEstimatesReducer(state, action.payload);
    case 'SET_FALLBACK_RATES':
      return fallbackRatesReducer(state, action.payload);
    case 'SET_FARM_DEPOSITS':
      return farmDepositsReducer(state, action.payload);
    case 'SET_LOCALE':
      return localeReducer(state, action.payload);
    case 'SET_LOGIN_FROM':
      return loginFromReducer(state, action.payload);
    case 'SET_PROVIDER':
      return providerReducer(state, action.payload);
    case 'SET_RPC_MODAL_HIDE':
      return rpcModalHideReducer(state, action.payload);
    case 'SET_RPC_MODAL_OPEN':
      return rpcModalOpenReducer(state, action.payload);
    case 'SET_SELECTED_FARM':
      return selectedFarmReducer(state, action.payload);
    case 'SET_SELECTED_LIQUIDITY_PAIR':
      return selectedLiquidityPairReducer(state, action.payload);
    case 'SET_USER_DETAILS':
      return userDetailsReducer(state, action.payload);
    case 'SET_IS_WALLET':
      return isWalletReducer(state, action.payload);
    case 'DELETE_EXECUTING_ROUTE':
      return deleteExecutingRouteReducer(state, action.payload);
    default:
      return state;
  }
};
