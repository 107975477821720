const stakingSepoliaData = {
  contractAddress: '0x2a70B50b26eA675f11c2812496dCE4538ab8f604',
  stakingToken: {
    // ITickerObject
    token: '0xa46c5C2aaCA6eCcd3213825272Bf3ce5f15e1BF5',
    decimals: 18,
    abbr: 'VERSE',
    value: 'VERSE',
    blockchain: 'ETH',
    label: 'Verse',
    ticker: 'verse',
    explorer: '',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/verse.png',
  },
  rewardToken: {
    // ITickerObject
    token: '0xa46c5C2aaCA6eCcd3213825272Bf3ce5f15e1BF5',
    decimals: 18,
    abbr: 'VERSE',
    value: 'VERSE',
    blockchain: 'ETH',
    label: 'Verse',
    ticker: 'verse',
    explorer: 'https://etherscan.io/tx/',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/verse.png',
  },
  pair: {
    id: '0xa46c5C2aaCA6eCcd3213825272Bf3ce5f15e1BF5',
    name: 'VERSE',
    token0: {
      name: 'Verse',
      symbol: 'VERSE',
      id: '0xa46c5C2aaCA6eCcd3213825272Bf3ce5f15e1BF5',
      decimals: 18,
    },
  },
  singleSided: true,
};

export default stakingSepoliaData;
