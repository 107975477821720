import {
  ConnectWalletClickedProperties,
  ConnectWalletOptionSelectedProperties,
  ConnectWalletResultProperties,
  DownloadProperties,
  NavigateProperties,
  NavigationClickedProperties,
  PromiseResult,
  Result,
  RpcAddedProperties,
  RpcCtaClickedProperties,
  TapProperties,
  VerseBurnEngineCtaTappedProperties,
  VerseBurnEngineResultProperties,
  VerseClickerCtaTappedProperties,
  VerseLoungeVisitedProperties,
  VerseLoungeVoteClickedProperties,
  ampli,
} from '@bitcoin-portal/web-ampli';

import { breakpoints } from '@bitcoin-portal/verse-web-components/dist/themes/breakpoints';

import { AMPLITUDE_KEY, PROVIDER_CHAINS } from '../context/constants';

import { newrelicError } from './newrelic';

interface AmpConfig {
  sessionId?: number;
  deviceId?: string;
}

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';

export const initAmplitude = async () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('sessionId');
    const deviceId = urlParams.get('deviceId');

    const config: AmpConfig = {};

    if (sessionId) {
      config.sessionId = Number(sessionId);
    }
    if (deviceId) {
      config.deviceId = deviceId;
    }

    if (ampli.isLoaded) {
      // adding this to cleanup console where this gets loadedtwice
      return;
    }

    ampli.load({
      client: {
        apiKey: AMPLITUDE_KEY,
        configuration: {
          ...config,
        },
      },
    });
  } catch (e) {
    console.error('Error initialising Amplitude: ', e);
    newrelicError(e, { method: 'initAmplitude' });
  }
};

export const getBlockchain = (provider: string): string => {
  return PROVIDER_CHAINS[provider || 'bitcoincom_eth'];
};

export const getDeviceSize = (width: number): string => {
  let sz = 'xl';
  Object.keys(breakpoints)
    .reverse()
    .forEach(key => {
      if (width <= breakpoints[key as Breakpoint]) {
        sz = key;
      }
    });

  return sz;
};

export const getEntrypoint = (): string => {
  try {
    const topLevelPath = window.location.pathname.split('/')?.[1];
    return topLevelPath || 'top';
  } catch (e) {
    return '';
  }
};

export interface AmpliMessages {
  connectWalletClicked: ConnectWalletClickedProperties;
  connectWalletOptionSelected: ConnectWalletOptionSelectedProperties;
  connectWalletResult: ConnectWalletResultProperties;
  navigationClicked: NavigationClickedProperties;
  verseClickerCtaTapped: VerseClickerCtaTappedProperties;
  download: DownloadProperties;
  navigate: NavigateProperties;
  rpcAdded: RpcAddedProperties;
  rpcCtaClicked: RpcCtaClickedProperties;
  tap: TapProperties;
  verseBurnEngineResult: VerseBurnEngineResultProperties;
  verseBurnEngineCtaTapped: VerseBurnEngineCtaTappedProperties;
  verseLoungeVisited: VerseLoungeVisitedProperties;
  verseLoungeVoteClicked: VerseLoungeVoteClickedProperties;
}

export const logAmplitudeEvent = async <K extends keyof AmpliMessages>(
  name: K,
  eventData: Partial<AmpliMessages[K]>,
): Promise<void> => {
  try {
    if (!ampli[name]) {
      throw new Error("Ampli event doesn't exist");
    }

    (ampli[name] as (data: Partial<AmpliMessages[K]>) => PromiseResult<Result>)(
      eventData,
    );
  } catch (e) {
    console.error('Error sending amplitude event: ', e);
    newrelicError(e, {
      method: 'logAmplitudeEvent',
      name,
      eventData,
    });
  }
};
