/* eslint-disable no-nested-ternary */
import { StytchProvider } from '@stytch/react';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { StytchHeadlessClient } from '@stytch/vanilla-js/dist/index.headless';
import { useConnector, useEthers } from '@usedapp/core';

import Modal from '@bitcoin-portal/verse-web-components/dist/Modal';

import { WalletConnectConnector } from '@context/connectors/WalletConnectConnector';
import { STYTCH_KEY, TEST_ACCOUNT } from '@context/constants';
import { useEns } from '@context/providers/EnsProvider';
import { useDispatch, useTrackedState } from '@context/store';

import {
  getBlockchain,
  getDeviceSize,
  getEntrypoint,
  logAmplitudeEvent,
  shortAddress,
} from '@helpers/index';

import { useIsLoading } from '@hooks/useIsLoading';
import { useWindowSize } from '@hooks/useWindowSize';

import CheckEmailModal from '../CheckEmailModal';
import Image from '../Image';
import LoginModal from './LoginModal';
import { ModalWrapper, StyledButton } from './styled';

interface Props {
  isDashboard?: boolean;
}

const LoginButton: FunctionComponent<Props> = ({ isDashboard = true }) => {
  const loginModalRef = useRef<Modal>(null);
  const checkEmailModalRef = useRef<Modal>(null);
  const isPageLoading = useIsLoading();
  const username = useEns();
  const { provider, userDetails, connectModalOpen, isWallet } =
    useTrackedState();
  const dispatch = useDispatch();
  const { account: ethersAccount, activateBrowserWallet, active } = useEthers();

  const { connector } = useConnector();
  const account = TEST_ACCOUNT || ethersAccount;

  const { width } = useWindowSize();

  const [connected, setConnected] = useState(false);
  const [isMetaMask, setIsMetaMask] = useState(false);
  const [email, setEmail] = useState('');
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [stytch, setStytch] = useState<StytchHeadlessClient>();

  const trackLoginClick = useCallback(() => {
    if (!connected && typeof window !== 'undefined' && width) {
      logAmplitudeEvent('connectWalletClicked', {
        blockchain: getBlockchain(provider),
        'current page': window.location,
        entrypoint: getEntrypoint(),
        'screen size': getDeviceSize(width),
      });
    }
  }, [connected, provider, width]);

  const zIndex = 9999;

  useEffect(() => {
    setStytch(new StytchHeadlessClient(STYTCH_KEY));
  }, []);

  useEffect(() => {
    if (connectModalOpen && loginModalRef) {
      loginModalRef?.current?.open();
    }
    if (!connectModalOpen || !loginModalRef) {
      loginModalRef?.current?.close();
    }
  }, [connectModalOpen]);

  useEffect(() => {
    setConnected(!!(userDetails || account));
  }, [userDetails, account, active]);

  useEffect(() => {
    setIsMetaMask(connector?.connector.name.toLowerCase() === 'metamask');
  }, [connector]);

  const buttonDesign = connected ? 'secondary' : 'primary';
  const connectedAccount = userDetails
    ? userDetails?.email
    : shortAddress(account);

  const displayName = username || connectedAccount;

  const walletConnectSession =
    connector?.connector instanceof WalletConnectConnector
      ? connector.connector.session
      : undefined;

  return (
    <>
      <StyledButton
        small
        disabled={isPageLoading}
        onClick={(e: React.UIEvent<HTMLElement>) => {
          if (isWallet && !connected) {
            activateBrowserWallet({
              type: 'bcomwallet',
            });

            dispatch({ type: 'SET_LOGIN_FROM' });
            trackLoginClick();
            return;
          }

          e.stopPropagation();
          loginModalRef?.current?.open();
          dispatch({ type: 'SET_LOGIN_FROM' });
          trackLoginClick();
        }}
        design={buttonDesign}
        wider={!!username}
      >
        <span>{connected ? displayName : 'Connect'}</span>
        {connected && (
          <span>
            {!!userDetails && (
              <Image
                src="/images/uploads/bcomconnect.png"
                alt="bitcoin.com icon"
                width={24}
              />
            )}
            {!!account && (
              <Image
                src={
                  isMetaMask
                    ? '/images/uploads/metamask.png'
                    : `${
                        walletConnectSession?.peer.metadata.name.toLowerCase() ===
                        'bitcoin.com wallet'
                          ? '/images/uploads/bcomconnect.png'
                          : '/images/uploads/walletconnect.png'
                      }`
                }
                alt={isMetaMask ? 'MetaMask icon' : 'WalletConnect icon'}
                width={24}
              />
            )}
          </span>
        )}
      </StyledButton>

      {stytch && (
        <ModalWrapper $isDashboard={isDashboard}>
          <StytchProvider stytch={stytch}>
            <Modal
              ref={loginModalRef}
              title={<FormattedMessage id="global.labels.connectWallet" />}
              maxWidth="420px"
              callback={({ action }) => {
                if (action === 'close') {
                  setEmail('');
                  dispatch({
                    type: 'SET_CONNECT_MODAL_OPEN',
                    payload: false,
                  });
                } else {
                  dispatch({
                    type: 'SET_CONNECT_MODAL_OPEN',
                    payload: true,
                  });
                }
              }}
              zIndex={zIndex}
              noPadding
            >
              <LoginModal
                loginModalRef={loginModalRef}
                checkEmailModalRef={checkEmailModalRef}
                updateEmail={(input: string) => setEmail(input)}
                email={email}
                setSubmittedEmail={setSubmittedEmail}
              />
            </Modal>
            <Modal
              ref={checkEmailModalRef}
              title={<FormattedMessage id="global.labels.checkEmail" />}
              maxWidth="420px"
              zIndex={zIndex}
            >
              <CheckEmailModal email={submittedEmail} />
            </Modal>
          </StytchProvider>
        </ModalWrapper>
      )}
    </>
  );
};

export default LoginButton;
