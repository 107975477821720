import React, {
  Children,
  FC,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import { TMenuItem } from '../../../context/types';

import ModalMenuBtn from '../ModalMenuBtn';
import { Links } from './styled';

interface Props {
  links: TMenuItem[];
  standalone: boolean;
  trackNavClick: (label: string, isNew?: boolean) => void;
}

const IntersectionObserverWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  links,
  standalone,
  trackNavClick,
}) => {
  const navRef = useRef<HTMLDivElement>(null);

  const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>(
    links.reduce((p, c) => ({ ...p, [c.id]: true }), {}),
  );

  const handleIntersection: IntersectionObserverCallback = entries => {
    const updatedEntries: Record<string, boolean> = {};
    entries.forEach(entry => {
      if (!(entry.target instanceof HTMLElement)) return;
      const { targetid } = entry.target.dataset;

      if (!targetid) return;
      if (entry.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });

    setVisibilityMap(prev => ({
      ...prev,
      ...updatedEntries,
    }));
  };

  useEffect(() => {
    if (!navRef.current) return undefined;

    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 1,
    });

    Array.from(navRef.current.children).forEach(item => {
      if ((item as HTMLElement).dataset.targetid) {
        observer.observe(item);
      }
    });
    return () => {
      observer.disconnect();
    };
  }, [links]);

  return (
    <>
      <Links ref={navRef}>
        {Children.map(children, child => {
          if (!child) return null;

          const visible =
            visibilityMap?.[(child as ReactElement).props['data-targetid']];

          return cloneElement(child as ReactElement, {
            style: {
              opacity: visible ? 1 : 0,
              pointerEvents: visible ? 'all' : 'none',
            },
          });
        })}
      </Links>
      <ModalMenuBtn
        standalone={standalone}
        trackEvent={trackNavClick}
        list={Object.keys(visibilityMap).reduce((prev, curr) => {
          if (visibilityMap[curr]) return prev;
          const link = links.find(l => l.id === curr);
          if (!link) return prev;
          return [...prev, link];
        }, [] as TMenuItem[])}
      />
    </>
  );
};

export default IntersectionObserverWrapper;
