import { DEFAULT_STAKING_CONTRACT } from '@context/constants';

// sort farms: verse-eth on top, active farms by apy, inactive farms by apy
export const sortFarmsList = (array: IFarmPair[]): IFarmPair[] => {
  return array
    .sort((a, b) => {
      // sort by apy first

      if (a.apy === '...' || b.apy === '...') return 0;
      if (a.apy === '> 500' && b.apy === '> 500') return 0;
      if (a.apy === '> 500' && b.apy !== '> 500') return -1;
      if (a.apy !== '> 500' && b.apy === '> 500') return 1;

      const apy1 = parseFloat(a.apy || '0');
      const apy2 = parseFloat(b.apy || '0');

      if (apy1 < apy2) return 1;
      if (apy1 > apy2) return -1;

      return 0;
    })
    .sort((a, b) => {
      // inactive at bottom of list
      if (a.hidden && !b.hidden) return 1;
      if (!a.hidden && b.hidden) return -1;

      // verse-eth on top
      if (a.contractAddress === DEFAULT_STAKING_CONTRACT) return -1;
      if (b.contractAddress === DEFAULT_STAKING_CONTRACT) return 1;

      // keep apy order
      return 0;
    });
};
