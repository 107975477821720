import { State } from '../store';

export type RpcModalOpenPayload = boolean;

export type RpcModalOpenAction = {
  type: 'SET_RPC_MODAL_OPEN';
  payload: RpcModalOpenPayload;
};

export const rpcModalOpenReducer = (
  state: State,
  payload: RpcModalOpenPayload,
): State => ({
  ...state,
  rpcModalOpen: payload,
});
