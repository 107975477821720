// copy pasta from here with modifications
// https://github.com/TrueFiEng/useDApp/tree/master/packages/connectors/wallet-connect-v2
import { Connector, ConnectorEvent, ConnectorUpdateData } from '@usedapp/core';
import { WalletConnectModal } from '@walletconnect/modal';
import { SessionTypes } from '@walletconnect/types';
import UniversalProvider, {
  UniversalProviderOpts,
} from '@walletconnect/universal-provider';
import { providers } from 'ethers';

import { rpcMap } from '../providers/DAppProvider';

/* interface WalletConnectV2ConnectorOptions {
  projectId: string;
  chains: Chain[];
  rpcMap?: Record<string, string>;
  checkGnosisSafe?: boolean;
} */

export class WalletConnectUniversal implements Connector {
  public provider?: providers.Web3Provider;

  public readonly name = 'MulticoinWalletConnect';

  readonly update = new ConnectorEvent<ConnectorUpdateData>();

  public universalProvider: UniversalProvider | undefined;

  private readonly walletConnectModal: WalletConnectModal;

  private readonly opts: UniversalProviderOpts;

  constructor(options: UniversalProviderOpts) {
    this.opts = options;

    if (!options.projectId) {
      throw new Error('project id required');
    }
    this.walletConnectModal = new WalletConnectModal({
      ...options,
      projectId: options.projectId,
      themeVariables: {
        '--wcm-z-index': '99',
      },
      explorerExcludedWalletIds: 'ALL',
      explorerRecommendedWalletIds: [
        '107bb20463699c4e614d3a2fb7b961e66f48774cb8f6d6c1aee789853280972c',
      ],
    });
  }

  private async init() {
    if (!this.opts.projectId) {
      throw new Error('no project id');
    }
    this.universalProvider = await UniversalProvider.init({
      // logger: 'info',
      projectId: this.opts.projectId,
      metadata: this.opts.metadata,
    });

    this.universalProvider.on('accountsChanged', (ev: unknown) => {
      console.log('accountsChanged: ', ev, this.universalProvider);
    });

    this.universalProvider.on('chainChanged', async (ev: unknown) => {
      console.log('chainChanged: ', ev, this.universalProvider);

      if (this.provider) {
        const accounts = await this.provider.listAccounts();
        const { chainId } = await this.provider.getNetwork();
        this.update.emit({
          chainId,
          accounts,
        });
      }
    });
  }

  async connectEagerly(): Promise<void> {
    try {
      await this.init();
      if (!this.universalProvider) {
        throw new Error('Could not initialize connector');
      }

      this.provider = new providers.Web3Provider(this.universalProvider, 'any');

      this.universalProvider.setDefaultChain('eip155:1');

      const accounts = await this.provider.listAccounts();

      const { chainId } = await this.provider.getNetwork();

      this.update.emit({
        chainId,
        accounts,
      });
    } catch (e) {
      console.error('Error connecting eagerly: ', e);
    }
  }

  async activate(): Promise<void> {
    try {
      await this.init();
      if (!this.universalProvider) {
        throw new Error('Could not initialize connector');
      }

      this.universalProvider.on('display_uri', async (uri: string) => {
        if (!this.universalProvider?.session) {
          await this.walletConnectModal.openModal({
            uri,
          });
        }
      });

      // Subscribe to session event
      this.universalProvider.on('session_event', (e: unknown) => {
        console.log('session_event', e);
      });

      // Subscribe to session delete
      this.universalProvider.on('session_delete', (e: unknown) => {
        console.log('session_delete', e);
      });

      // Subscribe to session ping
      this.universalProvider.on('session_ping', (e: unknown) => {
        console.log('session_ping', e);
      });

      // Subscribe to session update
      this.universalProvider.on('session_update', (e: unknown) => {
        console.log('session_update', e);
      });

      const eip55Params = {
        methods: [
          'eth_sendTransaction',
          'personal_sign',
          'eth_signTypedData_v4',
        ],
        events: ['chainChanged', 'accountsChanged'],
        rpcMap,
      };

      const session = await new Promise<
        SessionTypes.Struct | undefined
        // eslint-disable-next-line no-async-promise-executor
      >(async (resolve, reject) => {
        try {
          this.walletConnectModal.subscribeModal(e => {
            if (!e.open) {
              reject();
            }
          });

          const s = await this.universalProvider?.connect({
            namespaces: {
              eip155: {
                chains: ['eip155:1'],
                defaultChain: 'eip155:1',
                ...eip55Params,
              },
            },
            optionalNamespaces: {
              eip155: {
                chains: ['eip155:137', 'eip155:43114', 'eip155:56'],
                ...eip55Params,
              },
            },
          });

          resolve(s);
        } catch (error) {
          reject();
        }
      });

      if (session?.acknowledged) {
        this.walletConnectModal.closeModal();
      }

      this.provider = new providers.Web3Provider(this.universalProvider, 'any');

      this.universalProvider.setDefaultChain('eip155:1');

      const accounts = await this.provider.listAccounts();
      const { chainId } = await this.provider.getNetwork();

      this.update.emit({
        chainId,
        accounts,
      });
    } catch (e: any) {
      console.log('Connection Error', e);
      this.walletConnectModal.closeModal();
      this.cleanupVerifyApi();
      throw new Error(`Could not activate connector: ${e.message ?? ''}`);
    }
  }

  async deactivate(): Promise<void> {
    if (this.universalProvider?.session) {
      this.universalProvider?.disconnect();
    }

    this.provider = undefined;

    this.cleanupVerifyApi();
  }

  // eslint-disable-next-line class-methods-use-this
  private cleanupVerifyApi() {
    // Super hacky wc verify-api cleanup
    const va = document.getElementById('verify-api');
    va?.remove();
  }
}
