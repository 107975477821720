import styled, { css } from 'styled-components';

import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

import { media } from '../media';

export const StyledMenuButton = styled.button<{
  open: boolean;
}>`
  ${({ open }) => css`
    background: ${open ? colors.shade30 : 'none'};
    color: inherit;
    border: none;
    border-radius: ${open && '4px'};
    margin-right: 8px;
    box-sizing: border-box;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 60px;
    @media (min-width: ${media.medium}) {
      display: none;
    }
    &:hover {
      background: ${colors.shade40};
      border-radius: 4px;
    }
  `}
`;

export const Overlay = styled.div<{ standalone: boolean }>`
  ${({ standalone }) => css`
    position: fixed;
    top: 56px;
    left: 0;
    width: 100vw;
    height: ${standalone ? 'calc(100vh - 144px)' : 'calc(100vh - 112px)'};
    z-index: 1;
    opacity: 0.7;
    background-color: ${colors.shade10};
  `}
`;

export const CrossWrapper = styled.div``;
