import { BigNumber, ethers } from 'ethers';

import { DEFAULT_VALUE } from '@context/constants';

export const formatValue = (value: BigNumber, decimals: number): string => {
  if (value) {
    return ethers.utils.formatUnits(value, decimals);
  }

  return DEFAULT_VALUE;
};
