import { State } from '../store';
import { TExchangeProvider } from '../types';

export type ProviderPayload = TExchangeProvider;

export type ProviderAction = {
  type: 'SET_PROVIDER';
  payload: ProviderPayload;
};

export const providerReducer = (
  state: State,
  payload: ProviderPayload,
): State => ({
  ...state,
  provider: payload,
});
