import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TOrder, WalletController } from '@bitcoin-portal/neko-web-sdk';

import { EMPTY, NULL_WALLET } from '@context/constants';
import { useTrackedState } from '@context/store';
import { WalletType } from '@context/types';

import { ORDER_STATUSES } from '@views/Swap/context/constants';

import { sortWalletsByName } from '@views/Swap/helpers/wallet';

import { useSwapContext } from './SwapProvider';

type CefiSwapState = {
  orderData?: TOrder;
  orderId: string;
  orderStatus?: ORDER_STATUSES;
  userWallets: WalletType[];
  setOrderData: Dispatch<SetStateAction<TOrder | undefined>>;
  setOrderId: Dispatch<SetStateAction<string>>;
  setOrderStatus: Dispatch<SetStateAction<ORDER_STATUSES | undefined>>;
};

export const INITIAL_STATE = {
  orderId: EMPTY,
  userWallets: [] as WalletType[],
  setOrderData: () => null,
  setOrderId: () => null,
  setOrderStatus: () => null,
};

const CefiSwapContext = createContext<CefiSwapState>(INITIAL_STATE);

export const CefiSwapProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userDetails } = useTrackedState();
  const { setSelectedWallet } = useSwapContext();

  const [orderData, setOrderData] = useState<TOrder | undefined>();
  const [orderId, setOrderId] = useState(EMPTY);
  const [orderStatus, setOrderStatus] = useState<ORDER_STATUSES | undefined>();
  const [userWallets, setUserWallets] = useState([] as WalletType[]);

  useEffect(() => {
    const fetchBcomWallets = async () => {
      if (!userDetails) {
        setUserWallets([]);
        setSelectedWallet(NULL_WALLET);
        return;
      }

      try {
        const res = await WalletController.getLinkedWallets();

        if (res.isFailure) throw new Error(res.error);

        const payload = res.getValue();
        const wallets = sortWalletsByName(payload);

        setUserWallets(wallets);
      } catch (error) {
        console.error('Error fetching wallets:', error);
        setUserWallets([]);
      }
    };
    fetchBcomWallets();
  }, [userDetails]);

  const contextValue = useMemo(
    () => ({
      orderData,
      orderId,
      orderStatus,
      userWallets,
      setOrderData,
      setOrderId,
      setOrderStatus,
    }),
    [orderData, orderId, orderStatus, userWallets],
  );

  return (
    <CefiSwapContext.Provider value={contextValue}>
      {children}
    </CefiSwapContext.Provider>
  );
};

export const useCefiSwapContext = () => useContext(CefiSwapContext);
