import development from '../featureFlags/development.json';
import production from '../featureFlags/production.json';
import staging from '../featureFlags/staging.json';

export type TFeatureFlagId =
  | 'airdropBanner'
  | 'multichain'
  | 'promotions'
  | 'lifiAdmin'
  | 'widgetRebuild'
  | 'voting'
  | 'stakingList'
  | 'swapv2';

export type TFeatureFlag = {
  id: string;
  description: string;
  enabled: boolean;
  paths: string[];
};

const getAllFeatureFlags = (
  environment: string | undefined,
): TFeatureFlag[] => {
  switch (environment) {
    case 'staging':
      return staging;
    case 'production':
      return production;
    default:
      return development;
  }
};

export const featureFlags = getAllFeatureFlags(process.env.GATSBY_ACTIVE_ENV);

export const getFeatureFlag = (id: TFeatureFlagId): TFeatureFlag | undefined =>
  featureFlags.find(item => item.id === id);

export const isFeatureFlagEnabled = (id: TFeatureFlagId): boolean =>
  featureFlags.some(item => item.id === id && item.enabled);

export const useFeatureFlag = (
  id: TFeatureFlagId,
): {
  runIfEnabled: (callback: () => void) => void;
  isEnabled: boolean;
  featureFlag: TFeatureFlag | undefined;
} => ({
  runIfEnabled: (callback: () => void) =>
    isFeatureFlagEnabled(id) ? callback() : null,
  isEnabled: isFeatureFlagEnabled(id),
  featureFlag: getFeatureFlag(id),
});

export const getDisabledPathsList = () =>
  featureFlags
    .filter(flag => !flag.enabled)
    .map(flag => flag.paths)
    .flat();

export default featureFlags;
