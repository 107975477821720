import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const SwapIcon: FunctionComponent<{
  fillColor?: string;
  [key: string]: ReactNode;
}> = ({ fillColor = 'currentColor', ...rest }) => {
  return (
    <>
      <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M12.5 10H11.25V6.02476L6.4466 5.06407C6.23354 5.02146 6.01679 5 5.79951 5C3.97724 5 2.5 6.47724 2.5 8.29951V11.25H0V8.29951C0 5.09653 2.59653 2.5 5.79951 2.5C6.18142 2.5 6.56239 2.53772 6.93689 2.61262L11.25 3.47525V0H12.5L17.5 5L12.5 10Z"
          fill={fillColor}
        />
        <path
          d="M20 8.75V11.7005C20 14.9035 17.4035 17.5 14.2005 17.5C13.8186 17.5 13.4376 17.4623 13.0631 17.3874L8.75 16.5248V20H7.5L2.5 15L7.5 10H8.75V13.9752L13.5534 14.9359C13.7665 14.9785 13.9832 15 14.2005 15C16.0228 15 17.5 13.5228 17.5 11.7005V8.75H20Z"
          fill={fillColor}
        />
      </SvgIcon>
    </>
  );
};

export default SwapIcon;
