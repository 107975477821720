export const getCustomDecimals = (value: number, precision: number): number => {
  const absValue = Math.abs(value);

  if (absValue === 0) return 2;
  if (absValue >= 1000) return 0;
  if (absValue < 1e-10) return 18;
  if (absValue < 1e-6) return 10;
  if (absValue < 0.01 && absValue >= 1e-6) return 6;

  return precision;
};
