/* eslint-disable no-underscore-dangle */
import { ExchangeRatesType, WalletAsset, WalletType } from '@context/types';

import { BCOM_SUPPORTED_PROTOCOLS } from '@views/Swap/context/constants';

import { addThousandsSeparator, sanitizeInput } from '@helpers/index';

// fixes decimal places without rounding
export const toTruncated = (
  num: number,
  fixed: number,
  noThousandSeparator?: boolean,
): string => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  if (num > 0) {
    const fixedNum = num.toString().match(re)?.[0] || '0.00';
    const formattedNum = noThousandSeparator
      ? fixedNum
      : addThousandsSeparator(fixedNum);
    return formattedNum;
  }

  return '0.00';
};

export const findDEXToken = (
  exchangeProvider: string,
  address: string,
  dexTokens: ITickerObject[],
): ITickerObject => {
  const res = dexTokens.find(
    (x: ITickerObject) => x?.token?.toLowerCase() === address.toLowerCase(),
  );
  return res || dexTokens[0];
};

// TODO: can we clean up/simplify?
export const findCEXToken = (
  array: ITickerObject[],
  value: string, // ticker or assetId
  protocol?: string,
  blockchain?: string,
): string | undefined => {
  const targetValue = value.toLowerCase();
  const res = array.find((x: ITickerObject) => {
    if (x.token && x.token.toLowerCase() === targetValue) {
      if (protocol && blockchain)
        return x.protocol === protocol && x.blockchain === blockchain;
      return true;
    }

    if (protocol && blockchain)
      return (
        x.ticker.toLowerCase() === targetValue &&
        x.protocol === protocol &&
        x.blockchain === blockchain
      );

    return x.ticker.toLowerCase() === targetValue;
  });

  return res?.token || undefined;
};

export const findSupportedTokens = (
  array: ITickerObject[],
): ITickerObject[] => {
  return array.filter((x: ITickerObject) =>
    BCOM_SUPPORTED_PROTOCOLS.includes(x.protocol),
  );
};

export const findToken = (
  array: ITickerObject[],
  value: string,
  address?: string,
): string | undefined => {
  const res = array?.find((x: ITickerObject): boolean => {
    if (x.token) {
      if (address) {
        return x.token.toLowerCase() === address.toLowerCase();
      }
      return x.abbr.toLowerCase() === value.toLowerCase();
    }
    return false;
  });

  return res?.token?.replace('_ETH', '') || undefined;
};

export const findExplorer = (
  array: ITickerObject[],
  value: string,
): string | undefined => {
  const res = array.find((x: ITickerObject) => x?.abbr === value);
  return res?.explorer || undefined;
};

export const findDecimals = (
  array: ITickerObject[],
  token: string | undefined,
): number => {
  const res = array.find((x: ITickerObject) => x.token === token);
  return res?.decimals || 18;
};

export const findPrefill = (
  wallets: WalletType[],
  chain: string,
  protocol?: string,
): WalletType | string => {
  const protocolSupported = protocol
    ? BCOM_SUPPORTED_PROTOCOLS.includes(protocol)
    : true;

  const result = (wallets || []).filter((obj: WalletType) => {
    return chain.includes(obj.coin);
  });

  return (protocolSupported && result && result[0]) || '';
};

export const findBalance = (
  balances: WalletAsset[],
  ticker: string,
  array: ITickerObject[],
  protocol: string,
  blockchain: string,
): number => {
  const targetTicker = ticker.toLowerCase();
  const targetProtocol = protocol.toLowerCase();
  const targetBlockchain = blockchain.toLowerCase();

  const token = array.find(
    t =>
      t.protocol.toLowerCase() === targetProtocol &&
      t.ticker.toLowerCase() === targetTicker &&
      t.blockchain.toLowerCase() === targetBlockchain,
  );

  const matchingToken = balances.find((x: WalletAsset) => {
    const matchingTicker = x.ticker?.toLowerCase() === targetTicker;
    const matchingId = token?.token === x.id;

    if (token?.token) return matchingTicker && matchingId;
    return matchingTicker;
  });

  return matchingToken?.balance || 0;
};

/* --- RATES --- (move somewhere else?) */

export const getFiatRate = (
  isDefiProvider: boolean,
  exchangeProvider: string,
  toCurrencyKey: string,
  exchangeRates: ExchangeRatesType,
  dexRates: DexRatesType,
): number => {
  let finalFiatRate = 0.0;
  if (isDefiProvider) {
    const defined = dexRates[exchangeProvider] !== undefined;

    if (defined && dexRates[exchangeProvider][toCurrencyKey]) {
      finalFiatRate = dexRates[exchangeProvider][toCurrencyKey];
    }
  } else {
    const defined = exchangeRates[toCurrencyKey] !== undefined;

    if (defined) {
      finalFiatRate = exchangeRates[toCurrencyKey].USD;
    }
  }

  return finalFiatRate;
};

export const showRate = (value: number, rate: string): number => {
  if (!value || !rate) return 0;
  return value * sanitizeInput(rate);
};

export const convertToFiat = (
  amount: string,
  currencyKey: string,
  isDefiProvider: boolean,
  exchangeProvider: string,
  exchangeRates: ExchangeRatesType,
  dexRates: DexRatesType,
): number => {
  const fiatRate = getFiatRate(
    isDefiProvider,
    exchangeProvider,
    currencyKey,
    exchangeRates,
    dexRates,
  );

  const currencyFiat =
    !amount || amount === 'NaN' ? 0 : sanitizeInput(amount) * fiatRate;
  return currencyFiat;
};
