export const getMatchingPair = (
  tokenA: string,
  tokenB: string,
  pairs: ILiquidityPair[],
): ILiquidityPair | undefined => {
  const pair = pairs.find(p => {
    const token0 = p.token0.symbol.toLowerCase();
    const token1 = p.token1.symbol.toLowerCase();

    const symbolA =
      tokenA.toLowerCase() === 'weth' ? 'eth' : tokenA.toLowerCase();
    const symbolB =
      tokenB.toLowerCase() === 'weth' ? 'eth' : tokenB.toLowerCase();

    return (
      (symbolA === token0 && symbolB === token1) ||
      (symbolA === token1 && symbolB === token0)
    );
  });

  return pair;
};
