import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const AnalyticsIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path d="M20 1.25H15V18.75H20V1.25Z" fill="currentColor" />
        <path d="M7.5 6.25H12.5V18.75H7.5V6.25Z" fill="currentColor" />
        <path d="M0 11.25H5V18.75H0V11.25Z" fill="currentColor" />
      </SvgIcon>
    </>
  );
};

export default AnalyticsIcon;
