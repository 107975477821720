import { GraphQLClient, gql } from 'graphql-request';

import {
  BaseCoinPriceResponse,
  LiquidityPairResponse,
  TokenListResponse,
} from './types';

export class SubgraphAPIClient {
  client: GraphQLClient;

  constructor(baseUrl: string) {
    this.client = new GraphQLClient(baseUrl, {
      headers: {},
    });
  }

  getBaseCoinPrice = async (): Promise<BaseCoinPriceResponse> => {
    const query = gql`
      {
        bundle(id: "1") {
          ethPrice
        }
      }
    `;

    return this.client.request(query);
  };

  getLiquidityPair = async (id: string): Promise<LiquidityPairResponse> => {
    const query = gql`
            {
                pair(id: "${id}") {
                    id
                    token0Price
                    token1Price
                    name
                    reserveUSD
                    totalSupply
                }
            }
        `;

    return this.client.request(query);
  };

  getPairDailyData = async (pairId: string, since: number): Promise<any> => {
    const query = gql`
            {
                pair(id: "${pairId}") {
                    id
                    name
                    dayData(first: 1000, where: { date_gt: ${since} }) {
                        date
                        volumeUSD
                    }
                }
            }
        `;

    return this.client.request(query);
  };

  getTokenList = async (): Promise<TokenListResponse> => {
    const query = gql`
      {
        tokens(orderBy: liquidity, orderDirection: desc) {
          id
          name
          symbol
          liquidity
          derivedETH
          decimals
        }
      }
    `;

    return this.client.request(query);
  };

  getUserLiquidityPositions = async (id: string): Promise<any> => {
    const query = gql`
            {
                user(id: "${id}") {
                    liquidityPositions {
                        liquidityTokenBalance
                        pair {
                            id
                            name
                            totalSupply
                            reserveUSD
                        }
                        snapshots {
                            timestamp
                            liquidityTokenBalance
                            liquidityTokenTotalSupply
                            reserveUSD
                        }
                    }
                }
            }
        `;

    return this.client.request(query);
  };

  getMintTransaction = async (
    pairId: string,
    feeToAddress: string,
    timestamp: number,
  ): Promise<any> => {
    const query = gql`
            {
                pair(id: "${pairId}") {
                    liquidityPositionSnapshots(
                        where: { timestamp: ${timestamp} }
                    ) {
                        liquidityTokenBalance
                        liquidityTokenTotalSupply
                        reserveUSD
                    }
                }
            }
        `;

    return this.client.request(query);
  };

  getGraphSwapHistory = async (walletId: string): Promise<any> => {
    const query = gql`
            {
                swaps(where: { to: "${walletId}" }) {
                    id
                    amount0In
                    amount1In
                    amount0Out
                    amount1Out
                    pair {
                      name
                      token0 {
                        id
                        name
                        symbol
                      }
                      token1 {
                        id
                        name
                        symbol
                      }
                    }
                    timestamp
                }
            }
        `;
    return this.client.request(query);
  };

  // query needed to fetch yesterday's block # for pair APY calc
  getBlockNumber = async (start: number, end: number): Promise<any> => {
    const query = gql`
            {
                blocks(
                    first: 1,
                    orderBy: timestamp,
                    where: {
                      timestamp_gt: ${start}
                      timestamp_lt: ${end}
                    })
                    {
                        id
                        number
                        timestamp
                    }
            }
        `;

    return this.client.request(query);
  };

  // query needed to fetch volume until yesterday & now to calc APY calc
  getAllTimeVolume = async (pairId: string, block?: number): Promise<any> => {
    const query = gql`
            {
                pair(
                    id: "${pairId}",
                    ${block ? `block: { number: ${block} }` : ''}
                ) {
                    name
                    volumeUSD
                }
            }
        `;

    return this.client.request(query);
  };
}
