import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const VoteIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path d="M16 2H0V5H16V2Z" fill="currentColor" />
        <path d="M1 7H5V9H11V7H15V15H1V7Z" fill="currentColor" />
      </SvgIcon>
    </>
  );
};

export default VoteIcon;
