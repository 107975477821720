import { BigNumber } from 'ethers';

import { LinksType } from '@context/types';

export const APPROVE_DEFAULT_VALUE = BigNumber.from(2 ** 32);

export const ETH_BLOCKLYTICS_SUBGRAPH =
  'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks';
export const SBCH_BLOCKLYTICS_SUBGRAPH =
  'https://sbch.subgraph.api.bitcoin.com/subgraphs/name/verse/ethereum-blocks';

export const TOLERANCE = 0.99;
export const MAX_TOLERANCE = 0.8;
export const FIAT_PRECISION = 2;
export const REWARDS_RATE = 0.0025;

export const BUY_BASE_URL = 'https://buy.bitcoin.com';

// tokens supported on buy.bitcoin.com and relative paths
export const SUPPORTED_BUY_TOKENS: LinksType = {
  VERSE: '/verse/',
  AVAX: '/avax-cchain/',
  BAT: '/bat/',
  BCH: '/bch/',
  BNB: '/verse/',
  BTC: '/btc/',
  COMP: '',
  DASH: '',
  DOGE: '',
  DOT: '/dot/',
  ETH: '/eth/',
  GUSD: '',
  LTC: '/ltc/',
  MATIC: '/matic-polygon/',
  SHIB: '',
  SUSHI: '',
  UNI: '',
  USDC: '',
  USDT: '',
  WBTC: '',
  XLM: '/xlm/',
  XRM: '',
};

type DepositType = {
  title: string;
  label: string;
  ampLabel?: string;
  isNew?: boolean;
};

// for PoolAddLiquidity views
export const LP_DEPOSIT_TYPES: DepositType[] = [
  {
    title: 'default',
    label: 'Default Assets',
    ampLabel: 'dual asset',
  },
  {
    title: 'single',
    label: 'Single Asset',
    isNew: true,
    ampLabel: 'single asset',
  },
];
