import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { useCexTokens } from '@views/Swap/context/providers/CexTokensProvider';
import { useSwapContext } from '@views/Swap/context/providers/SwapProvider';

import { useQuery } from '@helpers/index';

import { useTrackedState } from '../store';

export const CefiCurrencyHandler: FunctionComponent = () => {
  const query = useQuery();
  const { provider: exchangeProvider } = useTrackedState();

  const cexTokens = useCexTokens();

  const {
    swapFromBlockchain,
    swapToBlockchain,
    swapFromCurrency,
    swapToCurrency,
    swapFromProtocol,
    swapToProtocol,
    setSwapFromCurrency,
    setSwapFromProtocol,
    setSwapFromBlockchain,
    setSwapToCurrency,
    setSwapToProtocol,
    setSwapToBlockchain,
  } = useSwapContext();

  const [usedQuery, setUsedQuery] = useState(false);

  // queries
  const queryFromToken = query.get('from')?.toUpperCase() || '';
  const queryToToken = query.get('to')?.toUpperCase() || '';
  const queryFromChain = query.get('fromchain')
    ? `${query.get('fromchain')?.toUpperCase()}_BLOCKCHAIN`
    : '';
  const queryToChain = query.get('tochain')
    ? `${query.get('tochain')?.toUpperCase()}_BLOCKCHAIN`
    : '';

  // token lists
  const cexTokensNoVerse = useMemo(
    () => cexTokens.filter(t => t.abbr !== 'VERSE'),
    [cexTokens.length],
  );

  // can only swap to verse on cefi with sideshift atm
  const cexTokensOut = useMemo(
    () => (exchangeProvider === 'changenow' ? cexTokensNoVerse : cexTokens),
    [exchangeProvider, cexTokensNoVerse, cexTokens],
  );

  useEffect(() => {
    const fromTokenOverride = usedQuery ? queryFromToken : '';
    const toTokenOverride = usedQuery ? queryToToken : '';
    const fromChainOverride = usedQuery ? queryFromChain : '';
    const toChainOverride = usedQuery ? queryToChain : '';

    const selectedFromToken = cexTokensNoVerse.find(
      t =>
        t.abbr === (fromTokenOverride || swapFromCurrency) &&
        t.blockchain === (fromChainOverride || swapFromBlockchain),
    );

    let selectedToToken = cexTokensOut.find(
      t =>
        t.abbr === (toTokenOverride || swapToCurrency) &&
        t.blockchain === (toChainOverride || swapToBlockchain),
    );

    if (
      selectedFromToken?.abbr === selectedToToken?.abbr &&
      selectedFromToken?.blockchain === selectedToToken?.blockchain
    ) {
      selectedToToken = cexTokensOut.find(
        t =>
          t.abbr !== selectedFromToken?.abbr &&
          t.blockchain !== selectedFromToken?.blockchain,
      );
    }

    if (queryFromToken || queryToToken) {
      setUsedQuery(true);
    }

    // update context
    setSwapFromCurrency(selectedFromToken?.abbr || 'ETH');
    setSwapFromProtocol(selectedFromToken?.protocol || 'ETH_PROTOCOL');
    setSwapFromBlockchain(selectedFromToken?.blockchain || 'ETH_BLOCKCHAIN');
    setSwapToCurrency(
      selectedToToken?.abbr ||
        (exchangeProvider === 'changenow' ? 'DAI' : 'VERSE'),
    );
    setSwapToProtocol(selectedToToken?.protocol || 'ERC_20_PROTOCOL');
    setSwapToBlockchain(selectedToToken?.blockchain || 'ETH_BLOCKCHAIN');
  }, [
    cexTokensNoVerse,
    cexTokensOut,
    exchangeProvider,
    queryFromChain,
    queryToChain,
    queryFromToken,
    queryToToken,
    swapFromBlockchain,
    swapToBlockchain,
    swapFromCurrency,
    swapToCurrency,
    swapFromProtocol,
    swapToProtocol,
    usedQuery,
  ]);

  return <></>;
};

export default CefiCurrencyHandler;
