import { useStytch } from '@stytch/react';
import React, {
  Dispatch,
  FunctionComponent,
  RefObject,
  SetStateAction,
  useState,
} from 'react';

import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import AppleLogo from '@bitcoin-portal/verse-web-components/dist/Icons/AppleLogo';
import GoogleLogo from '@bitcoin-portal/verse-web-components/dist/Icons/GoogleLogo';
import InputAction from '@bitcoin-portal/verse-web-components/dist/InputAction';
import LoginButtonCustom from '@bitcoin-portal/verse-web-components/dist/LoginButtonCustom';
import Modal from '@bitcoin-portal/verse-web-components/dist/Modal';

import { useDispatch } from '../../../../context/store';

import {
  newrelicError,
  sendEmailMagicLink,
  validateEmail,
} from '../../../../helpers';

import { StyledButton, StyledText } from './styled';

interface Props {
  loginModalRef?: RefObject<Modal>;
  checkEmailModalRef?: RefObject<Modal>;
  updateEmail: (email: string) => void;
  email: string;
  setSubmittedEmail: Dispatch<SetStateAction<string>>;
  trackEvent?: (wallet: string) => void;
}

const StytchLogin: FunctionComponent<Props> = ({
  loginModalRef,
  checkEmailModalRef,
  updateEmail,
  email,
  setSubmittedEmail,
  trackEvent,
}) => {
  const stytchClient = useStytch();
  const dispatch = useDispatch();
  const [emailValid, setEmailValid] = useState(true);

  const { pathname, origin } = useLocation();

  const startOAuth = (provider: 'apple' | 'google') => {
    try {
      stytchClient.oauth[provider].start({
        login_redirect_url: `${origin}/auth/`,
        signup_redirect_url: `${origin}/auth/`,
      });
      dispatch({
        type: 'SET_LOGIN_FROM',
        payload: pathname,
      });
    } catch (e) {
      navigate('.', {
        state: { stytchLoginError: true },
      });
      newrelicError(e, {
        method: 'startOAuth',
        page: pathname,
      });
    }
  };

  const submit = async () => {
    const valid = validateEmail(email);

    if (!valid) {
      setEmailValid(false);
      return;
    }

    trackEvent?.('magic link');

    const stytchLoginSuccess = await sendEmailMagicLink(
      stytchClient,
      email,
      pathname,
      dispatch,
    );
    setSubmittedEmail(email);
    if (loginModalRef) loginModalRef.current?.close();
    if (!stytchLoginSuccess) return;
    if (checkEmailModalRef) checkEmailModalRef.current?.open();
  };

  return (
    <>
      <div>
        <LoginButtonCustom
          onClick={() => {
            trackEvent?.('apple');
            startOAuth('apple');
          }}
          logo={
            <span style={{ marginBottom: 5 }}>
              <AppleLogo size={18} />
            </span>
          }
          text="Sign in with Apple"
        />
        <LoginButtonCustom
          onClick={() => {
            trackEvent?.('google');
            startOAuth('google');
          }}
          logo={<GoogleLogo />}
          text="Sign in with Google"
        />
      </div>
      <StyledText>OR</StyledText>
      <div>
        <InputAction
          action={
            <StyledButton onClick={submit} disabled={!emailValid}>
              Continue
            </StyledButton>
          }
          type="email"
          value={email}
          placeholder="Example@email.com"
          onChange={e => {
            updateEmail(e.target.value);
            setEmailValid(true);
          }}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              submit();
            }
          }}
          error={
            !emailValid ? 'Invalid email address. Please try again.' : undefined
          }
        />
      </div>
    </>
  );
};

export default StytchLogin;
