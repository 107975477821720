import { useEffect, useState } from 'react';

import { useEthers } from '@usedapp/core';

import { useTrackedState } from '@context/store';

export const useIsLoading = () => {
  const { isWallet } = useTrackedState();
  const { active } = useEthers();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (active && isWallet !== undefined) {
      setIsLoading(false);
    }
  }, [active, isWallet]);

  return isLoading;
};
