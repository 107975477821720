import { flattenMessages } from '../../helpers';

import en from './en.json';
import es from './es.json';
import ru from './ru.json';
import zh from './zh.json';

export default {
  en: flattenMessages(en, 'home'),
  es: flattenMessages(es, 'home'),
  ru: flattenMessages(ru, 'home'),
  zh: flattenMessages(zh, 'home'),
};
