import React, { FunctionComponent, useEffect } from 'react';

import { navigate } from 'gatsby';

import { useTrackedState } from '../store';

interface Props {
  page?: string;
}

export const OriginHandler: FunctionComponent<Props> = ({ page = '/' }) => {
  const { isWallet } = useTrackedState();

  useEffect(() => {
    if (isWallet) {
      navigate(`${page}?origin=wallet`, { replace: true });
    }
  }, [isWallet, page]);

  return <></>;
};

export default OriginHandler;
