const versePolygonStakingData = {
  contractAddress: '0x66b0fbbeb420b63155d61ec5922293148bb796ec',
  stakingToken: {
    // ITickerObject
    token: '0xc708D6F2153933DAA50B2D0758955Be0A93A8FEc',
    decimals: 18,
    abbr: 'VERSE',
    value: 'VERSE',
    blockchain: 'MATIC',
    label: 'Verse',
    ticker: 'verse',
    explorer: '',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/verse.png',
  },
  rewardToken: {
    // ITickerObject
    token: '0x236aa50979D5f3De3Bd1Eeb40E81137F22ab794b',
    decimals: 18,
    abbr: 'tBTC',
    value: 'tBTC',
    blockchain: 'MATIC',
    label: 'tBTC v2',
    ticker: 'tbtc',
    explorer: 'https://polygonscan.com/tx/',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/26133.png',
  },
  rewardTokens: [
    {
      // ITickerObject
      token: '0x236aa50979D5f3De3Bd1Eeb40E81137F22ab794b',
      decimals: 18,
      abbr: 'tBTC',
      value: 'tBTC',
      blockchain: 'MATIC',
      label: 'tBTC v2',
      ticker: 'tbtc',
      explorer: 'https://polygonscan.com/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/26133.png',
    },
  ],
  pair: {
    id: '0xc708D6F2153933DAA50B2D0758955Be0A93A8FEc',
    name: 'VERSE',
    token0: {
      name: 'Verse',
      symbol: 'VERSE',
      id: '0xc708D6F2153933DAA50B2D0758955Be0A93A8FEc',
      decimals: 18,
    },
  },
  singleSided: true,
  customPath: 'verse-btc',
  dynamicRewards: true,
};

export default versePolygonStakingData;
