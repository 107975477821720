import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const GetVerseIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="get-verse" viewBox="0 0 20 21" fill="none" {...rest}>
        <path
          d="M11.5496 0.902588H8.48777L8.03235 9.52759H4.0011L10.0011 16.5276L16.0011 9.52759H12.0636L11.5496 0.902588Z"
          fill="#899BB5"
        />
        <path
          d="M0 10.9026C0 6.67024 2.62929 3.052 6.34356 1.59216L6.22358 3.84825C3.70962 5.19687 2 7.85008 2 10.9026C2 15.3209 5.58172 18.9026 10 18.9026C14.4183 18.9026 18 15.3209 18 10.9026C18 7.86377 16.3057 5.22069 13.8102 3.86648L13.691 1.60581C17.387 3.07445 20 6.68337 20 10.9026C20 16.4254 15.5228 20.9026 10 20.9026C4.47715 20.9026 0 16.4254 0 10.9026Z"
          fill="#899BB5"
        />
      </SvgIcon>
    </>
  );
};

export default GetVerseIcon;
