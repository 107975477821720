import { State } from '../store';

export type FarmDepositsAction = {
  type: 'SET_FARM_DEPOSITS';
  payload: FarmDepositType;
};

export const farmDepositsReducer = (
  state: State,
  payload: FarmDepositType,
): State => ({
  ...state,
  farmDeposits: { ...state.farmDeposits, ...payload },
});
