import React from 'react';

import { GatsbyBrowser } from 'gatsby';

import WrapPageElement from './src/context/WrapPageElement';
import WrapRootElement from './src/context/WrapRootElement';

import browserHydrateFunction from './src/helpers/gatsby/browser-hydrate-function';

export const wrapPageElement: GatsbyBrowser<
  null,
  { locale: string }
>['wrapPageElement'] = WrapPageElement;

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <WrapRootElement>{element}</WrapRootElement>;
};

export const onServiceWorkerUpdateReady: GatsbyBrowser['onServiceWorkerUpdateReady'] =
  () => {
    if (!window?.location) return;

    window.location.reload();
  };

export const replaceHydrateFunction = browserHydrateFunction;
