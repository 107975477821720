import { Dispatch } from 'react';

import { navigate } from 'gatsby';

import { logoutAuth } from '../context/api/nekosdk';
import { Action } from '../context/reducer';

export const disconnectBcom = async (
  dispatch: Dispatch<Action>,
): Promise<void> => {
  await logoutAuth(dispatch);
  navigate('.', {
    state: { bcom: false },
  });
};

export const redirectAfterLogin = (
  loginFrom?: string,
  dispatch?: Dispatch<Action>,
): void => {
  if (loginFrom && dispatch) {
    dispatch({
      type: 'SET_LOGIN_FROM',
    });
    navigate(loginFrom);
  }
};
