import React, { FunctionComponent } from 'react';

import Swap from '@bitcoin-portal/verse-web-components/dist/Icons/Swap';

import { useMultichainProvider } from '@hooks/useMultichainProvider';

import Image from '../../Image';
import { MenuButton, SwapIconWrapper } from './styled';

interface Props {
  prov: IProviderOptionType;
  isCex?: boolean;
  onProviderSelect?: (provider: IProviderOptionType) => void;
}

const Provider: FunctionComponent<Props> = ({
  prov,
  isCex,
  onProviderSelect,
}) => {
  const { icon, label } = useMultichainProvider(prov);

  return (
    <MenuButton
      key={prov.provider}
      onClick={() => {
        if (onProviderSelect) onProviderSelect(prov);
      }}
    >
      {isCex ? (
        <SwapIconWrapper>
          <Swap size={12} />
        </SwapIconWrapper>
      ) : (
        <Image src={icon || ''} alt="provider icon" width={18} />
      )}
      {label}
    </MenuButton>
  );
};

export default Provider;
