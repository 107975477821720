import React from 'react';

import FireIcon from '@components/Icons/FireIcon';

import { TIcons } from '../../context/types';

import AnalyticsIcon from '../Icons/AnalyticsIcon';
import ClaimVerseIcon from '../Icons/ClaimVerseIcon';
import EarnBitcoinIcon from '../Icons/EarnBitcoinIcon';
import FarmsIcon from '../Icons/FarmsIcon';
import GetVerseIcon from '../Icons/GetVerseIcon';
import HelpIcon from '../Icons/HelpIcon';
import HomeIcon from '../Icons/HomeIcon';
import LoungeIcon from '../Icons/LoungeIcon';
import PoolsIcon from '../Icons/PoolsIcon';
import StakingIcon from '../Icons/StakingIcon';
import SwapIcon from '../Icons/SwapIcon';
import VoteIcon from '../Icons/VoteIcon';
import VoyagersIcon from '../Icons/VoyagersIcon';

export const icons: TIcons = {
  analytics: <AnalyticsIcon width={20} />,
  claim: <ClaimVerseIcon width={20} />,
  farms: <FarmsIcon width={20} />,
  burns: <FireIcon width={20} />,
  buy: <GetVerseIcon width={20} />,
  help: <HelpIcon width={20} />,
  home: <HomeIcon width={20} />,
  lounge: <LoungeIcon width={20} />,
  pools: <PoolsIcon width={20} />,
  staking: <StakingIcon width={20} />,
  swap: <SwapIcon width={20} />,
  swapv2: <SwapIcon width={20} />,
  vote: <VoteIcon width={20} />,
  voyagers: <VoyagersIcon width={20} />,
  earnbitcoin: <EarnBitcoinIcon width={20} />,
};
