import React, { FunctionComponent } from 'react';

import { useEthers } from '@usedapp/core';

import { useVerseClaimMerkle } from '@context/providers/VerseClaimMerkleProvider';
import { TMenuItem } from '@context/types';

import { icons } from '../../icons';

import {
  ModalContainer,
  ModalContent,
  NewBadge,
  StyledExternalLink,
  StyledIcon,
  StyledLink,
} from './styled';

interface Props {
  list: TMenuItem[];
  show: boolean;
  standalone: boolean;
  setShow: (show: boolean) => void;
  trackEvent?: (label: string, isNew?: boolean) => void;
}

const Modal: FunctionComponent<Props> = ({
  list,
  show,
  standalone,
  setShow,
  trackEvent,
}) => {
  const merkle = useVerseClaimMerkle();
  const { account } = useEthers();

  if (!show) return null;

  return (
    <ModalContainer standalone={standalone}>
      <ModalContent>
        {list.map(({ link, id, label, isNew }) => {
          return (
            <div
              key={id}
              onClick={() => trackEvent?.(label)}
              onKeyDown={() => trackEvent?.(label)}
              role="button"
              tabIndex={0}
            >
              {link.includes('http') ? (
                <StyledExternalLink
                  href={link}
                  newTab
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <StyledIcon>{icons[id]}</StyledIcon>
                  {label}
                </StyledExternalLink>
              ) : (
                <>
                  {link === '/claim/' && (!merkle || !account) ? null : (
                    <StyledLink
                      to={link}
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <StyledIcon>{icons[id]}</StyledIcon>
                      {label}
                      {isNew && <NewBadge>New</NewBadge>}
                    </StyledLink>
                  )}
                </>
              )}
            </div>
          );
        })}
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
