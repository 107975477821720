import React, { FunctionComponent } from 'react';

import Provider from '../Provider';
import { ProviderTypeLabel } from './styled';

interface Props {
  label: string;
  options: IProviderOptionType[];
  isCex?: boolean;
  onProviderSelect?: (provider: IProviderOptionType) => void;
}

const ProviderSection: FunctionComponent<Props> = ({
  label,
  options,
  isCex,
  onProviderSelect,
}) => {
  return (
    <>
      <ProviderTypeLabel>{label}</ProviderTypeLabel>
      {options.map(prov => (
        <Provider
          key={prov.provider}
          prov={prov}
          isCex={isCex}
          onProviderSelect={onProviderSelect}
        />
      ))}
    </>
  );
};

export default ProviderSection;
