import stakingSepoliaData from './stakingSepoliaData';

const farmsSepoliaList = [
  {
    contractAddress: '0x0FaC0696Fe21993B010495B31BBe735905a12B78',
    stakingToken: {
      // ITickerObject
      token: '0x05755d84E3C8b4E147e55c6bB35C0c4b45189692',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0xa46c5C2aaCA6eCcd3213825272Bf3ce5f15e1BF5',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0x05755d84E3C8b4E147e55c6bB35C0c4b45189692',
      name: 'VERSE/ETH',
      token0: {
        name: 'Verse',
        symbol: 'VERSE',
        id: '0xa46c5C2aaCA6eCcd3213825272Bf3ce5f15e1BF5',
        decimals: 18,
      },
      token1: {
        name: 'Ethereum',
        symbol: 'ETH',
        id: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
        decimals: 18,
      },
    },
  },
  stakingSepoliaData,
];

export default farmsSepoliaList;
