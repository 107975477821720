import React, { FunctionComponent, ReactNode, useEffect } from 'react';

import DAppProvider from '@context/providers/DAppProvider';

import { initAmplitude } from '../helpers';

interface Props {
  children: ReactNode;
}

const WrapRootElement: FunctionComponent<Props> = ({ children }) => {
  useEffect(() => {
    const initializeAmplitude = async () => {
      await initAmplitude();
    };
    initializeAmplitude();
  }, []);

  return <DAppProvider>{children}</DAppProvider>;
};

export default WrapRootElement;
