import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { TSwappableAsset } from '@bitcoin-portal/neko-web-sdk';

import { getSwappableAssets } from '@context/api/nekosdk';
import {
  BRIDGE_TOKENS,
  EXPLORER_LINKS,
  MATIC_ERC_TOKEN,
  TOKEN_PROTOCOLS,
} from '@context/constants';
import { useDexTokens } from '@context/providers/DexTokensProvider';
import { useDispatch } from '@context/store';
import { providers, tokensIn } from '@context/tokens';
import { TExchangeProvider } from '@context/types';

import { useQuery } from '@helpers/utils';

import { useSwapContext } from './SwapProvider';

type CexTokensState = ITickerObject[];

const CexTokensContext = createContext<CexTokensState>(tokensIn);

const DEFAULT_EXPLORER = 'https://etherscan.io/tx/';
const PINNED_COINS = ['BTC', 'BCH', 'ETH'];

const formatCexTokens = (
  payload: TSwappableAsset[],
  dexTokens: ITickerObject[],
): ITickerObject[] => {
  const allTokens = payload.filter((t: TSwappableAsset) => {
    if (t.assetTicker !== 'BTC') {
      return true;
    }
    return t.assetProtocol === 'BTC_PROTOCOL';
  });

  const pinnedTokens = allTokens
    .filter((t: TSwappableAsset) => {
      return PINNED_COINS.includes(t.assetId?.assetIdentifier);
    })
    .sort((t1: TSwappableAsset, t2: TSwappableAsset) => {
      return (
        PINNED_COINS.indexOf(t1.assetId?.assetIdentifier) -
        PINNED_COINS.indexOf(t2.assetId?.assetIdentifier)
      );
    });

  const otherTokens = allTokens.filter((t: TSwappableAsset) => {
    return (
      !PINNED_COINS.includes(t.assetId?.assetIdentifier) &&
      t.assetId?.assetIdentifier !== 'SMART_BCH'
    );
  });

  const cexTokens = [...pinnedTokens, ...otherTokens].map(
    (token: TSwappableAsset) => {
      const baseProtocol = token.assetProtocol.split('_')[0].toLowerCase();
      const explorerUrl = EXPLORER_LINKS[baseProtocol] || DEFAULT_EXPLORER;

      const tokenAddr = TOKEN_PROTOCOLS.includes(token.assetId?.protocol)
        ? token.assetId?.assetIdentifier
        : undefined;

      const tokenTicker = BRIDGE_TOKENS.includes(token.assetId?.assetIdentifier)
        ? token.assetId?.assetIdentifier
        : token.assetTicker;

      const decimals = dexTokens.find(
        t => t.abbr === token.assetTicker,
      )?.decimals;

      return {
        abbr: tokenTicker,
        value: tokenTicker,
        blockchain: token.assetId?.blockchain,
        protocol: token.assetId?.protocol,
        label: token.assetName,
        ticker: tokenTicker,
        explorer: explorerUrl,
        token: tokenAddr,
        decimals,
      };
    },
  );

  // add matic erc20 token if missing (for testing purposes)
  const missingMaticErc = !cexTokens.find(
    t => t.abbr === 'MATIC' && t.protocol === 'ERC_20_PROTOCOL',
  );

  if (missingMaticErc) {
    cexTokens.push(MATIC_ERC_TOKEN);
  }

  return cexTokens;
};

export const CexTokensProvider: FC<PropsWithChildren> = ({ children }) => {
  const dexTokens = useDexTokens();

  const { setCexProvider } = useSwapContext();
  const dispatch = useDispatch();
  const query = useQuery();

  const [cexTokens, setCexTokens] = useState<ITickerObject[]>(tokensIn);

  useEffect(() => {
    const queryProvider = query.get('provider');

    if (queryProvider && providers.map(p => p.name).includes(queryProvider)) {
      dispatch({
        type: 'SET_PROVIDER',
        payload: queryProvider as TExchangeProvider,
      });
    }
  }, []);

  useEffect(() => {
    const fetchAssets = async () => {
      const resp = await getSwappableAssets();
      if (resp.isFailure) return;

      const assetData = resp?.getValue();
      if (!assetData?.swapAssets?.length) return;

      const { provider: prov, swapAssets } = assetData;
      const formattedTokens = formatCexTokens(swapAssets, dexTokens);

      setCexProvider(prov);
      setCexTokens(formattedTokens);
    };
    // TO:DO might need to review this functon more closely
    fetchAssets();
  }, []);

  return (
    <CexTokensContext.Provider value={cexTokens}>
      {children}
    </CexTokensContext.Provider>
  );
};

export const useCexTokens = () => useContext(CexTokensContext);
