export const getVolumes = async (
  subgraphClient: ISubgraphAPIClient,
  pair: string,
  snapshot: ILiquidityPositionSnapshot,
  farmDeposits: FarmDepositType,
): Promise<number> => {
  const response = await subgraphClient.getPairDailyData(
    pair,
    snapshot?.timestamp,
  );

  const dayData = response?.pair?.dayData || [];

  const accumulatedVolume = dayData.reduce(
    (accumulator: number, current: any) =>
      accumulator + parseFloat(current.volumeUSD),
    0,
  );

  const volumeSinceFunding =
    snapshot?.liquidityTokenBalance === '0' && !farmDeposits?.[pair]
      ? 0
      : accumulatedVolume;

  return volumeSinceFunding;
};
