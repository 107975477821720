import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react';

import { useEthers } from '@usedapp/core';

import { findProvider, newrelicError } from '@helpers/index';

import { getReserves } from '@views/Pools/helpers/getReserves';
import { getVolumes } from '@views/Pools/helpers/getVolumes';

import { EMPTY_ACCOUNT } from '../constants';
import { useTrackedState } from '../store';

interface Props {
  subgraphClient: ISubgraphAPIClient;
  liquidityPositions: ILiquidityPositions;
  setLiquidityPositions: Dispatch<SetStateAction<ILiquidityPositions>>;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  success?: boolean;
}

export const LiquidityPositionHandler: FunctionComponent<Props> = ({
  subgraphClient,
  liquidityPositions,
  setLiquidityPositions,
  setLoading = () => null,
  success,
}) => {
  const { provider: exchangeProvider, farmDeposits } = useTrackedState();
  const { account } = useEthers();
  const provider = findProvider(exchangeProvider);

  const feeToAddress = useMemo(() => provider?.feeToAddress || '', [provider]);

  useEffect(() => {
    const getSnapshots = async () => {
      try {
        setLoading(true);
        const response =
          exchangeProvider === 'bitcoincom_seth'
            ? {}
            : await subgraphClient.getUserLiquidityPositions(
                (account || '').toLowerCase(),
              );

        const positions = response?.user?.liquidityPositions || [];

        const lps: ILiquidityPositions = {};
        positions.forEach((position: any) => {
          lps[position.pair.id] = {
            name: position.pair.name,
            pair: position.pair.id,
            liquidityTokenBalance: parseFloat(position.liquidityTokenBalance),
            reserveUSD: parseFloat(position.pair.reserveUSD),
            totalSupply: parseFloat(position.pair.totalSupply),
            snapshots: position.snapshots,
            volumeSinceFunding: 0,
            reserveUSDAtFunding: -1,
          };
        });

        setLiquidityPositions(lps);
      } catch (e) {
        newrelicError(e, {
          method: 'getSnapshots',
          account: account || '',
        });

        setLoading(false);
      }
    };

    if (provider.isDeFi) getSnapshots();
  }, [account, exchangeProvider, setLiquidityPositions, success]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const lps = liquidityPositions;
    const pairIds = Object.keys(liquidityPositions || {});

    const fetchPositionDetails = async () => {
      try {
        if (pairIds.length) {
          pairIds.forEach(async (pair: string) => {
            const position = liquidityPositions?.[pair];
            const snapshots = position?.snapshots || [];
            const lastSnapshot = snapshots[snapshots.length - 1];

            const reserveUSDAtFunding = await getReserves(
              subgraphClient,
              pair,
              lastSnapshot,
              feeToAddress,
            );
            const volumeSinceFunding = await getVolumes(
              subgraphClient,
              pair,
              lastSnapshot,
              farmDeposits,
            );

            lps[pair].reserveUSDAtFunding = reserveUSDAtFunding;
            lps[pair].volumeSinceFunding = volumeSinceFunding;
          });
        }
        setLiquidityPositions(lps);
        setLoading(false);
      } catch (e) {
        newrelicError(e, {
          method: 'fetchPositionDetails',
        });

        setLoading(false);
      }
    };
    if (provider.isDeFi && account && account !== EMPTY_ACCOUNT) {
      fetchPositionDetails();
    } else {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [farmDeposits, liquidityPositions, setLiquidityPositions]);

  return <></>;
};

export default LiquidityPositionHandler;
