import React, { FC, RefObject } from 'react';

import { useEthers } from '@usedapp/core';

import Modal from '@bitcoin-portal/verse-web-components/dist/Modal/Modal';

import { TEST_ACCOUNT } from '../../../../context/constants';

import bcomIcon from '../../../../../static/icons/icon-48x48.png';

import {
  BcomIconStyle,
  BcomIconWrapper,
  ConnectBubble,
  ConnectButton,
} from './styled';

interface Props {
  loginModalRef?: RefObject<Modal>;
  trackEvent?: (wallet: string) => void;
}

const BcomConnectButton: FC<Props> = ({ loginModalRef, trackEvent }) => {
  const { account: ethersAccount, activateBrowserWallet } = useEthers();
  const account = TEST_ACCOUNT || ethersAccount;

  return (
    <ConnectButton
      fullWidth
      design="flat"
      onClick={(e: React.UIEvent<HTMLElement>) => {
        e.stopPropagation();

        activateBrowserWallet({ type: 'bcomwallet' });

        if (!account) {
          trackEvent?.('bitcoin.com wc');
          loginModalRef?.current?.close();
        }
      }}
    >
      <div>
        <BcomIconWrapper>
          <BcomIconStyle src={bcomIcon} alt="Bitcoin.com" />
        </BcomIconWrapper>
        <span>Bitcoin.com</span>
      </div>
      <ConnectBubble>Connect</ConnectBubble>
    </ConnectButton>
  );
};

export default BcomConnectButton;
