import React, { FunctionComponent } from 'react';

import { useEthers } from '@usedapp/core';

import AmountFiat from '@bitcoin-portal/verse-web-components/dist/AmountFiat';

import { findProvider } from '@helpers/index';

import { useGetBalances } from '@hooks/core';

import { useGetLiquidity } from '@views/Pools/hooks/useGetLiquidity';

interface IPairLiquidity {
  pair: ILiquidityPair;
  currency: string;
  exchangeRates: any;
  exchangeProvider: string;
  fallbackRates?: DexRatesType;
}

interface IUserLiquidity {
  pair: ILiquidityPair;
  currency: string;
  exchangeRates: any;
  exchangeProvider: string;
  fallbackRates?: DexRatesType;
  stakedAmount?: number;
}

const LP_PERCENTAGE = 0.0025;

export const UserLiquidityFlat = (
  pair: ILiquidityPair,
  user: string,
  dexRates: any,
  exchangeProvider: string,
): number => {
  const provider = findProvider(exchangeProvider);

  const { readableBalance0, readableBalance1 } = useGetLiquidity(
    pair,
    user,
    provider.supportedChains[0].chainId,
  );

  const token0Rate =
    dexRates?.[exchangeProvider]?.[pair?.token0?.symbol?.toUpperCase()] || 0;
  const token1Rate =
    dexRates?.[exchangeProvider]?.[pair?.token1?.symbol?.toUpperCase()] || 0;

  const totalBalance =
    readableBalance0 * token0Rate + readableBalance1 * token1Rate;

  if (!totalBalance) return 0;
  return totalBalance;
};

export const UserLiquidity: FunctionComponent<IUserLiquidity> = ({
  pair,
  currency,
  exchangeRates,
  exchangeProvider,
  fallbackRates = {},
  stakedAmount = 0,
}) => {
  const { account } = useEthers();
  const hasFallbackRates =
    !!fallbackRates[exchangeProvider]?.[pair.token0?.symbol] &&
    !!fallbackRates[exchangeProvider]?.[pair.token1?.symbol];

  if (hasFallbackRates) {
    const estimatedBalance = UserLiquidityFlat(
      pair,
      account || '',
      fallbackRates,
      exchangeProvider,
    );

    return (
      <>
        <AmountFiat
          amount={estimatedBalance}
          currency={currency}
          locale="en-US"
        />
        {estimatedBalance > 0 && '*'}
      </>
    );
  }

  const totalBalance = UserLiquidityFlat(
    pair,
    account || '',
    exchangeRates,
    exchangeProvider,
  );

  return (
    <AmountFiat
      amount={totalBalance + stakedAmount}
      currency={currency}
      locale="en-US"
    />
  );
};

export const PairLiquidityFlat = (
  pair: ILiquidityPair,
  dexRates: any,
  exchangeProvider: string,
): number => {
  const provider = findProvider(exchangeProvider);

  const { readableBalance0, readableBalance1 } = useGetBalances(
    pair,
    provider.supportedChains[0].chainId,
  );

  const token0Rate =
    dexRates?.[exchangeProvider]?.[pair?.token0?.symbol?.toUpperCase()] || 0;
  const token1Rate =
    dexRates?.[exchangeProvider]?.[pair?.token1?.symbol?.toUpperCase()] || 0;

  const totalBalance =
    readableBalance0 * token0Rate + readableBalance1 * token1Rate;

  if (!totalBalance) return 0;
  return totalBalance;
};

export const PairLiquidity: FunctionComponent<IPairLiquidity> = ({
  pair,
  currency,
  exchangeRates,
  exchangeProvider,
  fallbackRates = {},
}) => {
  const hasFallbackRates =
    !!fallbackRates[exchangeProvider]?.[pair.token0?.symbol] &&
    !!fallbackRates[exchangeProvider]?.[pair.token1?.symbol];

  if (hasFallbackRates) {
    const estimatedBalance = PairLiquidityFlat(
      pair,
      fallbackRates,
      exchangeProvider,
    );

    return (
      <>
        <AmountFiat
          amount={estimatedBalance}
          currency={currency}
          locale="en-US"
        />
        {estimatedBalance > 0 && '*'}
      </>
    );
  }

  const totalBalance = PairLiquidityFlat(pair, exchangeRates, exchangeProvider);

  return (
    <>
      {totalBalance ? (
        <AmountFiat amount={totalBalance} currency={currency} locale="en-US" />
      ) : (
        <div>...</div>
      )}
    </>
  );
};

export const UserPairRewardsFlat = (
  position: ILiquidityPosition,
  lpTokenBalance: number,
): number => {
  if (typeof position === 'undefined') return 0;

  const lastSnapshot = position.snapshots[position.snapshots.length - 1];

  const avgSupply =
    (parseFloat(lastSnapshot?.liquidityTokenTotalSupply) +
      position.totalSupply) /
    2;

  const liquidityProportion = lpTokenBalance / avgSupply;

  return position.volumeSinceFunding * liquidityProportion * LP_PERCENTAGE;
};

export const PairRewardsFlat = (position: ILiquidityPosition): number => {
  if (typeof position === 'undefined') return 0;

  const lastSnapshot = position.snapshots[position.snapshots.length - 1];

  const avgSupply =
    (parseFloat(lastSnapshot?.liquidityTokenTotalSupply) +
      position.totalSupply) /
    2;

  const liquidityProportion = position.liquidityTokenBalance / avgSupply;

  return position.volumeSinceFunding * liquidityProportion * LP_PERCENTAGE;
};

export const NetPositionChangeFlat = (
  positions: ILiquidityPositions,
  farmDeposits: FarmDepositType,
): number => {
  if (Object.values(positions).filter(p => p.reserveUSDAtFunding < 0).length)
    return 0;

  const netChange = Object.keys(positions).reduce(
    (accumulator: number, current: string) => {
      const position = positions[current];
      const snapshots = position?.snapshots;
      const lastSnapshot = snapshots[snapshots.length - 1];
      const stakedBalance = farmDeposits?.[current] || 0;

      const liquidityBalance = parseFloat(lastSnapshot?.liquidityTokenBalance);
      const totalInitialBalance = liquidityBalance + stakedBalance;

      const liquidityTokenTotalSupply = parseFloat(
        lastSnapshot?.liquidityTokenTotalSupply,
      );

      const reserveUSD = position?.reserveUSDAtFunding;

      const reserveInitial =
        (totalInitialBalance / liquidityTokenTotalSupply) * reserveUSD;

      const totalCurrentBalance =
        position?.liquidityTokenBalance + stakedBalance;

      const reserveCurrent =
        (totalCurrentBalance / position?.totalSupply) * position?.reserveUSD;

      const delta = reserveCurrent - reserveInitial;

      return delta + accumulator;
    },
    0,
  );

  return netChange;
};

export const TotalPositionsFlat = (
  positions: ILiquidityPositions,
  fallbackEstimates: FallbackEstimatesType,
): number => {
  const totalInvested = Object.keys(positions).reduce(
    (accumulator: number, current: string) => {
      const position = positions[current];
      const fallbackBalance = fallbackEstimates[position.name];

      if (fallbackBalance) {
        return accumulator + fallbackBalance;
      }

      const liquidityProportion =
        position.liquidityTokenBalance / position.totalSupply;

      const initialUSD = liquidityProportion * position.reserveUSD;

      return accumulator + initialUSD;
    },
    0,
  );

  return totalInvested;
};

export default PairLiquidity;
