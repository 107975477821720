import React, { FunctionComponent, RefObject } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@bitcoin-portal/verse-web-components/dist/Button';
import Refresh from '@bitcoin-portal/verse-web-components/dist/Icons/Refresh';
import Label from '@bitcoin-portal/verse-web-components/dist/Label';
import Link from '@bitcoin-portal/verse-web-components/dist/Link';
import Modal from '@bitcoin-portal/verse-web-components/dist/Modal';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

import Error from '../../../static/images/uploads/error.png';

import { Confirmation } from './styled';

interface Props {
  connectModalRetry: () => void;
  loginErrorModalRef: RefObject<Modal>;
}

const LoginErrorModal: FunctionComponent<Props> = ({
  connectModalRetry,
  loginErrorModalRef,
}) => {
  const tryAgain = () => {
    if (loginErrorModalRef) loginErrorModalRef.current?.close();
    connectModalRetry();
  };

  const { formatMessage } = useIntl();

  return (
    <Confirmation>
      <div>
        <img src={Error} alt="paper airplane" />
      </div>
      <div>
        <FormattedMessage
          id="global.modals.login-error.error-ocurred"
          values={{
            CONTACT_SUPPORT_LINK: (
              <>
                <Link href="mailto:contact@bitcoin.com">
                  {formatMessage({
                    id: 'global.modals.login-error.contact-support',
                  })}
                </Link>
              </>
            ),
          }}
        />
      </div>
      <Button design="clear" onClick={tryAgain}>
        <Refresh size={15} />
        <Label
          style={{
            color: colors.verseBlue,
            paddingLeft: 4,
          }}
        >
          <FormattedMessage id="global.labels.tryAgain" />
        </Label>
      </Button>
    </Confirmation>
  );
};

export default LoginErrorModal;
