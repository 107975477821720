import React, { FC } from 'react';

import { useEthers } from '@usedapp/core';

import walletconnectIcon from '../../../../../static/images/uploads/walletconnect.png';

import { ConnectBubble, ConnectButton } from './styled';

const WalletConnectUniversalButton: FC = () => {
  const { activateBrowserWallet } = useEthers();

  return (
    <ConnectButton
      fullWidth
      design="flat"
      onClick={(e: React.UIEvent<HTMLElement>) => {
        e.stopPropagation();

        activateBrowserWallet({ type: 'wcuniversal' });
      }}
    >
      <div>
        <img src={walletconnectIcon} width="32px" alt="WalletConnect" />
        <span>WalletConnect</span>
      </div>
      <ConnectBubble>Connect</ConnectBubble>
    </ConnectButton>
  );
};

export default WalletConnectUniversalButton;
