import React, {
  Dispatch,
  FunctionComponent,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';

import { useLocation } from '@reach/router';

import Modal from '@bitcoin-portal/verse-web-components/dist/Modal';

import { useTrackedState } from '@context/store';

import {
  getBlockchain,
  getDeviceSize,
  getEntrypoint,
  logAmplitudeEvent,
} from '@helpers/index';

import { useWindowSize } from '@hooks/useWindowSize';

import { Separator } from '../styled';

import CefiLogin from './CefiLogin';
import DefiLogin from './DefiLogin';

interface Props {
  loginModalRef?: RefObject<Modal>;
  checkEmailModalRef?: RefObject<Modal>;
  updateEmail: (email: string) => void;
  email: string;
  setSubmittedEmail: Dispatch<SetStateAction<string>>;
}

const LoginModal: FunctionComponent<Props> = ({
  loginModalRef,
  checkEmailModalRef,
  updateEmail,
  email,
  setSubmittedEmail,
}) => {
  const { pathname } = useLocation();
  const { provider } = useTrackedState();
  const { width } = useWindowSize();

  const hideBcomLogin = useMemo(() => {
    if (!['sideshift', 'changenow'].includes(provider)) return true;

    return (
      pathname.includes('/pools') ||
      pathname.includes('/farms') ||
      pathname.includes('/staking')
    );
  }, []);

  const trackWalletOptionClicked = useCallback(
    (connection: string) => {
      if (typeof window !== 'undefined') {
        logAmplitudeEvent('connectWalletOptionSelected', {
          blockchain: getBlockchain(provider),
          'connection option': connection,
          'current page': window.location,
          entrypoint: getEntrypoint(),
          'screen size': getDeviceSize(width as number),
        });
      }
    },
    [provider, width],
  );

  return (
    <>
      <DefiLogin
        loginModalRef={loginModalRef}
        trackEvent={trackWalletOptionClicked}
      />
      {!hideBcomLogin && (
        <>
          <Separator />
          <CefiLogin
            loginModalRef={loginModalRef}
            checkEmailModalRef={checkEmailModalRef}
            updateEmail={updateEmail}
            email={email}
            setSubmittedEmail={setSubmittedEmail}
            trackEvent={trackWalletOptionClicked}
          />
        </>
      )}
    </>
  );
};

export default LoginModal;
