import React, { FC } from 'react';

import { useDispatch, useTrackedState } from '../../../../context/store';

import { disconnectBcom, shortAddress } from '../../../../helpers';

import bcomIcon from '../../../../../static/images/uploads/bcomconnect.png';

import { ConnectButton, DisconnectBubble, WalletDisplay } from './styled';

const LogoutButton: FC = () => {
  const dispatch = useDispatch();
  const { userDetails } = useTrackedState();

  return (
    <ConnectButton
      fullWidth
      design="primary"
      onClick={(e: React.UIEvent<HTMLElement>) => {
        e.stopPropagation();
        disconnectBcom(dispatch);
      }}
    >
      <div>
        <img src={bcomIcon} width="32px" alt="Bitcoin.com" />
        <WalletDisplay>
          <span>Bitcoin.com</span>
          <span>{shortAddress(userDetails?.email, 12)}</span>
        </WalletDisplay>
      </div>
      <DisconnectBubble>Disconnect</DisconnectBubble>
    </ConnectButton>
  );
};

export default LogoutButton;
