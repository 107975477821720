import { State } from '../store';

export type SelectedFarmAction = {
  type: 'SET_SELECTED_FARM';
  payload: string;
};

export const selectedFarmReducer = (state: State, payload: string): State => ({
  ...state,
  selectedFarmAddress: payload,
});
