// copy pasta from here with modifications
// https://github.com/TrueFiEng/useDApp/tree/master/packages/connectors/wallet-connect-v2
import { Web3Provider } from '@ethersproject/providers';
import { Connector, ConnectorUpdateData } from '@usedapp/core';
import EthereumProvider, {
  EthereumProviderOptions,
} from '@walletconnect/ethereum-provider';
import { SessionTypes } from '@walletconnect/types';
import { providers } from 'ethers';

import { Event } from '@usedapp/core/dist/esm/src/helpers';

export class WalletConnectConnector implements Connector {
  public provider?: Web3Provider;

  public readonly name = 'WalletConnect';

  readonly update = new Event<ConnectorUpdateData>();

  private ethereumProvider?: EthereumProvider;

  public session?: SessionTypes.Struct;

  private readonly opts: EthereumProviderOptions;

  constructor(options: EthereumProviderOptions) {
    this.opts = options;
  }

  private async init() {
    this.ethereumProvider = await EthereumProvider.init({
      ...this.opts,
      showQrModal: true,
      qrModalOptions: {
        ...this.opts.qrModalOptions,
        themeVariables: {
          '--wcm-font-family': 'Barlow, sans-serif',
          '--wcm-background-color': '#0F1823',
          '--wcm-container-border-radius': '25px',
          '--wcm-background-border-radius': '25px',
        },
      },
    });

    /*
    // accounts changed
    this.ethereumProvider.on('accountsChanged', ev => {
      console.log('accountsChanged', ev);
    });
    // chain changed
    this.ethereumProvider.on('chainChanged', ev => {
      console.log('chainChanged', ev);
    });
    // session established
    this.ethereumProvider.on('connect', ev => {
      console.log('connect', ev);
    });
    // session disconnect
    this.ethereumProvider.on('disconnect', ev => {
      console.log('disconnect', ev);
    });
    // connection uri
    this.ethereumProvider.on('display_uri', ev => {
      console.log('display_uri', ev);
    });

    // message event
    this.ethereumProvider.on('message', ev => {
      console.log('message', ev);
    });

    // session events
    this.ethereumProvider.on('session_event', ev => {
      console.log('session_event', ev);
    });
    this.ethereumProvider.on('session_update', ev => {
      console.log('session_update', ev);
    });
    this.ethereumProvider.on('session_delete', ev => {
      console.log('session_delete', ev);
    });
     */
  }

  async connectEagerly(): Promise<void> {
    try {
      await this.init();

      if (!this.ethereumProvider) {
        throw new Error('Could not initialize connector');
      }

      const accounts = (await this.ethereumProvider.request({
        method: 'eth_accounts',
      })) as string[];

      const chainId = (await this.ethereumProvider.request({
        method: 'eth_chainId',
      })) as any;

      this.createProvider();

      this.update.emit({
        chainId: parseInt(chainId, 10),
        accounts,
      });
    } catch (e) {
      console.debug(e);
    }
  }

  private createProvider() {
    if (!this.ethereumProvider) {
      throw new Error('No WalletConnect EthereumProvider');
    }

    this.session = this.ethereumProvider.session;

    this.provider = new providers.Web3Provider(this.ethereumProvider);

    if (!this.provider) {
      throw new Error('No Provider');
    }
  }

  async activate(): Promise<void> {
    try {
      await this.init();
      if (!this.ethereumProvider) {
        throw new Error('Could not initialize connector');
      }

      if (!this.ethereumProvider.isWalletConnect) {
        throw new Error('not connected');
      }

      await this.ethereumProvider.connect({
        chains: this.opts.chains,
      });

      const accounts = (await this.ethereumProvider.request({
        method: 'eth_accounts',
      })) as string[];

      const chainId = (await this.ethereumProvider.request({
        method: 'eth_chainId',
      })) as any;

      this.createProvider();

      this.update.emit({
        chainId: parseInt(chainId, 10),
        accounts,
      });
    } catch (e: any) {
      console.log(e);
      throw new Error(`Could not activate connector: ${e.message ?? ''}`);
    }
  }

  async deactivate(): Promise<void> {
    this.ethereumProvider?.disconnect();
    this.provider = undefined;
  }
}
