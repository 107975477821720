/* eslint-disable max-lines */
import iconList from './icons';

export const DEXTokens: ITickerObject[] = [
  {
    abbr: 'ETH',
    value: 'ETH',
    blockchain: 'ETH',
    protocol: 'ETH_PROTOCOL',
    label: 'Ethereum',
    ticker: 'eth',
    token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2_ETH',
    explorer: 'https://etherscan.io/tx/',
    decimals: 18,
  },
  {
    abbr: 'DAI',
    value: 'DAI',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'DAI Token',
    ticker: 'dai',
    explorer: 'https://etherscan.io/tx/',
    token: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
  },
  {
    abbr: 'USDC',
    value: 'USDC',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'USDC Token',
    ticker: 'usdc',
    explorer: 'https://etherscan.io/tx/',
    token: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
  },
  {
    abbr: 'COMP',
    value: 'COMP',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Compound Token',
    ticker: 'comp',
    explorer: 'https://etherscan.io/tx/',
    token: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
    decimals: 18,
  },
  {
    abbr: 'BAND',
    value: 'BAND',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Band Protocol',
    ticker: 'band',
    explorer: 'https://etherscan.io/tx/',
    token: '0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55',
    decimals: 18,
  },
  {
    abbr: 'MANA',
    value: 'MANA',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Decentraland Token',
    ticker: 'mana',
    explorer: 'https://etherscan.io/tx/',
    token: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
    decimals: 18,
  },
  {
    abbr: 'UNI',
    value: 'UNI',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Uniswap Token',
    ticker: 'uni',
    explorer: 'https://etherscan.io/tx/',
    token: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    decimals: 18,
  },
  {
    abbr: 'USDT',
    value: 'USDT',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'USDT Token',
    ticker: 'usdtErc20',
    explorer: 'https://etherscan.io/tx/',
    token: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
  },
  {
    abbr: 'ZRX',
    value: 'ZRX',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: '0x Token',
    ticker: 'zrx',
    explorer: 'https://etherscan.io/tx/',
    token: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
    decimals: 18,
  },
];

export const DEXTokensSushi = [
  {
    abbr: 'ETH',
    value: 'ETH',
    blockchain: 'ETH',
    protocol: 'ETH_PROTOCOL',
    label: 'Ethereum',
    ticker: 'eth',
    explorer: 'https://etherscan.io/tx/',
    token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2_ETH',
    decimals: 18,
  },
  {
    abbr: 'DAI',
    value: 'DAI',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'DAI Token',
    ticker: 'dai',
    explorer: 'https://etherscan.io/tx/',
    token: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
  },
  {
    abbr: 'USDC',
    value: 'USDC',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'USDC Token',
    ticker: 'usdc',
    explorer: 'https://etherscan.io/tx/',
    token: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
  },
  {
    abbr: 'xSUSHI',
    value: 'xSUSHI',
    protocol: 'ERC_20_PROTOCOL',
    blockchain: 'ETH',
    label: 'SushiBar Token',
    ticker: 'xsushi',
    explorer: 'https://etherscan.io/tx/',
    token: '0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272',
    decimals: 18,
    icon: 'https://github.com/bitcoin-portal/cryptocurrency-icons/blob/master/32@2x/icon/sushi@2x.png?raw=true',
  },
];

export const DEXTokensPancake = [
  {
    abbr: 'BNB',
    value: 'BNB',
    blockchain: 'BNB',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Binance Coin',
    ticker: 'bnb',
    explorer: 'https://bscscan.com/tx/',
  },
  {
    abbr: 'DAI',
    value: 'DAI',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'DAI Token',
    ticker: 'dai',
    explorer: 'https://etherscan.io/tx/',
    token: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
  },
  {
    abbr: 'USDC',
    value: 'USDC',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'USDC Token',
    ticker: 'usdc',
    explorer: 'https://etherscan.io/tx/',
    token: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
  },
];

export const DEXTokensSepolia = [
  {
    abbr: 'ETH',
    value: 'ETH',
    blockchain: 'ETH',
    protocol: 'ETH_PROTOCOL',
    label: 'Ethereum',
    ticker: 'eth',
    explorer: 'https://etherscan.io/tx/',
    token: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
    decimals: 18,
  },
  {
    abbr: 'DAI',
    value: 'DAI',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'DAI Token',
    ticker: 'dai',
    explorer: 'https://etherscan.io/tx/',
    token: '0x3e622317f8C93f7328350cF0B56d9eD4C620C5d6',
    decimals: 18,
  },
];

export const DEXTokensHolesky = [
  {
    abbr: 'ETH',
    value: 'ETH',
    blockchain: 'ETH',
    protocol: 'ETH_PROTOCOL',
    label: 'Ethereum',
    ticker: 'eth',
    explorer: 'https://holesky.etherscan.io/tx/',
    token: '0x7BF1A93194376c10b2C8d434D87CCCF2c5d2ED01',
    decimals: 18,
  },
  {
    abbr: 'DAI',
    value: 'DAI',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'DAI Token',
    ticker: 'dai',
    explorer: 'https://holesky.etherscan.io/tx/',
    token: '0x86Db6AD79793434627293c0B7801be04Aa783030',
    decimals: 18,
  },
];

export const DEXTokensPolygon = [
  {
    abbr: 'MATIC',
    value: 'MATIC',
    blockchain: 'MATIC',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Matic Coin',
    ticker: 'matic',
    explorer: 'https://polygonscan.com/tx/',
    token: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270_ETH',
    decimals: 18,
    icon: 'https://raw.githubusercontent.com/bitcoin-portal/cryptocurrency-icons/master/32%402x/icon/matic%402x.png',
  },
  {
    abbr: 'ICE',
    value: 'ICE',
    protocol: 'ERC_20_PROTOCOL',
    blockchain: 'MATIC',
    label: 'ICE Token',
    ticker: 'ice',
    explorer: 'https://polygonscan.com/tx/',
    token: '0xc6c855ad634dcdad23e64da71ba85b8c51e5ad7c',
    icon: 'https://assets.coingecko.com/coins/images/18110/small/ice-poker.png?1630500966',
    decimals: 18,
  },
  {
    abbr: 'USDC',
    value: 'USDC',
    protocol: 'ERC_20_PROTOCOL',
    blockchain: 'MATIC',
    label: 'USDC Token',
    ticker: 'usdc',
    explorer: 'https://polygonscan.com/tx/',
    token: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    decimals: 6,
  },
];

export const DEXTokensBinance = [
  {
    abbr: 'BNB',
    value: 'BNB',
    blockchain: 'BNB',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Binance Coin',
    ticker: 'bnb',
    explorer: 'https://bscscan.com/tx/',
    token: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_ETH',
    decimals: 18,
    icon: 'https://raw.githubusercontent.com/bitcoin-portal/cryptocurrency-icons/master/32%402x/icon/bnb%402x.png',
  },
  {
    abbr: 'DAI',
    value: 'DAI',
    protocol: 'ERC_20_PROTOCOL',
    blockchain: 'BNB',
    label: 'DAI Token',
    ticker: 'dai',
    explorer: 'https://bscscan.com/tx/',
    token: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    decimals: 18,
  },
];

export const DEXTokensSBCH = [
  {
    abbr: 'BCH',
    value: 'BCH',
    blockchain: 'BCH',
    protocol: 'SEP20_PROTOCOL',
    label: 'Bitcoin Cash',
    ticker: 'bch',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04_ETH',
    decimals: 18,
    icon: 'https://raw.githubusercontent.com/bitcoin-portal/cryptocurrency-icons/master/32%402x/icon/bch%402x.png',
  },
  {
    abbr: 'flexUSD',
    value: 'flexUSD',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'flexUSD',
    ticker: 'flexusd',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x7b2B3C5308ab5b2a1d9a94d20D35CCDf61e05b72',
    decimals: 18,
    icon: 'https://raw.githubusercontent.com/mistswapdex/assets/master/blockchains/smartbch/assets/0x7b2B3C5308ab5b2a1d9a94d20D35CCDf61e05b72/logo.png',
  },
  {
    abbr: 'FLEX',
    value: 'FLEX',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'FLEX',
    ticker: 'flex',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x98Dd7eC28FB43b3C4c770AE532417015fa939Dd3',
    icon: 'https://tokens.bch.sx/64/fb1813fd1a53c1bed61f15c0479cc5315501e6da6a4d06da9d8122c1a4fabb6c.png',
    decimals: 18,
  },
  {
    abbr: 'JOY',
    value: 'JOY',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'JOY',
    ticker: 'joy',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x6732E55Ac3ECa734F54C26Bd8DF4eED52Fb79a6E',
    icon: 'https://tokens.bch.sx/64/2624df798d76986231c7acb0f6923f537223da44ba6e25171186ab4056a58b64.png',
    decimals: 18,
  },
  {
    abbr: 'EBEN',
    value: 'EBEN',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'Green Ben',
    ticker: 'eben',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B',
    icon: iconList.eben,
    decimals: 18,
  },
  {
    abbr: 'TANGO',
    value: 'TANGO',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'TANGO',
    ticker: 'tango',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x73BE9c8Edf5e951c9a0762EA2b1DE8c8F38B5e91',
    icon: iconList.tango,
    decimals: 18,
  },
  {
    abbr: 'LAW',
    value: 'LAW',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'LAW',
    ticker: 'law',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x0b00366fBF7037E9d75E4A569ab27dAB84759302',
    icon: iconList.law,
    decimals: 18,
  },
  {
    abbr: 'MIST',
    value: 'MIST',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'MIST Token',
    ticker: 'mist',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x5fA664f69c2A4A3ec94FaC3cBf7049BD9CA73129',
    icon: iconList.mist,
    decimals: 18,
  },
  {
    abbr: 'LNS',
    value: 'LNS',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'LNS Token',
    ticker: 'lns',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x35b3Ee79E1A7775cE0c11Bd8cd416630E07B0d6f',
    icon: iconList.lns,
    decimals: 18,
  },
  {
    abbr: 'POTA',
    value: 'POTA',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'POTA Token',
    ticker: 'pota',
    explorer: 'https://smartscout.cash/tx/',
    token: '0xB5b1939ef0a3743d0Ae9282DbA62312b614A5Ac0',
    icon: iconList.pota,
    decimals: 18,
  },
  {
    abbr: 'GOC',
    value: 'GOC',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'GOC Token',
    ticker: 'goc',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x4b85a666dec7c959e88b97814e46113601b07e57',
    icon: iconList.goc,
    decimals: 18,
  },
  {
    abbr: 'RMZ',
    value: 'RMZ',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'RMZ Token',
    ticker: 'rmz',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x49F9ECF126B6dDF51C731614755508A4674bA7eD',
    icon: iconList.rmz,
    decimals: 18,
  },
  {
    abbr: 'CATS',
    value: 'CATS',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'CashCats',
    ticker: 'cats',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x265bD28d79400D55a1665707Fa14A72978FA6043',
    icon: iconList.cats,
    decimals: 2,
  },
  {
    abbr: 'EMBER',
    value: 'EMBER',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'Ember',
    ticker: 'ember',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x6BAbf5277849265b6738e75AEC43AEfdde0Ce88D',
    icon: iconList.ember,
    decimals: 18,
  },
  {
    abbr: 'FIRE',
    value: 'FIRE',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'Incinerate',
    ticker: 'fire',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x225FCa2A940cd5B18DFb168cD9B7f921C63d7B6E',
    icon: iconList.fire,
    decimals: 18,
  },
  {
    abbr: '1BCH',
    value: '1BCH',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: '1BCH',
    ticker: '1bch',
    explorer: 'https://smartscout.cash/tx/',
    token: '0x77d4b6e44a53bbda9a1d156b32bb53a2d099e53d',
    icon: iconList.onebch,
    decimals: 18,
  },
  {
    abbr: 'rBCH',
    value: 'rBCH',
    protocol: 'SEP20_PROTOCOL',
    blockchain: 'BCH',
    label: 'rBCH',
    ticker: 'rbch',
    explorer: 'https://smartscout.cash/tx/',
    token: '0xb4602588e5f1f9653b6f234206c91552e457facb',
    icon: iconList.rbch,
    decimals: 18,
  },
];

export const DEXTokensVerse = [
  {
    abbr: 'VERSE',
    value: 'VERSE',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Verse',
    ticker: 'verse',
    token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    explorer: 'https://etherscan.io/tx/',
    decimals: 18,
    icon: 'https://markets.bitcoin.com/images/coins/verse.png',
  },
  {
    abbr: 'ETH',
    value: 'ETH',
    blockchain: 'ETH',
    protocol: 'ETH_PROTOCOL',
    label: 'Ethereum',
    ticker: 'eth',
    token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2_ETH',
    icon: 'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/128/Ethereum-ETH-icon.png',
    explorer: 'https://etherscan.io/tx/',
    decimals: 18,
  },
  {
    abbr: 'DAI',
    value: 'DAI',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'DAI Token',
    ticker: 'dai',
    explorer: 'https://etherscan.io/tx/',
    token: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
  },
  {
    abbr: 'USDC',
    value: 'USDC',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'USDC Token',
    ticker: 'usdc',
    explorer: 'https://etherscan.io/tx/',
    token: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
  },
  {
    abbr: 'WBTC',
    value: 'WBTC',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Wrapped BTC',
    ticker: 'wbtc',
    explorer: 'https://etherscan.io/tx/',
    token: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    decimals: 8,
  },
  {
    abbr: 'WETH',
    value: 'WETH',
    blockchain: 'WETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'Ethereum',
    ticker: 'eth',
    token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    explorer: 'https://etherscan.io/tx/',
    decimals: 18,
  },
  {
    abbr: 'USDT',
    value: 'USDT',
    blockchain: 'ETH',
    protocol: 'ERC_20_PROTOCOL',
    label: 'USDT Token',
    ticker: 'usdt',
    explorer: 'https://etherscan.io/tx/',
    token: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
  },
];

/* export const DEXTokensSBCH = [
    {
        abbr: 'BCH',
        value: 'BCH',
        blockchain: 'BCH',
        protocol: 'ERC_20_PROTOCOL',
        label: 'BCH Coin',
        ticker: 'bch',
        explorer: 'https://smartscout.cash/tx/',
        token: '0x17F4FCF5b6E0A95D4eE331c8529041896A073F9b_ETH',
        decimals: 18,
        icon: 'https://raw.githubusercontent.com/bitcoin-portal/cryptocurrency-icons/master/32%402x/icon/bch%402x.png',
    },
    {
        abbr: 'MIST',
        value: 'MIST',
        protocol: 'ERC_20_PROTOCOL',
        blockchain: 'BCH',
        label: 'MIST Token',
        ticker: 'mist',
        explorer: 'https://smartscout.cash/tx/',
        token: '0x3d2cd929D1fC1dA68D2557AB85336C2740b1Ae81',
        icon: 'https://github.com/bitcoin-portal/cryptocurrency-icons/blob/master/32@2x/icon/mith@2x.png?raw=true',
        decimals: 18,
    },
]; */
