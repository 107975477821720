import { Dispatch, RefObject, SetStateAction } from 'react';

import Modal from '@bitcoin-portal/verse-web-components/dist/Modal/Modal';

// APIS
import { getAddress } from '@context/api/nekosdk';
import { WalletType } from '@context/types';

// needs grooming
export const walletTypeGuard = (value: unknown): value is WalletType => {
  if ((value as WalletType).assets) {
    return true;
  }
  return false;
};

// needs grooming
export const setWallet = async (
  wallet: WalletType | string,
  close: boolean,
  protocol: string,
  isRefundAddress: boolean,
  walletSelectModalRef: RefObject<Modal>,
  setSelectedWallet: Dispatch<SetStateAction<WalletType>>,
  setRefundAddress: Dispatch<SetStateAction<string>>,
  setToAddress: Dispatch<SetStateAction<string>>,
): Promise<void> => {
  if (!wallet) return;
  let address = '';

  if (walletTypeGuard(wallet)) {
    setSelectedWallet(wallet);
  }
  // include alternative to find wallet of WalletType if received wallet parameter is string

  if (typeof wallet === 'string') {
    address = wallet;
  } else {
    address = await getAddress(wallet.walletId, protocol);
  }

  if (isRefundAddress) {
    setRefundAddress(address);
  } else {
    setToAddress(address);
  }

  if (close) walletSelectModalRef?.current?.close();
};

export const getColor = (color: string, currency: string): string => {
  if (currency === 'BTC') return '#F19E0F';
  if (currency === 'ETH') return '#627CEA';
  return color;
};

export const sortWalletsByName = (array: WalletType[]): WalletType[] => {
  return array?.sort((w1: WalletType, w2: WalletType) => {
    if (w1.name.toLowerCase() < w2.name.toLowerCase()) return -1;
    if (w1.name.toLowerCase() > w2.name.toLowerCase()) return 1;
    return 0;
  });
};

export const sortWalletsByBalance = (
  array: WalletType[],
  targetAsset: string,
): WalletType[] => {
  return array?.sort((w1: WalletType, w2: WalletType) => {
    const asset1 = w1.assets.find(
      w => w.ticker.toLowerCase() === targetAsset.toLowerCase(),
    );
    const asset2 = w2.assets.find(
      w => w.ticker.toLowerCase() === targetAsset.toLowerCase(),
    );

    const bal1 = asset1?.balance || 0;
    const bal2 = asset2?.balance || 0;

    if (bal1 < bal2) return 1;
    if (bal1 > bal2) return -1;
    return 0;
  });
};
