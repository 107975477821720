import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { useDexTokens } from '@context/providers/DexTokensProvider';

import {
  DEFAULT_SWAP_FROM,
  DEFAULT_SWAP_TO,
  VERSE_TOKENS,
} from '@views/Swap/context/constants';
import { useSwapContext } from '@views/Swap/context/providers/SwapProvider';

import { useQuery } from '@helpers/index';

import { useTrackedState } from '../store';

// FUNCTIONS:
// updates selected token based on query params
// sets default tokens
export const DefiCurrencyHandler: FunctionComponent = () => {
  const query = useQuery();

  const { provider: exchangeProvider } = useTrackedState();

  const dexTokens = useDexTokens();

  const {
    swapFromBlockchain,
    swapToBlockchain,
    swapFromCurrency,
    swapToCurrency,
    swapFromTokenAddress,
    swapToTokenAddress,
    setSwapFromCurrency,
    setSwapFromProtocol,
    setSwapFromBlockchain,
    setSwapFromTokenAddress,
    setSwapToCurrency,
    setSwapToProtocol,
    setSwapToBlockchain,
    setSwapToTokenAddress,
  } = useSwapContext();

  const [usedQuery, setUsedQuery] = useState(false);

  // queries
  const queryFromToken = query.get('from')?.toUpperCase() || '';
  const queryToToken = query.get('to')?.toUpperCase() || '';

  const dexTokensIn = dexTokens;
  const dexTokensOut = dexTokens;

  // default tokens
  const defaultFromToken = useMemo(
    () => DEFAULT_SWAP_FROM[exchangeProvider],
    [exchangeProvider],
  );
  const defaultToToken = useMemo(
    () => DEFAULT_SWAP_TO[exchangeProvider],
    [exchangeProvider],
  );

  useEffect(() => {
    const fromTokenOverride = usedQuery ? '' : queryFromToken;
    const toTokenOverride = usedQuery ? '' : queryToToken;

    const selectedFromToken = dexTokensIn.find(t => {
      if (fromTokenOverride) {
        if (fromTokenOverride === 'VERSE') {
          return (
            t.abbr === fromTokenOverride &&
            Object.keys(VERSE_TOKENS).includes(t?.token ?? '')
          );
        }
        return t.abbr.toLowerCase() === fromTokenOverride.toLowerCase();
      }

      if (
        swapFromTokenAddress === defaultFromToken.token ||
        !swapFromTokenAddress
      ) {
        return t.abbr === swapFromCurrency;
      }

      return t.abbr === swapFromCurrency && t?.token === swapFromTokenAddress;
    });

    let selectedToToken = dexTokensOut.find(t => {
      if (toTokenOverride) {
        if (toTokenOverride === 'VERSE') {
          return (
            t.abbr === toTokenOverride &&
            Object.keys(VERSE_TOKENS).includes(t?.token ?? '')
          );
        }
        return t.abbr.toLowerCase() === toTokenOverride.toLowerCase();
      }
      if (!swapToTokenAddress) {
        return t.abbr === swapToCurrency;
      }

      return t.abbr === swapToCurrency && t?.token === swapToTokenAddress;
    });

    if (
      selectedFromToken !== undefined &&
      selectedToToken !== undefined &&
      selectedFromToken.abbr === selectedToToken.abbr &&
      selectedFromToken.token === selectedToToken.token &&
      selectedFromToken.blockchain === selectedToToken.blockchain
    ) {
      selectedToToken = dexTokensOut.find(
        t =>
          t.abbr !== selectedFromToken?.abbr &&
          t?.token !== selectedFromToken?.token &&
          t.blockchain !== selectedFromToken?.blockchain,
      );
    }

    if (queryFromToken || queryToToken) {
      setUsedQuery(true);
    }

    const fromBlockchain =
      selectedFromToken?.blockchain || defaultFromToken.blockchain;
    const toBlockchain =
      selectedToToken?.blockchain || defaultToToken.blockchain;

    // update context
    setSwapFromCurrency(selectedFromToken?.abbr || defaultFromToken.abbr);
    setSwapFromProtocol(
      selectedFromToken?.protocol || defaultFromToken.protocol,
    );
    setSwapFromBlockchain(
      fromBlockchain.includes('_BLOCKCHAIN')
        ? fromBlockchain
        : `${fromBlockchain}_BLOCKCHAIN`,
    );
    setSwapFromTokenAddress(
      selectedFromToken?.token || defaultFromToken.token || '',
    );
    setSwapToCurrency(selectedToToken?.abbr || defaultToToken.abbr);
    setSwapToProtocol(selectedToToken?.protocol || defaultToToken.protocol);
    setSwapToBlockchain(
      toBlockchain.includes('_BLOCKCHAIN')
        ? toBlockchain
        : `${toBlockchain}_BLOCKCHAIN`,
    );
    setSwapToTokenAddress(selectedToToken?.token || defaultToToken.token || '');
  }, [
    defaultFromToken.abbr,
    defaultToToken.abbr,
    dexTokensIn[0],
    dexTokensOut[0],
    exchangeProvider,
    queryFromToken,
    queryToToken,
    swapFromBlockchain,
    swapToBlockchain,
    swapFromCurrency,
    swapToCurrency,
    swapFromTokenAddress,
    swapToTokenAddress,
    usedQuery,
  ]);

  return <></>;
};

export default DefiCurrencyHandler;
