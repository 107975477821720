import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { OriginHandler } from '../../context/handlers';

import ConnectErrorModal from './ConnectErrorModal';
import Footer from './Footer';
import Header from './Header';
import Nav from './Nav';
import { ContentSection, DashboardWrapper } from './styled';

interface Props {
  page: string;
  children: ReactNode;
}

const Dashboard: FunctionComponent<Props> = ({ children, page }) => {
  const [standalone, setStandalone] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isWebAppiOS =
        'standalone' in window.navigator &&
        window.navigator.standalone === true;
      const isWebAppChrome = window.matchMedia(
        '(display-mode: standalone)',
      ).matches;
      const isStandalone = isWebAppiOS || isWebAppChrome;
      setStandalone(isStandalone);
    }
  }, []);

  return (
    <DashboardWrapper standalone={standalone}>
      <OriginHandler page={page} />

      <Header />
      <ConnectErrorModal />
      <Nav page={page} standalone={standalone} />
      <ContentSection>{children}</ContentSection>
      <Footer />
    </DashboardWrapper>
  );
};

export default Dashboard;
