import { State } from '../store';

export type SelectedLiquidityPairAction = {
  type: 'SET_SELECTED_LIQUIDITY_PAIR';
  payload: ILiquidityPair;
};

export const selectedLiquidityPairReducer = (
  state: State,
  payload: ILiquidityPair,
): State => ({
  ...state,
  selectedLiquidityPair: {
    ...state.selectedLiquidityPair,
    ...payload,
  },
});
