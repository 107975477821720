import React, { FunctionComponent, useEffect } from 'react';

import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { useEthers } from '@usedapp/core';

interface Props {
  setSignerInstance: (signer: JsonRpcSigner) => void;
}

export const SignerInstanceHandler: FunctionComponent<Props> = ({
  setSignerInstance,
}) => {
  const { account, library } = useEthers();

  // set signer instance
  useEffect(() => {
    if (!account || !library) return;
    const signer = (library as JsonRpcProvider).getSigner(account);

    setSignerInstance(signer);
  }, [account, library]);

  return <></>;
};

export default SignerInstanceHandler;
