import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import Resolution from '@unstoppabledomains/resolution';
import { useEthers } from '@usedapp/core';

import { RPC_URL_ETHEREUM, RPC_URL_POLYGON } from '@context/constants';

const EnsContext = createContext<string | undefined>(undefined);

const resolution = new Resolution({
  sourceConfig: {
    uns: {
      locations: {
        Layer1: {
          url: RPC_URL_ETHEREUM,
          network: 'mainnet',
        },
        Layer2: {
          url: RPC_URL_POLYGON,
          network: 'polygon-mainnet',
        },
      },
    },
    ens: {
      url: RPC_URL_ETHEREUM,
      network: 'mainnet',
    },
  },
});

const EnsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [ens, setEns] = useState<string>();
  const { account } = useEthers();

  useEffect(() => {
    const getEnsName = async () => {
      if (!account) return;

      try {
        const name = await resolution.reverse(account);
        setEns(name || undefined);
      } catch (e) {
        console.log('Error fetching ENS domain: ', e);
      }
    };

    getEnsName();
  }, [account]);

  return <EnsContext.Provider value={ens}>{children}</EnsContext.Provider>;
};

export const useEns = () => useContext(EnsContext);

export default EnsProvider;
