import { Contract } from '@ethersproject/contracts';
import { Call, Falsy, useCall, useEtherBalance } from '@usedapp/core';
import { TypedContract } from '@usedapp/core/dist/esm/src/model';
import { BigNumber, BigNumberish, ethers } from 'ethers';

import {
  DEFAULT_BIGNUM,
  DEFAULT_CHAIN,
  DEFAULT_DECIMALS,
  DEFAULT_VALUE,
} from '@context/constants';

import { formatValue } from '@helpers/formatValue';
import { ERC20Interface } from '@helpers/index';

export const useNativeBalance = (holderAddress: string): string => {
  const etherBalance = useEtherBalance(holderAddress);

  if (etherBalance) {
    return formatValue(etherBalance, DEFAULT_DECIMALS);
  }

  return DEFAULT_VALUE;
};

export const useCustomBalance = (
  tokenAddress: string,
  holderAddress: string,
  chainId: number,
): BigNumber => {
  const haveParams = holderAddress && tokenAddress;
  const { value, error } =
    useCall(
      haveParams &&
        ({
          contract: new Contract(tokenAddress, ERC20Interface),
          method: 'balanceOf',
          args: [holderAddress],
        } as unknown as Falsy | Call<TypedContract, string>),
      {
        chainId: chainId || DEFAULT_CHAIN,
      },
    ) ?? {};

  if (error) {
    console.error(error.message);
    return DEFAULT_BIGNUM;
  }

  return value?.[0];
};

export const useFormatBalance = (
  holderAddress: string,
  tokenAddress: string,
  decimals: number,
  chainId: number,
): string => {
  const balance = useCustomBalance(tokenAddress, holderAddress, chainId);

  if (balance) {
    return ethers.utils.formatUnits(balance, decimals);
  }

  return DEFAULT_VALUE;
};

export const useCustomAllowance = (
  tokenAddress: string,
  holderAddress: string,
  spenderAddress: string,
  chainId: number,
): BigNumber => {
  const haveParams = holderAddress && tokenAddress && spenderAddress;
  const { value, error } =
    useCall(
      haveParams &&
        ({
          contract: new Contract(tokenAddress, ERC20Interface),
          method: 'allowance',
          args: [holderAddress, spenderAddress],
        } as unknown as Falsy | Call<TypedContract, string>),
      {
        chainId: chainId || DEFAULT_CHAIN,
      },
    ) ?? {};

  if (error) {
    console.error(error.message);
    return DEFAULT_BIGNUM;
  }

  return value?.[0];
};

// TODO: only used in useGasEstimation/useTransaction hooks, may remove if we decide not to remove layer of abstraction
export const useGetAllowances = (
  pair: ILiquidityPair,
  holderAddress: string,
  routerAddress: string,
  chainId: number,
): {
  readableAllowance0: BigNumberish;
  readableAllowance1: BigNumberish;
} => {
  const token0Allowance = useCustomAllowance(
    pair?.token0?.id,
    holderAddress,
    routerAddress,
    chainId,
  );

  const token1Allowance = useCustomAllowance(
    pair?.token1?.id,
    holderAddress,
    routerAddress,
    chainId,
  );

  return {
    readableAllowance0: token0Allowance || 0,
    readableAllowance1: token1Allowance || 0,
  };
};

export const useGetBalances = (
  pair: ILiquidityPair,
  chainId: number,
): {
  readableBalance0: number;
  readableBalance1: number;
} => {
  const token0Balance = useCustomBalance(pair.token0?.id, pair.id, chainId);

  const token1Balance = useCustomBalance(pair.token1?.id, pair.id, chainId);

  const readableBalance0 = token0Balance
    ? parseFloat(
        formatValue(token0Balance, parseInt(pair.token0?.decimals, 10)),
      )
    : 0;

  const readableBalance1 = token1Balance
    ? parseFloat(
        formatValue(token1Balance, parseInt(pair.token1?.decimals, 10)),
      )
    : 0;

  return {
    readableBalance0,
    readableBalance1,
  };
};
