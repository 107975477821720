import { State } from '../store';

export type LoginFromAction = {
  type: 'SET_LOGIN_FROM';
  payload?: string;
};

export const loginFromReducer = (state: State, payload?: string): State => ({
  ...state,
  loginFrom: payload,
});
