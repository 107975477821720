import React, { FunctionComponent, useEffect, useMemo } from 'react';

import { navigate, useLocation, useParams } from '@reach/router';

import { useLiquidityPairs } from '@context/providers/LiquidityPairsProvider';

import { findProvider } from '@helpers/index';

import { getFarmForPath } from '@views/Farms/helpers/getFarmForPath';
import { getFarmListForProvider } from '@views/Farms/helpers/getFarmListForProvider';
import { checkCorrectPairs } from '@views/Pools/helpers/checkCorrectPairs';
import { getMatchingPair } from '@views/Pools/helpers/getMatchingPair';
import { getPairForPath } from '@views/Pools/helpers/getPairForPath';
import { getStakingFarmForPath } from '@views/StakingV2/helpers/getStakingFarmForPath';

import { ETH_CHAIN_NETWORKS } from '../constants';
import { useDispatch, useTrackedState } from '../store';

interface Props {
  page?: string;
}

export const PairValidationHandler: FunctionComponent<Props> = ({
  page = 'pools',
}) => {
  const { pathname } = useLocation();
  const isEarnBitcoinPage = pathname.includes('earn-bitcoin');

  const params = useParams();
  const chain = params?.chain || 'eth';
  let pair = params?.pair || '';

  if (isEarnBitcoinPage) {
    pair = 'verse-btc';
  }

  const dispatch = useDispatch();

  const { provider: exchangeProvider } = useTrackedState();
  const { liquidityPairs } = useLiquidityPairs();

  const provider = useMemo(
    () => findProvider(exchangeProvider),
    [exchangeProvider],
  );

  const correctPairs = useMemo(
    () => checkCorrectPairs(chain, liquidityPairs),
    [chain, liquidityPairs],
  );

  useEffect(() => {
    if (page !== 'pools') return;
    if (pair?.match(/^[a-z]+-[a-z]+$/i)) {
      const foundPair = getPairForPath(pair, liquidityPairs || []);
      const { chainParam } = provider || {
        chainParam: 'eth',
      };

      if (foundPair) {
        dispatch({
          type: 'SET_SELECTED_LIQUIDITY_PAIR',
          payload: foundPair,
        });
      } else if (correctPairs) {
        navigate(`/pools/${chainParam}/`);
      }
    } else {
      navigate(`/pools/eth/`);
    }
  }, [provider, liquidityPairs, correctPairs]);

  useEffect(() => {
    const { chainParam } = provider || {
      chainParam: 'eth',
    };

    if (page !== 'farms' || chainParam !== chain) return;
    if (
      pair?.match(/^[a-z]+-[a-z]+$/i) ||
      pair?.match(/^[a-z]+-[a-z]+-[a-z]+$/i)
    ) {
      const farmPairs = getFarmListForProvider(exchangeProvider);
      const foundPair = getFarmForPath(pair, farmPairs);

      const lp = getMatchingPair(
        foundPair?.pair.token0.symbol || '',
        foundPair?.pair.token1.symbol || '',
        liquidityPairs || [],
      );

      if (!ETH_CHAIN_NETWORKS.includes(provider.name)) {
        navigate(`/farms/${chain}/`);
      }

      if (foundPair && lp) {
        dispatch({
          type: 'SET_SELECTED_FARM',
          payload: foundPair.contractAddress,
        });
        dispatch({
          type: 'SET_SELECTED_LIQUIDITY_PAIR',
          payload: lp,
        });
      } else if (correctPairs) {
        navigate(`/farms/${chainParam}/`);
      }
    } else {
      navigate(`/farms/eth/`);
    }
  }, [chain, provider, liquidityPairs, correctPairs]);

  useEffect(() => {
    const { chainParam } = provider || {
      chainParam: 'eth',
    };

    if (page !== 'staking' || chainParam !== chain) return;

    const farmPairs = getFarmListForProvider(exchangeProvider);
    const stakingFarm = getStakingFarmForPath(pair, farmPairs);

    if (stakingFarm) {
      dispatch({
        type: 'SET_SELECTED_FARM',
        payload: stakingFarm?.contractAddress,
      });
    }
  }, [chain, pair, provider, correctPairs]);

  return <></>;
};

export default PairValidationHandler;
