export const getStakingFarmForPath = (
  path: string,
  farmList: IFarmPair[],
): IFarmPair | undefined => {
  const matchingFarm = farmList.find(farm => {
    if (farm.customPath) {
      return farm.customPath === path;
    }

    return farm.stakingToken?.abbr?.toLowerCase() === path?.toLowerCase();
  });

  return matchingFarm;
};
