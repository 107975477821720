import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useEthers } from '@usedapp/core';

import { VerseSaleController } from '@bitcoin-portal/neko-web-sdk';

type TVerseClaimMerkleContext = {
  index: number;
  locked: string;
  opened: string;
  timeFrame: string;
  proof: string[];
};

const VerseClaimMerkleContext = createContext<
  TVerseClaimMerkleContext | undefined
>(undefined);

const VerseClaimMerkleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [merkle, setMerkle] = useState<TVerseClaimMerkleContext>();
  const { account } = useEthers();

  useEffect(() => {
    if (!account) {
      setMerkle(undefined);
      return;
    }

    const getMerkle = async () => {
      const res = await VerseSaleController.getMerkleProof(account);
      if (res.isFailure || !res.getValue()) {
        setMerkle(undefined);
        return;
      }
      setMerkle(res.getValue());
    };

    getMerkle();
  }, [account]);

  return (
    <VerseClaimMerkleContext.Provider value={merkle}>
      {children}
    </VerseClaimMerkleContext.Provider>
  );
};

export const useVerseClaimMerkle = () => useContext(VerseClaimMerkleContext);

export default VerseClaimMerkleProvider;
