import React, { ComponentPropsWithoutRef, FC } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  width?: number | string;
}

const FireIcon: FC<Props> = ({ width = 16 }) => {
  return (
    <SvgIcon
      width={width}
      height={width}
      viewBox="0 0 18 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 11.25C17.75 16.0825 13.8325 20 9 20C4.16751 20 0.25 16.0825 0.25 11.25C0.25 6.875 4 3.75 4 3.75H6.5V5.625C6.5 6.31536 7.05964 6.875 7.75 6.875C8.44036 6.875 9 6.31536 9 5.625V0H11.5C11.5 0 17.75 3.75 17.75 11.25ZM11.5 15C11.5 16.3807 10.3807 17.5 9 17.5C7.61929 17.5 6.5 16.3807 6.5 15C6.5 11.875 9 10 9 10C9 10 11.5 11.875 11.5 15Z"
      />
    </SvgIcon>
  );
};

export default FireIcon;
