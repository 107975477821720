import { State } from '../store';
import { UserDetailsType, UserStytchDetailsType } from '../types';

export type UserDetailsAction = {
  type: 'SET_USER_DETAILS';
  payload?: UserDetailsType | UserStytchDetailsType;
};

export const userDetailsReducer = (
  state: State,
  payload?: UserDetailsType | UserStytchDetailsType,
): State => ({
  ...state,
  userDetails: payload,
});
