import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { useEthers } from '@usedapp/core';

import { SubgraphAPIClient } from '@context/api/subgraph';
import { BCH_FLEXUSD_PAIR, DEFAULT_CHAIN } from '@context/constants';
import { useExchangeRates } from '@context/providers/ExchangeRatesProvider';
import { useLiquidityPairs } from '@context/providers/LiquidityPairsProvider';
import { useTrackedState } from '@context/store';

import { findProvider } from '@helpers/utils';
import {
  chainDetails,
  subgraphApiForProvider,
  supportedChain,
} from '@helpers/web3';

type tokenRatesPayload = {
  token: string;
  basePrice: number;
  rate: number;
  fiatRate: number;
  exchangeProvider: string;
  baseSymbol: string;
};

const tokenRatesReducer = (
  state: DexRatesType,
  {
    basePrice,
    baseSymbol,
    fiatRate,
    exchangeProvider,
    rate,
    token,
  }: tokenRatesPayload,
): DexRatesType => {
  const unwrappedSymbol = baseSymbol.slice(1, baseSymbol.length);
  const newState = {
    ...state,
    [exchangeProvider]: {
      ...state?.[exchangeProvider],
      [token.toUpperCase()]: rate * fiatRate,
      [baseSymbol]: basePrice * fiatRate,
      [unwrappedSymbol]: basePrice * fiatRate,
    },
  };

  return newState;
};

const DexRatesContext = createContext<DexRatesType>({});

const DexRatesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { provider: exchangeProvider } = useTrackedState();
  const exchangeRates = useExchangeRates();
  const { chainId } = useEthers();

  const provider = findProvider(exchangeProvider);
  const { liquidityPairs } = useLiquidityPairs();

  const supportedChainId =
    chainId && supportedChain(chainId) ? chainId : DEFAULT_CHAIN;
  const chain = chainDetails(provider, supportedChainId);

  const subgraphClient = useMemo(
    () => new SubgraphAPIClient(subgraphApiForProvider(provider)),
    [provider],
  );

  const [basePrice, setBasePrice] = useState(0);
  const [stableCoinPrice, setStableCoinPrice] = useState(1);

  const [tokenRates, setTokenRates] = useReducer(tokenRatesReducer, {});

  const fiatRate = exchangeRates?.USD?.USD || 1;

  // recalculate flexusd price
  useEffect(() => {
    const calculateFlexUsd = async () => {
      const pairId = BCH_FLEXUSD_PAIR;
      const response = await subgraphClient.getLiquidityPair(pairId);

      if (response.pair) {
        const { token1Price } = response.pair;
        const bchPrice = exchangeRates?.BCH?.USD;
        const estimatedFlexUsd = bchPrice / parseFloat(token1Price) || 1;
        setStableCoinPrice(estimatedFlexUsd);
      }
    };

    if (exchangeProvider === 'bitcoincom') {
      calculateFlexUsd();
    } else {
      setStableCoinPrice(1);
    }
  }, [basePrice, exchangeProvider]);

  useEffect(() => {
    const getWBCHPrice = async () => {
      const response = await subgraphClient.getBaseCoinPrice();
      if (response.bundle) {
        const { ethPrice } = response.bundle;
        const recalculatedPrice = parseFloat(ethPrice) * stableCoinPrice;
        setBasePrice(recalculatedPrice);
      }
    };

    const interval = setInterval(() => {
      if (provider.isDeFi) getWBCHPrice();
    }, 3e4);

    if (fiatRate && provider.isDeFi) {
      getWBCHPrice();
    }
    return () => clearInterval(interval);
  }, [stableCoinPrice, fiatRate, provider]);

  useEffect(() => {
    if (!liquidityPairs) return;
    liquidityPairs.forEach(pair => {
      const baseSymbol = chain?.wethSymbol || 'WBCH';
      const tokens = pair.name.split('-');

      tokens.forEach((token: string, i: number) => {
        if (token === baseSymbol) return;

        const curr = pair?.[`token${i}` as keyof ILiquidityPair];
        const derivedETH = curr?.['derivedETH' as keyof typeof curr] || '0';

        setTokenRates({
          token,
          basePrice,
          rate: parseFloat(derivedETH) * basePrice,
          fiatRate,
          exchangeProvider,
          baseSymbol,
        });
      });
    });
  }, [liquidityPairs, basePrice, exchangeProvider, stableCoinPrice]);

  return (
    <DexRatesContext.Provider value={tokenRates}>
      {children}
    </DexRatesContext.Provider>
  );
};

export const useDexRates = () => useContext(DexRatesContext);

export default DexRatesProvider;
