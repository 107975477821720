import { Dispatch } from 'react';

import { toSlpAddress } from 'bchaddrjs-slp';

import {
  AuthController,
  BlockController,
  MarketsController,
  SwapController,
  TSwappableAsset,
  UserController,
  WalletController,
} from '@bitcoin-portal/neko-web-sdk';
import Result from '@bitcoin-portal/neko-web-sdk/lib/Result';
import { TConversionRatesData } from '@bitcoin-portal/neko-web-sdk/lib/services/markets/rates';
import { TAuthDetails } from '@bitcoin-portal/neko-web-sdk/lib/services/neko/auth/types';

import { Action } from '../reducer';

const SESSION_KEY = 'BSESSIONID_V2_VALID';

export const checkUser = async (dispatch: Dispatch<Action>): Promise<void> => {
  const session = window.sessionStorage.getItem(SESSION_KEY);

  if (session === 'false') {
    return;
  }

  const res = await UserController.getUserDetailsV2();

  if (res.isFailure) {
    dispatch({ type: 'SET_USER_DETAILS' });
    window.sessionStorage.setItem(SESSION_KEY, 'false');
    return;
  }

  const payload = res.getValue();

  dispatch({ type: 'SET_USER_DETAILS', payload });
  window.sessionStorage.setItem(SESSION_KEY, 'true');
};

export const logoutAuth = async (dispatch: Dispatch<Action>): Promise<void> => {
  await AuthController.logoutAuth();

  dispatch({ type: 'SET_USER_DETAILS' });
  window.sessionStorage.setItem(SESSION_KEY, 'false');
};

export const loginAuthStytch = async (
  authDetails: TAuthDetails,
): Promise<boolean> => {
  const res = await AuthController.loginAuth(authDetails);

  if (res.isFailure) {
    window.sessionStorage.setItem(SESSION_KEY, 'false');
    return false;
  }

  window.sessionStorage.setItem(SESSION_KEY, 'true');
  return true;
};

export const getAddress = async (
  walletId: string,
  protocol?: string,
): Promise<string> => {
  if (!walletId) return '';
  const res = await WalletController.getAddress(walletId);
  if (res.isFailure) {
    return '';
  }

  let addr = res.getValue().legacyAddress;
  if (protocol && protocol === 'SLP_PROTOCOL') {
    addr = toSlpAddress(addr);
  }
  return addr;
};

export const getBlockHeight = async (
  timestamp: number,
): Promise<
  Result<{
    height: number;
  }>
> => {
  return BlockController.getBlockHeight(timestamp);
};

export const getSpotRate = async (
  baseCurrency: string,
): Promise<Result<TConversionRatesData>> => {
  return MarketsController.convertRates(baseCurrency, 'USD');
};

export const getSwappableAssets = async (): Promise<
  Result<{
    provider: string;
    swapAssets: TSwappableAsset[];
  }>
> => {
  return SwapController.fetchSwappableAssets();
};
