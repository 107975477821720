import React, { FunctionComponent, useEffect } from 'react';

import { RouteComponentProps, useParams } from '@reach/router';
import { navigate } from 'gatsby';

import { findProvider } from '../../helpers';

import { useTrackedState } from '../store';

interface Props extends RouteComponentProps {
  page?: string;
}

export const NoChainHandler: FunctionComponent<Props> = ({
  page = 'pools',
}) => {
  const { chain } = useParams() || { chain: '' };

  const { provider: exchangeProvider } = useTrackedState();

  const provider = findProvider(exchangeProvider);

  useEffect(() => {
    if (!chain && provider) {
      const { chainParam = 'eth' } = provider;
      if (page === 'staking' && chainParam === 'eth') {
        navigate(`/${page}/${chainParam}/verse/`, {
          replace: true,
        });
      } else {
        navigate(`/${page}/${chainParam}`, {
          replace: true,
        });
      }
    }
  }, [provider, page]);

  return <></>;
};

export default NoChainHandler;
