import React, { FC, useEffect, useMemo, useRef } from 'react';

import { WindowLocation, navigate, useLocation } from '@reach/router';

import Modal from '@bitcoin-portal/verse-web-components/dist/Modal';

import { useDispatch } from '../../../context/store';

import LoginErrorModal from '../../LoginErrorModal';

const ConnectErrorModal: FC = () => {
  const dispatch = useDispatch();

  const loginErrorModalRef = useRef<Modal>(null);
  const { state } = useLocation() as WindowLocation<State>;

  const navigateState = useMemo(() => {
    const bcom = state?.bcom || false;
    const bcomLoginError = state?.bcomLoginError || false;
    const stytchLoginError = state?.stytchLoginError || false;

    return { bcomLoginError, stytchLoginError, bcom };
  }, []);

  useEffect(() => {
    if (navigateState.bcom) {
      dispatch({
        type: 'SET_CONNECT_MODAL_OPEN',
        payload: false,
      });
    }
  }, [navigateState.bcom, dispatch]);

  useEffect(() => {
    if (navigateState.bcomLoginError || navigateState.stytchLoginError) {
      loginErrorModalRef.current?.open();
    }
  }, [
    loginErrorModalRef,
    navigateState.bcomLoginError,
    navigateState.stytchLoginError,
  ]);

  return (
    <Modal
      ref={loginErrorModalRef}
      title={<>Sign in Failed</>}
      maxWidth="420px"
      callback={({ action }) => {
        if (action === 'close') {
          navigate('.', {
            state: {
              stytchLoginError: false,
              bcomLoginError: false,
            },
          });
          state.stytchLoginError = false;
          state.bcomLoginError = false;
        }
      }}
    >
      <LoginErrorModal
        loginErrorModalRef={loginErrorModalRef}
        connectModalRetry={() =>
          dispatch({
            type: 'SET_CONNECT_MODAL_OPEN',
            payload: true,
          })
        }
      />
    </Modal>
  );
};

export default ConnectErrorModal;
