import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ExternalLink from '@bitcoin-portal/verse-web-components/dist/Link';

import { useTrackedState } from '../../../context/store';

import { getBlockchain, logAmplitudeEvent } from '../../../helpers';

import Image from '../../Image';
import {
  FooterLinks,
  FooterSection,
  LogoSocialWrapper,
  LogoWrapper,
  Social,
  StyledBcomLogo,
  StyledCopyrightText,
  StyledFooterLink,
} from './styled';

const Footer = () => {
  const { messages } = useIntl();

  const { provider } = useTrackedState();

  const trackFooterClick = useCallback(
    (label: string) => {
      if (typeof window !== 'undefined') {
        logAmplitudeEvent('navigationClicked', {
          blockchain: getBlockchain(provider),
          'current page': window.location,
          highlight: '',
          menu: 'footer',
          'menu cta': label.toLowerCase(),
        });
      }
    },
    [provider],
  );

  const socials = messages['global.footer.socials'] as unknown as {
    id: string;
    src: string;
    alt: string;
    link: string;
  }[];

  const links = messages['global.footer.links'] as unknown as {
    id: string;
    label: string;
    link: string;
  }[];

  return (
    <FooterSection>
      <FooterLinks>
        {links.map(({ id, link, label }) => {
          return (
            <StyledFooterLink
              key={id}
              href={link}
              newTab={id !== 'audits'}
              onClick={() => trackFooterClick(id)}
            >
              {label}
            </StyledFooterLink>
          );
        })}
      </FooterLinks>
      <LogoSocialWrapper>
        <LogoWrapper>
          <ExternalLink
            href="https://www.bitcoin.com/"
            newTab
            onClick={() => trackFooterClick('logo')}
          >
            <StyledBcomLogo
              src="/images/uploads/bcom-logo.png"
              alt="Bitcoin.com"
            />
          </ExternalLink>
        </LogoWrapper>
        <Social>
          {socials.map(({ alt, id, link, src }) => {
            return (
              <ExternalLink
                key={alt}
                href={link}
                newTab
                onClick={() => trackFooterClick(id)}
              >
                <Image src={src} alt={alt} width={32} height={32} />
              </ExternalLink>
            );
          })}
        </Social>
      </LogoSocialWrapper>
      <StyledCopyrightText>
        <FormattedMessage
          id="global.footer.copyright"
          values={{ YEAR: new Date().getFullYear() }}
        />
      </StyledCopyrightText>
    </FooterSection>
  );
};

export default Footer;
