import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import ExternalLink from '@bitcoin-portal/verse-web-components/dist/Link';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const ModalContainer = styled.div<{
  standalone: boolean;
}>`
  ${({ standalone }) => css`
    position: fixed;
    right: 9px;
    bottom: ${standalone ? '112px' : '80px'};
    background-color: ${colors.verseNavBlue};
    display: flex;
    flex-direction: column;
    width: 199px;
    height: fit-content;
    padding: 24px;
    border-radius: 11px;
    border: 1px solid ${colors.shade50};
    z-index: 1;
  `}
`;

export const ModalContent = styled.div`
  text-align: left;
  align-items: left;
  font-weight: 600;
  font-size: 12px;
  line-height: 14.32px;
`;

export const StyledExternalLink = styled(ExternalLink)`
  display: flex;
  color: ${colors.shade80};
  padding: 10px 0;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background: ${colors.shade40};
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  color: ${colors.shade80};
  padding: 10px 0;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background: ${colors.shade40};
  }
`;

export const StyledIcon = styled.div`
  margin-right: 12px;
  margin-left: 12px;
`;

export const NewBadge = styled.div`
  display: block;
  background-color: ${colors.verseBlue};
  margin-left: 24px;
  padding: 2px 8px;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  border-radius: 3px;
`;
