import { Dispatch } from 'react';

import {
  MagicLinksLoginOrCreateOptions,
  StytchUIClient,
} from '@stytch/vanilla-js';
import { StytchHeadlessClient } from '@stytch/vanilla-js/dist/index.headless';
import { navigate } from 'gatsby';

import { ACTIVE_ENV, STYTCH_EMAIL_TEMPLATES } from '../context/constants';
import { Action } from '../context/reducer';

import { newrelicError } from './newrelic';

interface EmailOptions extends MagicLinksLoginOrCreateOptions {
  // eslint-disable-next-line camelcase
  login_template_id?: string;
  // eslint-disable-next-line camelcase
  signup_template_id?: string;
}

export const sendEmailMagicLink = async (
  stytchClient: StytchHeadlessClient | StytchUIClient,
  email: string,
  pathname: string,
  dispatch: Dispatch<Action>,
): Promise<boolean> => {
  try {
    const options: EmailOptions = {
      login_magic_link_url: `${origin}/auth/`,
      login_expiration_minutes: 60,
      login_template_id: STYTCH_EMAIL_TEMPLATES.login[ACTIVE_ENV],
      signup_magic_link_url: `${origin}/auth/`,
      signup_template_id: STYTCH_EMAIL_TEMPLATES.signup[ACTIVE_ENV],
      signup_expiration_minutes: 60,
    };
    const stytchLogin = await stytchClient.magicLinks.email.loginOrCreate(
      email,
      options,
    );

    if (stytchLogin.status_code !== 200) {
      navigate('.', {
        state: { stytchLoginError: true },
      });
      return false;
    }

    dispatch({
      type: 'SET_LOGIN_FROM',
      payload: pathname,
    });
    return true;
  } catch (e) {
    navigate('.', {
      state: { stytchLoginError: true },
    });
    newrelicError(e, {
      method: 'sendEmailMagicLink',
      page: pathname,
    });
    return false;
  }
};
