import { BigNumber, ethers } from 'ethers';

import { DEFAULT_DECIMALS } from '@context/constants';

import { MAX_TOLERANCE } from '../context/constants';

export const prepareMin = (balance: number, decimals = 0): BigNumber => {
  return decimals === 0
    ? ethers.utils.parseEther(
        (balance * MAX_TOLERANCE).toFixed(DEFAULT_DECIMALS),
      )
    : ethers.utils.parseUnits(
        (balance * MAX_TOLERANCE).toFixed(decimals),
        decimals,
      );
};
