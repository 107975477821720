import { State } from '../store';

export type RpcModalHidePayload = boolean;

export type RpcModalHideAction = {
  type: 'SET_RPC_MODAL_HIDE';
  payload: RpcModalHidePayload;
};

export const rpcModalHideReducer = (
  state: State,
  payload: RpcModalHidePayload,
): State => ({
  ...state,
  rpcModalHide: payload,
});
