import styled from 'styled-components';

import Button from '@bitcoin-portal/verse-web-components/dist/Button/Button';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const BcomIconWrapper = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: ${colors.shade30};
  box-sizing: border-box;
  padding: 8px;
`;

export const BcomIconStyle = styled.img`
  height: 16px;
  width: 16px;
`;

// LoginModal
export const ConnectButton = styled(Button)`
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0px ${({ theme }) => theme.spacing.unit * 1.5}px;
  margin-bottom: ${({ theme }) => theme.spacing.unit}px;
  border-radius: 12px;
  & > div:first-child {
    display: flex;
    gap: 8px;
    align-items: center;
    & > span:first-child {
      height: ${({ theme }) => theme.spacing.unit * 4}px !important;

      & > img {
        width: ${({ theme }) => theme.spacing.unit * 4}px;
        height: ${({ theme }) => theme.spacing.unit * 4}px;
      }
    }
  }
`;

export const ConnectBubble = styled.div`
  background-color: ${colors.shade50};
  color: ${colors.white};
  border-radius: 100px;
  padding: 5px ${({ theme }) => theme.spacing.unit * 2.5}px;
  font-size: 12px;
`;

export const WalletDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  & > span:nth-child(2) {
    font-size: 12px;
    color: ${colors.shade100};
    opacity: 0.5;
  }
`;

export const DisconnectBubble = styled.div`
  background-color: ${colors.white};
  color: ${colors.verseBlue};
  border-radius: 100px;
  padding: 5px ${({ theme }) => theme.spacing.unit * 2.5}px;
  font-size: 12px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;
